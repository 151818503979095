import type { TimelineDuration } from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/timeline.tsx';
import { DUE_DATE_OFFSET } from '../../constants/date.tsx';
import { CLOSED } from '../../constants/interval.tsx';
import type { Interval, IntervalGroupPositions } from '../../types/interval.tsx';
import { getBarLeftPosition, getBarRightPosition } from '../bar/positions.tsx';
import { groupOverlappingIntervals, type GenericInterval, type IntervalGroup } from './grouping';

/* The data is sanitised in two ways:
 * 1. Make sure that the end date is not before the start date
 * 2. Make sure that the intervals do not fall outside the timeline
 * While this is important for the rendering, it also affects the result of our greedy grouping algorithm.
 */
export const getSanitizedIntervals = (
	intervals: ReadonlyArray<Interval>,
	timelineDuration: TimelineDuration,
): GenericInterval<Interval>[] => {
	const saneIntervals: Array<GenericInterval<Interval>> = [];

	intervals.forEach((interval: Interval) => {
		const saneStartDate = Math.min(interval.startDate, interval.endDate);
		const saneEndDate = Math.max(interval.startDate, interval.endDate) + DUE_DATE_OFFSET;

		const isStartInTimeline =
			saneStartDate >= timelineDuration.startMilliseconds &&
			saneStartDate <= timelineDuration.endMilliseconds;
		const isEndInTimeline =
			saneEndDate >= timelineDuration.startMilliseconds &&
			saneEndDate <= timelineDuration.endMilliseconds;
		const isLongerThanTimeline =
			saneStartDate <= timelineDuration.startMilliseconds &&
			saneEndDate >= timelineDuration.endMilliseconds;

		if (isStartInTimeline || isEndInTimeline || isLongerThanTimeline) {
			saneIntervals.push({
				id: interval.id,
				name: interval.name,
				state: interval.state,
				startDate: saneStartDate,
				endDate: saneEndDate,
			});
		}
	});

	return saneIntervals;
};

/* For an interval group, determine the positions of the group and its sub-intervals on the timeline.
 * This effectively involves translating dates to pixels.
 */
export const calculateIntervalGroupPositions = (
	intervalGroup: IntervalGroup<Interval>,
	timelineDuration: TimelineDuration,
	timelineWidth: number,
): IntervalGroupPositions => {
	const { startDate, endDate, expandedEndDate, intervals } = intervalGroup;

	// === Calculate sub-interval positions === //

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const intervalsPositions: Record<string, any> = {};
	intervals.forEach((interval) => {
		const left = getBarLeftPosition(interval.startDate, timelineDuration, timelineWidth);
		const right = getBarRightPosition(
			interval.endDate - DUE_DATE_OFFSET,
			timelineDuration,
			timelineWidth,
		);
		intervalsPositions[interval.id] = {
			left: Math.max(0, left),
			right: Math.max(0, right),
		};
	});

	// === Calculate group level positions === //

	const left = getBarLeftPosition(startDate, timelineDuration, timelineWidth);
	const right = getBarRightPosition(endDate - DUE_DATE_OFFSET, timelineDuration, timelineWidth);
	const rightExpanded = getBarRightPosition(
		expandedEndDate - DUE_DATE_OFFSET,
		timelineDuration,
		timelineWidth,
	);

	let targetIntervalLeft = left;
	let targetIntervalRight = right;
	const targetInterval = intervals.find((interval: Interval) => interval.state !== CLOSED);

	if (targetInterval !== undefined && intervalsPositions[targetInterval.id]) {
		targetIntervalLeft = intervalsPositions[targetInterval.id].left;
		targetIntervalRight = intervalsPositions[targetInterval.id].right;
	}

	// === Sanitise group level positions === //

	const clippedLeft = Math.max(0, left);
	const clippedRight = Math.max(0, right);
	const clippedTargetLeft = Math.max(0, targetIntervalLeft);
	const clippedTargetRight = Math.max(0, targetIntervalRight);
	const clippedRightExpanded = Math.max(0, rightExpanded);
	const width = timelineWidth - clippedLeft - clippedRight;
	const widthExpanded = timelineWidth - clippedLeft - clippedRightExpanded;
	return {
		isLeftClipped: left !== clippedLeft,
		isRightClipped: right !== clippedRight,
		isRightExpandedClipped: rightExpanded !== clippedRightExpanded,
		left: clippedLeft,
		right: clippedRight,
		targetIntervalLeft: clippedTargetLeft,
		targetIntervalRight: clippedTargetRight,
		rightExpanded: clippedRightExpanded,
		width,
		widthExpanded,
		intervalsPositions,
		intervals,
	};
};

/**
 * Returns a function that groups intervals. An interval group is one or more intervals.
 * Multiple intervals may be grouped together when their start and end dates are overlapping.
 * After grouping, we calculate their pixel positions so they can be displayed.
 */
export const getIntervalGroupPositions = (
	intervals: ReadonlyArray<Interval>,
	timelineDuration: TimelineDuration,
	timelineWidth: number,
): ReadonlyArray<IntervalGroupPositions> => {
	const saneIntervals = getSanitizedIntervals(intervals, timelineDuration);
	const intervalGroups = groupOverlappingIntervals<Interval>(saneIntervals);

	return intervalGroups.map<IntervalGroupPositions>((intervalGroup: IntervalGroup<Interval>) =>
		calculateIntervalGroupPositions(intervalGroup, timelineDuration, timelineWidth),
	);
};
