import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/zip';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { IssueId, AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Configuration } from '@atlassian/jira-software-roadmap-model/src/configuration/index.tsx';
import type { Healthcheck } from '@atlassian/jira-software-roadmap-model/src/healthcheck/index.tsx';
import type { SortedIssueHash } from '@atlassian/jira-software-roadmap-model/src/issue/index.tsx';
import type { Metadata } from '@atlassian/jira-software-roadmap-model/src/metadata/index.tsx';
import type { Query } from '@atlassian/jira-software-roadmap-model/src/router/index.tsx';
import type { Settings } from '@atlassian/jira-software-roadmap-model/src/settings/index.tsx';
import type { StatusCategoryHash } from '@atlassian/jira-software-roadmap-model/src/status/index.tsx';
import type { User } from '@atlassian/jira-software-roadmap-model/src/user/index.tsx';
import type { RouterDataContext } from '@atlassian/react-resource-router';
import getCriticalData from '../critical-data';
import type { CriticalDataResponse } from '../critical-data/types';
import { filterItemsByJQLFilters } from '../issues/filter';
import { fetchEagerLoadedData } from './eager-loading';
import type { EagerLoadedData } from './eager-loading/types.tsx';
import type { CriticalData } from './types';

const mapResult = (
	configuration: Configuration | undefined,
	issues: SortedIssueHash,
	statusCategories: StatusCategoryHash | undefined,
	users: Record<AccountId, User>,
	settings: Settings,
	metadata: Metadata,
	healthcheck: undefined | Healthcheck,
	filteredIssueIds: IssueId[] | undefined,
	eagerLoadedData?: EagerLoadedData,
) => ({
	configuration,
	issues,
	statusCategories,
	users,
	settings,
	metadata,
	healthcheck,
	filteredIssueIds,
	eagerLoadedData,
});

export const getCriticalDataObservable = (
	sourceAri: Ari,
	query?: Query,
	locationAri?: Ari,
): Observable<CriticalData> => {
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	let filteredIssuesObservable: Observable<IssueId[] | void> = Observable.of(undefined);
	const customFilters = query?.customFilter ?? [];
	const quickFilters = query?.quickFilter ?? [];

	if (quickFilters.length > 0 || customFilters.length > 0) {
		filteredIssuesObservable = filterItemsByJQLFilters(sourceAri, quickFilters, customFilters);
	}

	return Observable.zip(
		getCriticalData(sourceAri, locationAri),
		filteredIssuesObservable,
		(
			{
				currentUser,
				configuration,
				statusCategories,
				issues,
				users,
				metadata,
				settings,
				healthcheck,
			}: CriticalDataResponse,
			filteredIssueIds: IssueId[],
		) => {
			const mappedCriticalData = mapResult(
				configuration,
				issues,
				statusCategories,
				{ ...currentUser, ...users },
				settings,
				metadata,
				healthcheck,
				filteredIssueIds,
			);

			return mappedCriticalData;
		},
	);
};

export const getCriticalDataWithEagerLoading = (
	sourceAri: Ari,
	routerDataContext: RouterDataContext,
	locationAri?: Ari,
): Observable<CriticalData> =>
	Observable.zip(
		getCriticalData(sourceAri, locationAri),
		fetchEagerLoadedData(sourceAri, routerDataContext),
		(
			{
				currentUser,
				configuration,
				statusCategories,
				issues,
				users,
				metadata,
				settings,
				healthcheck,
			}: CriticalDataResponse,
			eagerLoadedlData: EagerLoadedData,
		) => {
			const mappedCriticalData = mapResult(
				configuration,
				issues,
				statusCategories,
				{ ...currentUser, ...users },
				settings,
				metadata,
				healthcheck,
				undefined,
				eagerLoadedlData,
			);

			return mappedCriticalData;
		},
	);
