import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';

const GRID_SIZE = 8;
const DEFAULT_LIST_CONTENT_PADDING = GRID_SIZE * 3;
const NON_BASE_LEVEL_LIST_CONTENT_PADDING = 0;
const DEFAULT_LIST_CREATE_OFFSET = -12;
const CHECKBOX_SPACE = 32;

export const getListContentPaddingLeft = ({
	isCreate = false,
	hasCheckbox = false,
	depth,
	isParent,
	offset,
}: {
	isCreate?: boolean;
	hasCheckbox?: boolean;
	depth: number;
	isParent: boolean;
	offset?: number;
}) => {
	let result = depth * DEFAULT_LIST_CONTENT_PADDING;

	if (hasCheckbox) {
		result += CHECKBOX_SPACE;
	}

	if (isVisualRefreshEnabled()) {
		result += offset || 0;
	}

	if (isParent) {
		result += NON_BASE_LEVEL_LIST_CONTENT_PADDING;
	} else {
		result += DEFAULT_LIST_CONTENT_PADDING;
	}

	if (isCreate) {
		result += DEFAULT_LIST_CREATE_OFFSET;
	}

	return result;
};
