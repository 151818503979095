import { MILLISECONDS_PER_DAY } from '@atlassian/jira-software-roadmap-model/src/datetime/index.tsx';
import type { BarDragType } from '../../../types/chart-item.tsx';

export const getDaysInBetween = (
	fromDate: number,
	toDate: number,
	isToDateExcluded = false,
): number =>
	Math.round(Math.abs(fromDate - toDate) / MILLISECONDS_PER_DAY) + (isToDateExcluded ? 0 : 1);

interface DurationProps {
	fromDate: number;
	toDate: number;
	isSigned: boolean;
	isToDateExcluded: boolean;
}

export const getDurationProps = (
	prevStartDate: number | undefined,
	prevDueDate: number | undefined,
	startDate: number | undefined,
	dueDate: number | undefined,
	dragType: BarDragType | undefined,
	currentStartDate: number,
	currentDueDate: number,
): DurationProps => {
	// Show duration on hover if no dragging or dragging entire bar
	if (dragType === undefined || dragType === 'LEFT_AND_RIGHT') {
		return {
			fromDate: currentStartDate,
			toDate: currentDueDate,
			isSigned: false,
			isToDateExcluded: false,
		};
	}

	const isDurationDiff =
		prevStartDate !== undefined &&
		prevDueDate !== undefined &&
		startDate !== undefined &&
		dueDate !== undefined;

	const isStartDateFixed =
		prevDueDate !== undefined && startDate === undefined && dueDate !== undefined;

	const isDueDateFixed =
		prevStartDate !== undefined && startDate !== undefined && dueDate === undefined;

	const isDurationMeta =
		prevStartDate === undefined &&
		prevDueDate === undefined &&
		startDate !== undefined &&
		dueDate !== undefined;

	if (isDurationDiff || isStartDateFixed) {
		return { fromDate: prevDueDate, toDate: dueDate, isSigned: true, isToDateExcluded: true };
	}

	if (isDueDateFixed) {
		return {
			fromDate: startDate,
			toDate: prevStartDate,
			isSigned: true,
			isToDateExcluded: true,
		};
	}

	if (isDurationMeta) {
		return { fromDate: startDate, toDate: dueDate, isSigned: false, isToDateExcluded: false };
	}

	return {
		fromDate: startDate ?? 0,
		toDate: dueDate ?? 0,
		isSigned: false,
		isToDateExcluded: false,
	};
};
