/** @jsx jsx */
import React, { useEffect, type ReactNode, useRef, type MouseEvent } from 'react';
import { css, jsx } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { Color } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { useIsScrollingX } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/scroll-meta/main.tsx';
import { useViewport } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/viewport/context/index.tsx';
import { CHART_PREVIEW_BAR_Z_INDEX } from '../../../common/constants/z-index.tsx';
import type {
	OnChartItemUpdate,
	ChartItemUpdateParameter,
} from '../../../common/types/chart-item.tsx';
import type { DateType } from '../../../common/types/date.tsx';
import { useChartItemInteractions } from '../../../controllers/table-providers/chart-item-interaction';
import { PreviewBar } from './preview-bar';

const previewContainerStyles = css({
	width: '100%',
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: CHART_PREVIEW_BAR_Z_INDEX,
});

type Props = {
	isHovered: boolean;
	id: string;
	level: number;
	color: Color;
	startDateType: DateType;
	dueDateType: DateType;
	children?: ReactNode;
	onUpdate: OnChartItemUpdate;
};

const NoDateContent = ({
	id,
	level,
	color,
	startDateType,
	dueDateType,
	isHovered,
	onUpdate,
	children,
}: Props) => {
	const lastScrollLeft = useRef(0);
	const [isScrollingX] = useIsScrollingX();
	const wasScrollingX = usePreviousWithInitial(isScrollingX);
	const { requestViewportScrollBounding } = useViewport();

	const [
		{ isActive, leftPosition, rightPosition, dragType },
		{ onDragStart, onDrag, onDragEnd, onLingerStart, onLingerEnd, onPreview },
	] = useChartItemInteractions(id);

	// === EFFECTS === //

	/* This component is hidden while scrolling. After scrolling ends we need to update the
	 * preview position to match the new scroll offset (where the user cursor *now* is).
	 */
	useEffect(() => {
		if (isActive && !dragType) {
			const { scrollLeft } = requestViewportScrollBounding();

			if (!wasScrollingX && isScrollingX) {
				lastScrollLeft.current = scrollLeft;
			} else if (wasScrollingX && !isScrollingX && leftPosition !== undefined) {
				const scrollOffset = scrollLeft - lastScrollLeft.current;
				lastScrollLeft.current = scrollLeft;

				onPreview(leftPosition + scrollOffset);
			}
		}
	});

	// === CALLBACKS === //

	const onContainerMouseMove = ({ pageX, currentTarget }: MouseEvent<HTMLDivElement>) => {
		// Get the left position relative to the timeline
		onPreview(pageX - currentTarget.getBoundingClientRect().left);
	};

	const onPreviewClick = (params: ChartItemUpdateParameter, analyticsEvent: UIAnalyticsEvent) => {
		onUpdate(id, params, analyticsEvent);
		onLingerEnd();
	};

	const onPreviewDragEnd = (params: ChartItemUpdateParameter, analyticsEvent: UIAnalyticsEvent) => {
		onUpdate(id, params, analyticsEvent);
		onDragEnd();
	};

	// === RENDER === //

	/* Why check the passed in hover state when we have our own mouse enter and leave listeners here?
	 * 1. The hover state is passed from a table internal renderer to minimise the consumer DOM (on average).
	 *    We don't need to render an extra div to track the mouse move state until necessary.
	 * 2. Calling onLingerStart and onLingerEnd from a useEffect results in an item's start being called before
	 *    the previous items end which results in strange behaviour.
	 */
	if ((isHovered && !isScrollingX) || (isActive && dragType)) {
		return (
			<div
				data-testid={`roadmap.timeline-table-kit.ui.chart-item-content.no-date-content.preview-container-${id}`}
				css={previewContainerStyles}
				onMouseMove={onContainerMouseMove}
				onMouseEnter={onLingerStart}
				onMouseLeave={onLingerEnd}
			>
				{children}
				{leftPosition !== undefined && rightPosition !== undefined && (
					<PreviewBar
						level={level}
						color={color}
						startDateType={startDateType}
						dueDateType={dueDateType}
						leftPosition={leftPosition}
						rightPosition={rightPosition}
						onDrag={onDrag}
						onDragStart={onDragStart}
						onDragEnd={onPreviewDragEnd}
						onClick={onPreviewClick}
					/>
				)}
			</div>
		);
	}

	return null;
};

export { NoDateContent };
