import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import type { Label } from '@atlassian/jira-filters/src/common/types.tsx';
import type {
	ComponentId,
	IssueTypeId,
	AccountId,
	VersionId,
	IssueStatusCategoryId,
	IssueId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import {
	type RoadmapType,
	CLASSIC,
} from '@atlassian/jira-software-roadmap-model/src/app/index.tsx';
import type { LevelOneQuerySetting } from '@atlassian/jira-software-roadmap-model/src/filter/index.tsx';
import type { QuickFilterId } from '@atlassian/jira-software-roadmap-model/src/quick-filter/index.tsx';
import type { Query } from '@atlassian/jira-software-roadmap-model/src/router/index.tsx';
import type { Query as RouterQuery } from '@atlassian/react-resource-router';

const parseValuesList = <TValue extends string>(value?: string): TValue[] => {
	if (value !== undefined && !isEmpty(value) && isString(value)) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return value.split(',') as TValue[];
	}
	return [];
};

const parseValueBoolean = (value?: string | boolean): boolean | undefined =>
	value === 'true' || value === true ? true : undefined;

export const getSanitizedQuery = (parsed: RouterQuery, roadmapType: RoadmapType): Query => {
	const isCMP = roadmapType === CLASSIC;

	const sanitized = {
		// generic params
		projectKey: parsed.projectKey,
		rapidView: parsed.rapidView,
		timeline: parsed.timeline,
		selectedIssue: parsed.selectedIssue,
		text: parsed.text,
		hideDependencies: parseValueBoolean(parsed.hideDependencies),
		hideProgress: parseValueBoolean(parsed.hideProgress),
		hideWarnings: parseValueBoolean(parsed.hideWarnings),
		hideVersionHeader: parseValueBoolean(parsed.hideVersionHeader),
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		epic: parsed.epic as LevelOneQuerySetting,
		statuses: parseValuesList<IssueStatusCategoryId>(parsed.statuses),
		issueType: parseValuesList<IssueTypeId>(parsed.issueType),
		assignee: parseValuesList<AccountId>(parsed.assignee),
		label: parseValuesList<Label>(parsed.label),
		issueParent: parseValuesList<IssueId>(parsed.issueParent),
		version: parseValuesList<VersionId>(parsed.version),
		component: parseValuesList<ComponentId>(parsed.component),
		highlightedVersions: parseValuesList<VersionId>(parsed.highlightedVersions),
		shared: 'shared' in parsed,
		// CMP specific params
		quickFilter: isCMP ? parseValuesList<QuickFilterId>(parsed.quickFilter) : undefined,
		customFilter: !isCMP ? parseValuesList<QuickFilterId>(parsed.customFilter) : undefined,
		goal: parseValuesList<string>(parsed.goal),
	};

	return sanitized;
};
