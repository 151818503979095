import { useRef, useEffect, useCallback } from 'react';
import type { BarDragType } from '../../../common/types/chart-item.tsx';
import { useInteractionData } from './main';

/* A lightweight wrapper that orchestrates the interaction actions:
 * - Internally supplies the item id to the underlying actions so that the components don't have to
 * - Centralises the handling of timers (throttling, etc)
 */
const useChartItemInteractions = (id: string) => {
	const timeoutId = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
	const frameId = useRef<number | undefined>(undefined);
	const [interactionState, interactionActions] = useInteractionData(id);

	useEffect(
		() => () => {
			frameId.current && cancelAnimationFrame(frameId.current);
			clearTimeout(timeoutId.current);
		},
		[],
	);

	const onDragStart = useCallback(
		(dragType: BarDragType) => {
			interactionActions.onDragStart(id, dragType);
		},
		[id, interactionActions],
	);

	const onLingerStart = useCallback(() => {
		interactionActions.onLingerStart(id);
		timeoutId.current = setTimeout(() => interactionActions.onLinger(), 200);
	}, [id, interactionActions]);

	const onLingerEnd = useCallback(() => {
		clearTimeout(timeoutId.current);
		interactionActions.onLingerEnd();
	}, [interactionActions]);

	const onPreview = useCallback(
		(previewX: number) => {
			if (!frameId.current) {
				frameId.current = requestAnimationFrame(() => {
					frameId.current = undefined;
					interactionActions.onPreview(previewX);
				});
			}
		},
		[interactionActions],
	);

	return [
		interactionState,
		{
			...interactionActions,
			onDragStart,
			onLingerStart,
			onLingerEnd,
			onPreview,
		},
	] as const;
};

export { useChartItemInteractions };
