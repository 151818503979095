import { createContext, type Context } from 'react';
import noop from 'lodash/noop';
import type { MarshalActions } from '../types';

export const SideEffectContext: Context<MarshalActions> = createContext({
	custom: {},
	onRowMouseEnter: noop,
	onRowMouseLeave: noop,
	onRowSelect: noop,
	onRowDeselect: noop,
	onRowFocus: noop,
	onRowBlur: noop,
	onRowGenerateDragPreview: noop,
	onRowDragStart: noop,
	onRowDragEnd: noop,
	onRowDragOver: noop,
	onRowDragChildOverParent: noop,
	onRowDragChildOverParentEnd: noop,
	onRowDragOutOfBounds: noop,
	onAddButtonMouseEnter: noop,
	onAddButtonMouseLeave: noop,
	onScrollStateChanged: noop,
});
