/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { useIntl } from 'react-intl-next';
import { Box, xcss } from '@atlaskit/primitives';
import { N40, B500, N300, B50 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { borderWidth } from '../../../../../common/styled/timeline.tsx';
import type { WeekNumber } from '../common/types';
import { inMonthOrder, inMonthOrderOld } from '../messages';

// Each day is 1/7th of a Week Cell in width
const dayWidth = (1 / 7) * 100;

const headerStyles = css({
	marginTop: token('space.050', '4px'),
	font: token('font.body.small'),
	color: token('color.text.subtlest', N300),
});

const headerTextTransformStyles = css({
	textTransform: 'uppercase',
});

const dayContainerStylesOld = css({
	display: 'flex',
	flex: '1 1 auto',
	alignItems: 'center',
	width: '100%',
	height: `calc(100% - ${token('space.050', '4px')} - ${token('space.200', '16px')})`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `${dayWidth}%`,
		font: token('font.body.small'),
		lineHeight: 1.4,
		textAlign: 'center',
		overflowWrap: 'break-word',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		margin: `${token('space.0', '0')} ${token('space.100', '8px')} ${borderWidth}px`,
		padding: `0 ${token('space.025', '2px')}`,
		color: token('color.text.subtlest', N300),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'span:nth-child(6n + 6), span:nth-child(7n + 7)': {
		backgroundColor: token('color.background.neutral.hovered', N40),
		borderRadius: token('border.radius', '3px'),
	},
});

const dayChildTodayStylesOld = css({
	borderRadius: token('border.radius', '3px'),
	backgroundColor: token('color.background.accent.blue.subtlest', B50),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	color: `${token('color.text.brand', B500)} !important`,
});

const dayContainerStyles = css({
	display: 'flex',
	flex: '1 1 auto',
	alignItems: 'center',
	width: '100%',
	height: `calc(100% - ${token('space.050', '4px')} - ${token('space.200', '16px')})`,
});

const dayChildWeekendStyles = css({
	backgroundColor: token('color.background.neutral.hovered', N40),
	borderRadius: token('border.radius', '3px'),
});

const dayChildTodayStyles = css({
	borderRadius: token('border.radius', '3px'),
	backgroundColor: token('color.background.accent.blue.subtlest', B50),
	color: `${token('color.text.brand', B500)}`,
});

const dayChildStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${dayWidth}%`,
	font: token('font.body.small'),
	textAlign: 'center',
	overflowWrap: 'break-word',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	margin: `${token('space.0', '0')} ${token('space.100', '8px')} ${borderWidth}px`,
	padding: `0 ${token('space.025', '2px')}`,
	color: token('color.text.subtlest', N300),
});

const dayChildHighlightedStyles = css({
	color: token('color.text.accent.gray.bolder', N300),
});

type Props = {
	isHighlightToday: boolean;
	weekNumbers: WeekNumber[];
	columnIndex: number;
	todayCoordinates: [number, number] | undefined;
	// Set to required on FG cleanup jsw_roadmaps_timeline-gridcell-header-contrast
	isHighlighted?: boolean;
	yearCaption?: string | undefined | null;
};

const WeekCell = ({
	columnIndex,
	weekNumbers,
	todayCoordinates,
	yearCaption,
	isHighlightToday,
	isHighlighted,
}: Props) => {
	const { formatMessage } = useIntl();

	// For the weeks granularity, the 4th day of the week determines the month that week falls in
	const monthNumber = weekNumbers[3].month;

	const todayIndex = weekNumbers.findIndex(
		(element: WeekNumber, i: number) =>
			isHighlightToday &&
			todayCoordinates &&
			todayCoordinates[0] === columnIndex &&
			todayCoordinates[1] === i,
	);

	return (
		<>
			<small css={[headerStyles, !isVisualRefreshEnabled() && headerTextTransformStyles]}>
				{fg('jsw_roadmaps_timeline-gridcell-header-contrast') ? (
					<Box
						as="span"
						xcss={[
							isHighlighted &&
								fg('jsw_roadmaps_timeline-gridcell-header-contrast') &&
								contrastStyles,
						]}
					>
						{formatMessage(
							isVisualRefreshEnabled() ? inMonthOrder[monthNumber] : inMonthOrderOld[monthNumber],
						)}{' '}
						{yearCaption}
					</Box>
				) : (
					<>
						{formatMessage(
							isVisualRefreshEnabled() ? inMonthOrder[monthNumber] : inMonthOrderOld[monthNumber],
						)}{' '}
						{yearCaption}
					</>
				)}
			</small>
			<div
				css={[
					!fg('jsw_roadmaps_timeline-gridcell-header-contrast') && dayContainerStylesOld,
					fg('jsw_roadmaps_timeline-gridcell-header-contrast') && dayContainerStyles,
				]}
			>
				{weekNumbers.map(({ day }: WeekNumber, dayIndex: number) =>
					fg('jsw_roadmaps_timeline-gridcell-header-contrast') ? (
						<span
							key={`week-number-${dayIndex}`}
							css={[
								dayChildStyles,
								(dayIndex === 5 || dayIndex === 6) && dayChildWeekendStyles,
								isHighlighted && dayChildHighlightedStyles,
								todayIndex === dayIndex && dayChildTodayStyles,
							]}
						>
							{day}
						</span>
					) : (
						<span
							key={`week-number-${dayIndex}`}
							css={todayIndex === dayIndex && dayChildTodayStylesOld}
						>
							{day}
						</span>
					),
				)}
			</div>
		</>
	);
};

const contrastStyles = xcss({
	color: 'color.text.accent.gray.bolder',
});

WeekCell.defaultProps = {
	yearCaption: null,
};

export default WeekCell;
export type { Props as APIProps };
