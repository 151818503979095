import { useEffect, useRef, useCallback } from 'react';
import { IDLE_THRESHOLD, DEFAULT_STATE } from './constants';
import type { ScrollingState } from './types';
import { hasStateChanged } from './utils';

/* This hook is used to notify when either horizontal or vertical scrolling is in progress.
 * The notification will only fire for *changes* to this state, and not for every scroll event.
 */
const useIsScrolling = (
	scrollElement: HTMLElement,
	onScrollChange: (scrollingState: ScrollingState) => void,
) => {
	const rafId = useRef<number | null>(null);

	const startTime = useRef(-1);
	const isScrolling = useRef(false);

	const lastPosition = useRef({ x: 0, y: 0 });
	const lastScrollState = useRef<ScrollingState>(DEFAULT_STATE);

	const scrollEnd = useCallback(() => {
		if (isScrolling.current) {
			startTime.current = -1;
			isScrolling.current = false;
			lastScrollState.current = DEFAULT_STATE;

			onScrollChange(DEFAULT_STATE);
		}

		if (rafId.current !== null) {
			cancelAnimationFrame(rafId.current);
			rafId.current = null;
		}
	}, [onScrollChange]);

	/* Update the state and recursively schedule animation frames until the user
	 * stops scrolling (indicated by no scroll events over a small time period).
	 * -
	 * To determine which axis is scrolling, we compare the previous scroll offset with the
	 * the current offset. This is imperfect, since it's possible to get identical offsets
	 * even while scrolling. To minimise the number of updates, which also cause UI flickering,
	 * we assert that the scrolling state can only be fully reset on scroll end.
	 */
	const tick = useCallback(() => {
		if (isScrolling.current) {
			const stopTime = performance.now();
			const time = stopTime - startTime.current;

			const { scrollTop, scrollLeft } = scrollElement;
			const scrollState = { ...lastScrollState.current };

			if (scrollTop !== lastPosition.current.y) {
				scrollState.isScrollingY = true;
			}

			if (scrollLeft !== lastPosition.current.x) {
				scrollState.isScrollingX = true;
			}

			lastPosition.current = { x: scrollLeft, y: scrollTop };

			if (time >= IDLE_THRESHOLD) {
				scrollEnd();
			} else {
				if (hasStateChanged(lastScrollState.current, scrollState)) {
					lastScrollState.current = { ...scrollState };
					onScrollChange(scrollState);
				}
				rafId.current = requestAnimationFrame(tick);
			}
		}
	}, [scrollElement, scrollEnd, onScrollChange]);

	const onScroll = useCallback(() => {
		isScrolling.current = true;
		startTime.current = performance.now();
		if (!rafId.current) {
			rafId.current = requestAnimationFrame(tick);
		}
	}, [tick]);

	useEffect(() => {
		scrollElement.addEventListener('scroll', onScroll);
		return () => {
			scrollElement.removeEventListener('scroll', onScroll);
			if (rafId.current !== null) {
				cancelAnimationFrame(rafId.current);
				rafId.current = null;
			}
		};
	}, [scrollElement, onScroll]);
};

export { useIsScrolling };
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ScrollingState };
