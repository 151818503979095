import { createContext, useContext } from 'react';
import defaultMetaGetter from '@atlassian/jira-get-meta';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { MetaConsumerData } from './types';

/**
 * Provide `getMeta(...)` via context.
 */
const MetaAPIContext = createContext<typeof defaultMetaGetter>(defaultMetaGetter);

export const { Provider: MetaAPIProvider, Consumer: MetaAPIConsumer } = MetaAPIContext;

/**
 * Return `getMeta` from context.
 */
export const useMetaAPI = (): typeof defaultMetaGetter => useContext(MetaAPIContext);
