import 'rxjs/add/operator/map';
import 'rxjs/add/observable/fromPromise';
import type { Observable } from 'rxjs/Observable';
import type { Ari } from '@atlassian/jira-platform-ari';
import getIssueIdsConcreteQuery, {
	type getIssueIdsQuery,
} from '@atlassian/jira-relay/src/__generated__/getIssueIdsQuery.graphql';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { generateGetIssueIdsQueryVariables } from '@atlassian/jira-software-roadmap-data-queries/src/queries/get-issue-ids/index.tsx';
import { GET_ISSUE_IDS } from '@atlassian/jira-software-roadmap-utils/src/utils/metrics/common/constants.tsx';
import { runQueryWithMetadata } from '../common/agg/run-query.tsx';
import { queryMetrics } from '../performance-analytics';

const analyticKey = 'jira.frontend.fe.software.roadmap.get-issue-ids';
const concurrentMetricDefinition = queryMetrics[GET_ISSUE_IDS];

export const getIssueIdsFromServer = (sourceAri: Ari): Observable<IssueId[]> =>
	runQueryWithMetadata<getIssueIdsQuery>(
		getIssueIdsConcreteQuery,
		generateGetIssueIdsQueryVariables(sourceAri),
		analyticKey,
		concurrentMetricDefinition,
	).map(
		({ data }) =>
			data.roadmaps?.roadmapForSource?.content?.items.edges
				.filter(Boolean) // TODO: "filter" to be removed after null/undefined is removed from edges in AGG schema
				.map(({ node: roadmapItem }): string => roadmapItem.id) ?? [],
	);
