import React, { useCallback, useRef, useMemo } from 'react';
import { IconButton } from '@atlaskit/button/new';
import Button from '@atlaskit/button/standard-button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import FeedbackIcon from '@atlaskit/icon/core/migration/feedback';
import SettingsIcon from '@atlaskit/icon/core/migration/settings';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import {
	EntryKeys,
	ENTRYPOINT_ID_JIRA_PLATFORM,
} from '@atlassian/jira-feedback-collector/src/constants.tsx';
import { useFeedbackCollectorActions } from '@atlassian/jira-feedback-collector/src/controllers/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { setReturnToProjectUrl } from '@atlassian/jira-navigation-apps-sidebar-project-utils';
import normaliseUrl from '@atlassian/jira-platform-url-normalisation';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ResponsiveButton } from '@atlassian/jira-responsive-button/src/ui/index.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { LicensedProducts } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import type { BoardId } from '@atlassian/jira-software-roadmap-model/src/app/index.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { useRouterActions } from '@atlassian/react-resource-router';
import { getRoadmapSettingsUrl } from '../../../utils/urls.tsx';
import messages from './messages';

type Props = {
	isCMP: boolean;
	boardId: BoardId;
	projectKey: ProjectKey;
	cloudId: string;
	boardType: string;
	canConfigureTimeline: boolean;
	defaultOpen?: boolean;
};

const licensedProductsToString: (arg1: LicensedProducts) => string = (products) =>
	Object.keys(products)
		.map((productKey) => (products[productKey] ? productKey : ''))
		.join(', ');

const MoreSettings = ({
	isCMP,
	boardId,
	projectKey,
	cloudId,
	boardType,
	canConfigureTimeline,
	defaultOpen = false,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { push } = useRouterActions();
	const isAdmin = useIsAdmin();
	const { licensedProducts } = useTenantContext();
	const { software: softwareEdition } = useAppEditions();

	const { openFeedbackCollector } = useFeedbackCollectorActions();

	const configureRoadmapAnalyticsEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'configureRoadmapButtonClicked',
	});

	const navigateToRoadmapSettingsPage = () => {
		fireUIAnalytics(configureRoadmapAnalyticsEvent);
		if (!isCMP) setReturnToProjectUrl();
		const url = getRoadmapSettingsUrl(isCMP, projectKey, boardId);
		push(url);
	};

	const feedbackButtonRef = useRef<HTMLButtonElement>(null);

	const additionalFeedbackEntries = useMemo(
		() => [
			{
				id: EntryKeys.OPT_OUT,
				value: 'No',
			},
			{
				id: EntryKeys.FEEDBACK_COLLECTOR_LOCATION,
				value: 'jsw.roadmap',
			},
			{
				id: EntryKeys.CLOUD_ID,
				value: cloudId,
			},
			{
				id: EntryKeys.IS_SIMPLIFIED_PROJECT,
				value: !isCMP.toString(),
			},
			{
				id: EntryKeys.BOARD_TYPE,
				value: boardType,
			},

			{
				id: EntryKeys.LOCATION,

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				value: normaliseUrl(window?.location?.href ?? ''),
			},
			{
				id: EntryKeys.REFERER,

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				value: document.referrer ? normaliseUrl(document.referrer) : '',
			},
			{
				id: EntryKeys.USER_AGENT,

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				value: navigator.userAgent,
			},
			{
				id: EntryKeys.SCREEN_RESOLUTION,

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				value: `${window.screen.width} x ${window.screen.height}`,
			},
			{
				id: EntryKeys.IS_ADMIN,
				value: isAdmin.toString(),
			},
			{
				id: EntryKeys.SUPPORTED_PROJECT_TYPES,
				value: licensedProductsToString(licensedProducts),
			},
			{
				id: EntryKeys.PROJECT_TYPE,
				value: 'software',
			},
			{
				id: EntryKeys.EDITION,
				value: softwareEdition,
			},
		],
		[boardType, cloudId, isCMP, isAdmin, licensedProducts, softwareEdition],
	);

	const onOpenFeedbackCollector = useCallback(() => {
		openFeedbackCollector({
			entrypointId: ENTRYPOINT_ID_JIRA_PLATFORM,
			feedbackTitle: formatMessage(messages.feedbackCollectorTitle),
			feedbackTitleDetails: formatMessage(messages.feedbackCollectorDescription),
			triggerRef: feedbackButtonRef,
			feedbackContext: additionalFeedbackEntries,
		});
	}, [openFeedbackCollector, formatMessage, additionalFeedbackEntries]);

	return (
		<DropdownMenu
			testId="roadmap.standard-roadmap.header.more-settings"
			// eslint-disable-next-line jira/react-no-inline-function-prop
			trigger={({ triggerRef, ...triggerProps }) =>
				// eslint-disable-next-line no-nested-ternary
				isVisualRefreshEnabled() ? (
					<IconButton
						{...triggerProps}
						icon={ShowMoreHorizontalIcon}
						label={formatMessage(messages.moreSettingsMenuLabel)}
						ref={triggerRef}
						isTooltipDisabled={false}
					/>
				) : getWillShowNav4() ? (
					<ResponsiveButton
						label={formatMessage(messages.moreSettingsMenuLabel)}
						icon={ShowMoreHorizontalIcon}
						ref={triggerRef}
						{...triggerProps}
					/>
				) : (
					<Button
						{...triggerProps}
						ref={triggerRef}
						appearance="subtle"
						iconAfter={<ShowMoreHorizontalIcon label="" color="currentColor" spacing="spacious" />}
					/>
				)
			}
			placement="bottom-end"
			defaultOpen={defaultOpen}
		>
			<DropdownItemGroup>
				{canConfigureTimeline && (
					<DropdownItem
						onClick={navigateToRoadmapSettingsPage}
						elemBefore={<SettingsIcon label="" color="currentColor" spacing="spacious" />}
						testId="roadmap.standard-roadmap.header.more-settings.configure-timeline"
					>
						{formatMessage(messages.moreSettingsButtonLabel)}
					</DropdownItem>
				)}
				{getWillShowNav4() && (
					<DropdownItem
						elemBefore={<FeedbackIcon label="" color="currentColor" spacing="spacious" />}
						onClick={onOpenFeedbackCollector}
						ref={feedbackButtonRef}
						testId="roadmap.standard-roadmap.header.more-settings.feedback"
					>
						{formatMessage(messages.feedbackButtonLabel)}
					</DropdownItem>
				)}
			</DropdownItemGroup>
		</DropdownMenu>
	);
};

export default MoreSettings;
