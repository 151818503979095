/** @jsx jsx */
import React, {
	useEffect,
	useState,
	memo,
	useCallback,
	type MouseEvent,
	type FocusEvent,
	type KeyboardEvent,
} from 'react';
import { css, keyframes, jsx } from '@compiled/react';
import Popup, { type ContentProps, type TriggerProps } from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import type { TriggerProps as TooltipProps } from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { mergeRefs } from '@atlassian/jira-merge-refs';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { HEADER_CONTENT_HEIGHT } from '@atlassian/jira-software-roadmap-timeline-table/src/common/constants.tsx';
import { useFocusMarshal } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/side-effect-marshal/focus-marshal';
import { useViewport } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/viewport/context/index.tsx';
import { isEnterOrSpaceKey } from '@atlassian/jira-software-roadmap-timeline-table/src/common/utils/events.tsx';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import {
	BAR_GAP_WIDTH,
	EXPANDABLE_INDICATOR_WIDTH,
} from '../../../../common/constants/chart-header.tsx';
import {
	HEADER_BACKGROUND_COLOR,
	HEADER_BACKGROUND_COLOR_OLD,
} from '../../../../common/constants/color.tsx';
import { OVERLAY_FLYOUT_Z_INDEX } from '../../../../common/constants/z-index.tsx';
import type { IntervalGroupPositions, Interval } from '../../../../common/types/interval.tsx';
import { useIsHeaderModalActive } from '../../../../controllers/table-providers/chart-item-interaction/main.tsx';
import { ExpandableIndicator } from './expandable-indicator';
import messages from './messages';
import { IntervalsTooltip } from './tooltip';
import { getLabel, getSprintAriaLabel } from './utils';
import { getDynamicMarkerStyles } from './utils/dynamic-styles.tsx';

// Used in the context of the *local* layout to show expanded items over their sibling items
const EXPANDED_Z_INDEX = 1;

const expandStyles = (right: number, rightExpanded: number, isExpanded: boolean) =>
	keyframes({
		from: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			right: `${isExpanded ? right : rightExpanded}px`,
		},
		to: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			right: `${isExpanded ? rightExpanded : right}px`,
		},
	});

const expandableContentStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	boxShadow: `inset -${BAR_GAP_WIDTH}px 0px 0px 0px ${HEADER_BACKGROUND_COLOR}`,
});

const expandableContentStylesOld = css({
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		boxShadow: `inset -${BAR_GAP_WIDTH}px 0px 0px 0px ${HEADER_BACKGROUND_COLOR_OLD}`,
	},
});

export type Props = {
	targetIntervalId?: string | undefined;
	intervalGroup: IntervalGroupPositions;
	/**
	 * render props method to show the Popup content when any key date been pressed
	 * @param selectedIntervals sprint intervals collection
	 * @returns
	 */
	renderPopupContent: (
		selectedIntervals: ReadonlyArray<Interval>,
		setInitialFocusRef?: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
	) => React.ReactNode;
};

/* A marker represents one or more intervals grouped together. The marker start is the start of the earliest interval,
 * and conversely, the marker end is the end of the latest interval.
 * Markers may be expandable or collapsible depending on its proximity to neighbouring markers.
 */
const BaseMarker = ({ targetIntervalId, intervalGroup, renderPopupContent }: Props) => {
	const { formatMessage } = useIntl();
	const { listenToViewportScroll, stopListeningToViewportScroll } = useViewport();
	const [isMouseEntered, setIsMouseEntered] = useState(false);
	const [isPopupActive, setIsPopupActive] = useState(false);
	const [{ isHeaderModalActive }] = useIsHeaderModalActive();

	const { shouldPreventNavigation } = useFocusMarshal();

	const openPopup = useCallback(() => {
		shouldPreventNavigation(true);
		setIsPopupActive(true);
	}, [shouldPreventNavigation]);

	const closePopup = useCallback(() => {
		shouldPreventNavigation(false);
		setIsPopupActive(false);
	}, [shouldPreventNavigation]);

	// Listen to user event scrolling the timeline table => Hide the popup on each time its been scrolled
	useEffect(() => {
		const onViewPortScrollHandler = () => {
			if (fg('timeline_grid_navigation_m2')) {
				closePopup();
			} else {
				setIsPopupActive(false);
			}
		};

		listenToViewportScroll(onViewPortScrollHandler);
		return function cleanup() {
			stopListeningToViewportScroll(onViewPortScrollHandler);
		};
	}, [closePopup, listenToViewportScroll, stopListeningToViewportScroll]);

	const { left, right, rightExpanded, intervals } = intervalGroup;
	const isExpandable = right !== rightExpanded;
	const isHighlighted = targetIntervalId !== undefined;
	const isExpanded = isExpandable && (isMouseEntered || isHighlighted);

	const { colors, borders, spacing, childVisibility } = getDynamicMarkerStyles({
		intervalGroup,
		isExpanded,
		isExpandable,
		isHighlighted,
	});

	const { isLabelVisible, isExpandIndicatorVisible } = childVisibility;
	const label = getLabel({ intervals, targetIntervalId, isLabelVisible });

	const { createAnalyticsEvent } = useAnalyticsEvents();

	// ================= //
	// === CALLBACKS === //
	// ================= //

	const onMouseEnter = () => {
		if (!isPopupActive) {
			setIsMouseEntered(true);
		}
	};

	const onMouseLeave = () => {
		if (!isPopupActive) {
			setIsMouseEntered(false);
		}
	};

	const onClickContainer = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			traceUFOPress('timeline-interval-marker-clicked', event.timeStamp);
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			});
			fireUIAnalytics(analyticsEvent, 'intervalsMarker', {
				numberOfMarker: intervals.length,
			});
			if (fg('timeline_grid_navigation_m2')) {
				openPopup();
			} else {
				setIsPopupActive(true);
			}
		},
		[createAnalyticsEvent, intervals.length, openPopup],
	);

	const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
		if (isEnterOrSpaceKey(event)) {
			event.preventDefault();
			if (fg('timeline_grid_navigation_m2')) {
				openPopup();
			} else {
				setIsPopupActive(true);
			}
		}
	};

	// ============== //
	// === RENDER === //
	// ============== //

	const renderMarker = (
		{ ref: triggerRef, ...restTriggerProps }: TriggerProps,
		tooltipProps?: TooltipProps,
	) => {
		const { ref: tooltipRef, onFocus, onBlur, onClick, ...restTooltipProps } = tooltipProps ?? {};

		const handleOnFocus = (event: FocusEvent<HTMLElement>) => {
			onMouseEnter();
			onFocus?.(event);
		};

		const handleOnBlur = (event: FocusEvent<HTMLElement>) => {
			onMouseLeave();
			onBlur?.(event);
		};

		const handleOnClick = (event: MouseEvent<HTMLElement>) => {
			onClickContainer(event);
			onClick?.(event);
		};

		if (!isExpandable) {
			return (
				// eslint-disable-next-line @atlaskit/design-system/no-html-button
				<span
					{...restTriggerProps}
					{...restTooltipProps}
					ref={tooltipRef ? mergeRefs(triggerRef, tooltipRef) : triggerRef}
					role="button"
					aria-label={`${formatMessage(messages.sprintsCountLabel, { sprintsCount: intervals.length })} ${getSprintAriaLabel(intervals, formatMessage)}`}
					css={[baseContainerStyles, overflowStyles]}
					style={{
						color: colors.color,
						backgroundColor: colors.backgroundColor,
						border: borders.border ?? 'none',
						borderRadius: borders.borderRadius,
						padding: spacing.padding,
						margin: spacing.margin,
						left: `${left}px`,
						right: `${right}px`,
					}}
					tabIndex={0}
					data-testid="roadmap.timeline-table-kit.ui.chart-header-item.intervals.marker.content"
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					onFocus={handleOnFocus}
					onBlur={handleOnBlur}
					onClick={handleOnClick}
					onKeyDown={handleKeyDown}
				>
					{label}
				</span>
			);
		}

		// Temporary fix on issue where compiled/css doesn't work using "keyframes" inside props.style but only for `css` utility function (been reported as bug in #help-compiled previously)
		const animationStyles = css({
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			animation: `${expandStyles(right, rightExpanded, isExpanded)} 0.2s ease-in-out`,
		});

		return (
			// eslint-disable-next-line @atlaskit/design-system/no-html-button
			<div
				{...restTriggerProps}
				{...restTooltipProps}
				ref={tooltipRef ? mergeRefs(triggerRef, tooltipRef) : triggerRef}
				role="button"
				aria-label={`${formatMessage(messages.sprintsCountLabel, { sprintsCount: intervals.length })} ${getSprintAriaLabel(intervals, formatMessage)}`}
				css={[
					expandedContainerStyles,
					baseContainerStyles,
					// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
					!fg('jsw_roadmaps_timeline-fix-sprint-lozenge-styling') && animationStyles,
					transitionStyles,
				]}
				style={{
					left: `${left}px`,
					right: `${isExpanded ? rightExpanded : right}px`,
					zIndex: isExpanded ? EXPANDED_Z_INDEX : 'auto',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
					...(fg('jsw_roadmaps_timeline-fix-sprint-lozenge-styling')
						? { margin: spacing.margin }
						: {}),
				}}
				tabIndex={0}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				onFocus={handleOnFocus}
				onBlur={handleOnBlur}
				onClick={handleOnClick}
				onKeyDown={handleKeyDown}
			>
				<span
					data-testid="roadmap.timeline-table-kit.ui.chart-header-item.intervals.marker.content"
					css={[
						overflowStyles,
						isExpanded &&
							fg('jsw_roadmaps_timeline-fix-sprint-lozenge-styling') &&
							expandableContentStyles,
						!fg('jsw_roadmaps_timeline-fix-sprint-lozenge-styling') && expandableContentStylesOld,
					]}
					style={{
						color: colors.color,
						backgroundColor: colors.backgroundColor,
						border: borders.border ?? 'none',
						borderRadius: borders.borderRadius,
						padding: spacing.padding,
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
						...(fg('jsw_roadmaps_timeline-fix-sprint-lozenge-styling')
							? {}
							: { margin: spacing.margin }),
						width: isExpandIndicatorVisible
							? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
								`calc(100% - ${EXPANDABLE_INDICATOR_WIDTH}px)`
							: '100%',
					}}
				>
					{label}
				</span>
				{isExpandIndicatorVisible && (
					<ExpandableIndicator backgroundColor={colors.backgroundColor} />
				)}
			</div>
		);
	};

	/**
	 * Callback to render the user selected interval flyout popup content
	 */
	const renderSelectedIntervalPopupContent = useCallback(
		({ setInitialFocusRef }: ContentProps) => renderPopupContent(intervals, setInitialFocusRef),
		[intervals, renderPopupContent],
	);

	const renderPopupTrigger = (triggerProps: TriggerProps) => (
		<IntervalsTooltip intervals={intervals}>
			{(tooltipProps?: TooltipProps) => renderMarker(triggerProps, tooltipProps)}
		</IntervalsTooltip>
	);

	const onClosePopup = () => {
		if (!isHeaderModalActive) {
			if (isMouseEntered) {
				setIsMouseEntered(false);
			}
			if (fg('timeline_grid_navigation_m2')) {
				closePopup();
			} else {
				setIsPopupActive(false);
			}
		}
	};

	return (
		<Popup
			isOpen={isPopupActive}
			onClose={onClosePopup}
			placement="bottom-start"
			content={renderSelectedIntervalPopupContent}
			trigger={renderPopupTrigger}
			zIndex={OVERLAY_FLYOUT_Z_INDEX}
			autoFocus={false}
			{...(fg('jsw_roadmaps_timeline-fix-sprint-lozenge-styling')
				? { role: 'dialog', label: formatMessage(messages.popupLabel) }
				: {})}
		/>
	);
};

const IntervalBaseMarker = memo<Props>(BaseMarker);
export { IntervalBaseMarker };

const baseContainerStyles = css({
	position: 'absolute',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${HEADER_CONTENT_HEIGHT}px`,
	minWidth: token('space.025', '2px'),
	textAlign: 'center',
	font: token('font.body.small'),
	lineHeight: token('space.150', '12px'),
	fontWeight: token('font.weight.bold'),
	cursor: 'pointer',
});

const overflowStyles = css({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

const expandedContainerStyles = css({
	display: 'flex',
});

const transitionStyles = css({
	transition: 'right 0.2s ease-in-out',
});
