// ============================================== //
// === Catch all - Generic constants go here! === //
// ============================================== //

export const TOP = 'TOP' as const;
export const BOTTOM = 'BOTTOM' as const;
export const LEFT = 'LEFT' as const;
export const RIGHT = 'RIGHT' as const;
export const LEFT_AND_RIGHT = 'LEFT_AND_RIGHT' as const;

export const START_DATE_RELATIVE = 'START_DATE_RELATIVE' as const;
export const DUE_DATE_RELATIVE = 'DUE_DATE_RELATIVE' as const;
export const START_DATE_AND_DUE_DATE_RELATIVE = 'START_DATE_AND_DUE_DATE_RELATIVE' as const;

// TABLE HEADER ID
export const RELEASES_HEADER_ID = 'RELEASES_HEADER_ID';
export const SPRINTS_HEADER_ID = 'SPRINTS_HEADER_ID';

// RENDERERS
export const DEFAULT_RENDERER = () => null;
