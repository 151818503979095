import { createSelector } from 'reselect';
import {
	getIsSprintsPlanningEnabled,
	getIsChildIssuePlanningEnabled,
} from '../../../configuration/selectors';
import {
	getIssueChildrenHash,
	getIssueDueDateHash,
	getIssueStartDateHash,
} from '../../../entities/issues/selectors';
import { createGetIssueSprintDates } from '../../sprint';
import {
	type RolledUpDates,
	type BoundaryDates,
	getBoundaryDatesHash as getBoundaryDatesHashPure,
	getParentRolledUpDatesHash as getParentRolledUpDatesHashPure,
} from './pure';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { RolledUpDates, BoundaryDates };

export const getParentRolledUpDatesHash = createSelector(
	getIssueChildrenHash,
	createGetIssueSprintDates,
	getIssueStartDateHash,
	getIssueDueDateHash,
	getIsSprintsPlanningEnabled,
	getIsChildIssuePlanningEnabled,
	getParentRolledUpDatesHashPure,
);

/**
 * Returns a hash of the boundary dates for each parent, derived from child explicit dates or sprint dates
 */
export const getExplicitBoundaryDatesHash = createSelector(
	getIssueChildrenHash,
	getIssueStartDateHash,
	getIssueDueDateHash,
	createGetIssueSprintDates,
	getIsSprintsPlanningEnabled,
	getBoundaryDatesHashPure,
);
