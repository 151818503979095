import React from 'react';
import { DEFAULT_RENDERER } from '../../../../../common/constants';
import type { BarContentRenderers, BarContentProps, BarContentState } from './types';

type Props = BarContentRenderers & BarContentProps & BarContentState;

const RoadmapBarContent = ({
	id,
	dragType,
	leftPosition,
	rightPosition,
	prevStartDate,
	prevDueDate,
	startDate,
	dueDate,
	startDateType,
	dueDateType,
	placeholder,
	isActive,
	isReadOnly,
	isMinimumBar,
	renderBarIcon,
	renderDateLabel,
	renderDependencyHandler,
	onBarMouseLeave,
	getBarBoundingClientRect,
}: Props) => {
	const isHovered = isActive && !dragType;
	const isDraggingExplicit = isActive && dragType !== undefined;
	const isDragging = dragType !== undefined;

	const getRenderDependencyHandler = () =>
		!isDragging &&
		!isReadOnly &&
		renderDependencyHandler(
			{
				id,
				leftPosition,
				rightPosition,
				placeholder,
				getBarBoundingClientRect,
				onBarMouseLeave,
			},
			{ isHovered },
		);

	const getRenderDateLabel = () =>
		isDraggingExplicit || isHovered
			? renderDateLabel({
					prevStartDate,
					prevDueDate,
					startDate,
					dueDate,
					startDateType,
					dueDateType,
					placeholder,
					getBarBoundingClientRect,
					dragType,
				})
			: null;

	const getRenderBarIcon = () =>
		!isMinimumBar && !isDraggingExplicit && renderBarIcon({ id, leftPosition, rightPosition });

	return (
		<>
			{getRenderDependencyHandler()}
			{getRenderDateLabel()}
			{getRenderBarIcon()}
		</>
	);
};

RoadmapBarContent.defaultProps = {
	renderBarIcon: DEFAULT_RENDERER,
	renderDateLabel: DEFAULT_RENDERER,
	renderDependencyHandler: DEFAULT_RENDERER,
};

export { RoadmapBarContent };
