// Allow PureComponent
// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type ComponentType } from 'react';
import ReactDOM from 'react-dom';
import omit from 'lodash/omit';
import type {
	Position,
	OnMouseDown,
	OnClick,
	OnDragStart,
	OnDrag,
	OnDragEnd,
} from '../common/types';
import { createRaf } from '../common/utils';

export const DEFAULT_DRAG_THRESHOLD = 3;

const DISABLED_DRAG_PROPS = ['onMouseDown', 'onDragStart', 'onDrag', 'onDragEnd'];
const ENABLED_DRAG_PROPS = ['onMouseDown', 'onClick', 'onDragStart', 'onDrag', 'onDragEnd'];

const getMousePosition = (event: MouseEvent) => ({
	x: event.pageX,
	y: event.pageY,
});

const requestAnimationFrame = createRaf();

export type Props = {
	isDisabled: boolean;
	// Immediately when the mouse was pressed down
	onMouseDown: OnMouseDown | undefined;
	// Called when the mouse was pressed, not moved beyond the drag threshold, then released
	onClick: OnClick | undefined;
	// Called when the mouse was pressed down, then moved beyond the drag threshold.
	// Is provided the (page relative) position the drag was started from.
	onDragStart: OnDragStart | undefined;
	// Called when dragging.
	// Is provided the from, to and start positions
	onDrag: OnDrag | undefined;
	// Called when dragging and the mouse was released
	// Is provided the start and end positions of the entire drag
	onDragEnd: OnDragEnd | undefined;
};

type State = {
	dragStart: Position;
	previousDragPosition: Position;
	isDragging: boolean;
};

export const withDragObserver = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	WrappedComponent: ComponentType<any>,
	threshold: number = DEFAULT_DRAG_THRESHOLD,
) => {
	// eslint-disable-next-line jira/react/no-class-components
	class DragObserver extends PureComponent<Props, State> {
		static defaultProps = {
			isDisabled: false,
			onMouseDown: undefined,
			onDragStart: undefined,
			onDrag: undefined,
			onDragEnd: undefined,
			onClick: undefined,
		};

		constructor(props: Props) {
			super(props);

			this.animationId = undefined;
			this.mounted = false;
			this.state = {
				isDragging: false,
				dragStart: { x: 0, y: 0 },
				previousDragPosition: { x: 0, y: 0 },
			};
		}

		componentDidMount() {
			this.mounted = true;
			// eslint-disable-next-line react/no-find-dom-node
			this.ref = ReactDOM.findDOMNode(this);

			if (this.ref) {
				// @ts-expect-error - TS2345 - Argument of type '(event: MouseEvent) => void' is not assignable to parameter of type 'EventListenerOrEventListenerObject'.
				this.ref.addEventListener('mousedown', this.onChildMouseDown);
			}
		}

		componentWillUnmount() {
			if (this.ref) {
				// @ts-expect-error - TS2345 - Argument of type '(event: MouseEvent) => void' is not assignable to parameter of type 'EventListenerOrEventListenerObject'.
				this.ref.removeEventListener('mousedown', this.onChildMouseDown);
			}

			this.removeWindowEventListeners();

			if (this.animationId !== undefined) {
				cancelAnimationFrame(this.animationId);
				this.animationId = undefined;
			}
			this.mounted = false;
		}

		addWindowEventListeners() {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('mousemove', this.onWindowMouseMove);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('mouseup', this.onWindowMouseUp);
		}

		removeWindowEventListeners() {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('mousemove', this.onWindowMouseMove);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('mouseup', this.onWindowMouseUp);
		}

		onChildMouseDown = (event: MouseEvent) => {
			// Do not start dragging if mouse down with right click
			if (this.props.isDisabled || event.button !== 0) {
				return;
			}

			event.preventDefault();
			event.stopPropagation();

			this.addWindowEventListeners();

			const dragStart = getMousePosition(event);

			this.setState(
				{
					isDragging: false,
					dragStart,
					previousDragPosition: dragStart,
				},
				() => this.onMouseDown(event),
			);
		};

		onWindowMouseMove = (event: MouseEvent) => {
			event.preventDefault();

			const mouse = getMousePosition(event);
			const { isDragging, dragStart, previousDragPosition } = this.state;

			if (!isDragging) {
				if (
					Math.abs(mouse.x - dragStart.x) > threshold ||
					Math.abs(mouse.y - dragStart.y) > threshold
				) {
					this.setState({ isDragging: true });
					this.onDragStart(dragStart);
				}
			} else {
				const from = previousDragPosition;
				const to = mouse;

				this.setState({ previousDragPosition: mouse });

				this.onDrag(from, to, dragStart);
			}
		};

		onWindowMouseUp = (event: MouseEvent) => {
			const { isDragging, dragStart } = this.state;

			if (isDragging) {
				const mouse = getMousePosition(event);
				const start = dragStart;
				const end = mouse;

				if (this.mounted) {
					this.setState({ isDragging: false });
				}
				this.onDragEnd(start, end);
			} else if (
				event.button === 0 &&
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				((navigator?.userAgent ?? '').includes('Mac') ? !event.ctrlKey : true)
			) {
				// Trigger onClick on left clicks only. Checking ctrlKey as ctrl + click could emulate right-click on Mac
				this.onClick(event);
			}

			this.removeWindowEventListeners();
		};

		onMouseDown(event: MouseEvent) {
			if (this.props.onMouseDown) {
				this.props.onMouseDown(event);
			}
		}

		onDragStart(from: Position) {
			if (this.props.onDragStart) {
				this.props.onDragStart(from);
			}
		}

		onDrag(from: Position, to: Position, start: Position) {
			if (this.props.onDrag) {
				this.animationId = requestAnimationFrame(() => {
					if (this.props.onDrag && this.state.isDragging) {
						this.props.onDrag(from, to, start);
					}
				});
			}
		}

		onDragEnd(from: Position, to: Position) {
			if (this.props.onDragEnd) {
				this.props.onDragEnd(from, to);
			}
		}

		onClick(event: MouseEvent) {
			if (this.props.onClick) {
				this.props.onClick(event);
			}
		}

		/* isMounted is a class method and thus reserved */
		mounted: boolean;

		/* Animation frame id for drag observer */
		animationId: number | undefined;

		// @ts-expect-error - TS2564 - Property 'ref' has no initializer and is not definitely assigned in the constructor.
		ref: Element | Text | null;

		render() {
			const { isDisabled } = this.props;
			const { isDragging } = this.state;

			/* When dragging is disabled, clicking should still be enabled.
			 * Rather than try handle onClick in this HOC, we instead just allow
			 * the onClick passed in to make it to the underlying component.
			 */
			const safeProps = omit(this.props, isDisabled ? DISABLED_DRAG_PROPS : ENABLED_DRAG_PROPS);

			return <WrappedComponent isDragging={isDragging} {...safeProps} />;
		}
	}

	return DragObserver;
};
