import { LEFT_AND_RIGHT, LEFT, RIGHT } from '../../constants';
import {
	START_DATE_PLACEHOLDER,
	DUE_DATE_PLACEHOLDER,
	START_AND_DUE_DATE_PLACEHOLDER,
} from '../../constants/chart-item.tsx';
import type { BarDragType, Placeholder } from '../../types/chart-item.tsx';

export const isStartDatePlaceholder = (placeholder: Placeholder) =>
	placeholder === START_DATE_PLACEHOLDER || placeholder === START_AND_DUE_DATE_PLACEHOLDER;

export const isDueDatePlaceholder = (placeholder: Placeholder) =>
	placeholder === DUE_DATE_PLACEHOLDER || placeholder === START_AND_DUE_DATE_PLACEHOLDER;

export const getPlaceholderWhileDragging = (
	dragType: BarDragType | undefined,
	placeholder: Placeholder,
) => {
	if (dragType === LEFT_AND_RIGHT) {
		return undefined;
	}

	if (dragType === LEFT && placeholder === START_DATE_PLACEHOLDER) {
		return undefined;
	}

	if (dragType === RIGHT && placeholder === DUE_DATE_PLACEHOLDER) {
		return undefined;
	}

	if (dragType === RIGHT && placeholder === START_AND_DUE_DATE_PLACEHOLDER) {
		return START_DATE_PLACEHOLDER;
	}

	if (dragType === LEFT && placeholder === START_AND_DUE_DATE_PLACEHOLDER) {
		return DUE_DATE_PLACEHOLDER;
	}

	return placeholder;
};

export const getPlaceholder = (
	hasLeftPlaceholder: boolean,
	hasRightPlaceholder: boolean,
): Placeholder => {
	if (hasLeftPlaceholder && hasRightPlaceholder) {
		return START_AND_DUE_DATE_PLACEHOLDER;
	}
	if (hasLeftPlaceholder) {
		return START_DATE_PLACEHOLDER;
	}
	if (hasRightPlaceholder) {
		return DUE_DATE_PLACEHOLDER;
	}
	return undefined;
};
