import get from 'lodash/get';
import { commitMutation } from 'react-relay';
import type { GraphQLTaggedNode, MutationParameters, VariablesOf } from 'relay-runtime';
import { Observable } from 'rxjs/Observable';
import uuid from 'uuid';
import { fg } from '@atlassian/jira-feature-gating';
import getRelayEnvironment from '@atlassian/jira-relay-environment';
import { startCaptureGraphQlErrors } from '@atlassian/jira-relay-errors';
import {
	getTraceIds,
	startCapturingTraceIds,
	stopCapturingTraceIds,
} from '@atlassian/relay-traceid';
import type { Metric } from './types';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import {
	commitMutationObservable,
	getGraphQlErrorsForOperation,
	sendOperationalEvent,
	sendOperationalEventV2OnError,
	sendOperationalEventV2OnSuccess,
	transformGenericError,
	transformGraphQLErrors,
} from './utils';

export const runMutation = <TMutation extends MutationParameters>(
	mutationName: string,
	mutation: GraphQLTaggedNode,
	variables: VariablesOf<TMutation>,
	analyticKey: string,
	concurrentMetricDefinition: (concurrentId: string) => Metric,
): Observable<TMutation['response']> => {
	const operationName = `${mutationName}Mutation`;
	const concurrentId = uuid.v4();
	startCapturingTraceIds(operationName);

	const errorCollector: string = startCaptureGraphQlErrors();

	if (fg('jsw-roadmap-unsubscribable-mutation')) {
		concurrentMetricDefinition(concurrentId).start();
		return commitMutationObservable<TMutation>(getRelayEnvironment(), {
			mutation,
			variables,
		})
			.do((data) => {
				concurrentMetricDefinition(concurrentId).stop();
				const mutationPayload = get(data, ['roadmaps', mutationName]);

				if (!mutationPayload || mutationPayload.success !== true) {
					const statusCode = mutationPayload
						? get(mutationPayload, ['errors', 0, 'extensions', 'statusCode'], 'unknown')
						: 'unknown';

					const relevantErrors = getGraphQlErrorsForOperation(errorCollector, operationName);

					const transformedError = transformGraphQLErrors(
						relevantErrors,
						operationName,
						getTraceIds(operationName),
					);

					sendOperationalEventV2OnError(transformedError, analyticKey, false, String(statusCode));
				} else {
					sendOperationalEvent(analyticKey, '200', getTraceIds(operationName));
				}
				stopCapturingTraceIds(operationName);
			})
			.catch((error: Error) => {
				concurrentMetricDefinition(concurrentId).stop();
				const transformedError = transformGenericError(
					error,
					getTraceIds(operationName),
					operationName,
				);
				sendOperationalEventV2OnError(transformedError, analyticKey, false);
				stopCapturingTraceIds(operationName);

				throw error;
			});
	}

	return Observable.fromPromise(
		new Promise(
			(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				resolve: (result: Promise<Record<any, any>> | Record<any, any>) => void,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				reject: (error?: any) => void,
			) => {
				concurrentMetricDefinition(concurrentId).start();
				commitMutation<TMutation>(getRelayEnvironment(), {
					mutation,
					variables,
					onCompleted: (data: TMutation['response']) => {
						concurrentMetricDefinition(concurrentId).stop();
						const mutationPayload = get(data, ['roadmaps', mutationName]);
						if (!mutationPayload || mutationPayload.success !== true) {
							const statusCode = mutationPayload
								? get(mutationPayload, ['errors', 0, 'extensions', 'statusCode'], 'unknown')
								: 'unknown';

							const relevantErrors = getGraphQlErrorsForOperation(errorCollector, operationName);

							const transformedError = transformGraphQLErrors(
								relevantErrors,
								operationName,
								getTraceIds(operationName),
							);

							sendOperationalEventV2OnError(
								transformedError,
								analyticKey,
								false,
								String(statusCode),
							);
						} else {
							sendOperationalEventV2OnSuccess(analyticKey, getTraceIds(operationName));
						}
						stopCapturingTraceIds(operationName);
						resolve(data);
					}, // network errors
					onError: (error: Error) => {
						concurrentMetricDefinition(concurrentId).stop();
						const transformedError = transformGenericError(
							error,
							getTraceIds(operationName),
							operationName,
						);
						sendOperationalEventV2OnError(transformedError, analyticKey, false);
						stopCapturingTraceIds(operationName);
						reject(error);
					},
				});
			},
		),
	);
};
