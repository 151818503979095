import React, { type ComponentType, type ReactElement } from 'react';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { Color } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { useTimelineState } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/timeline/index.tsx';
import { EXPLICIT } from '../../../common/constants/date.tsx';
import type {
	Placeholder,
	OnChartItemClick,
	OnChartItemUpdate,
} from '../../../common/types/chart-item.tsx';
import type { Position } from '../../../common/types/common';
import type { DateType } from '../../../common/types/date.tsx';
import { FormattedDate } from '../../../common/ui/formatted-date/main.tsx';
import {
	getBarLeftPosition,
	getBarRightPosition,
	getIsBarVisible,
} from '../../../common/utils/bar/positions.tsx';
import { RoadmapBar, type BarContentProps, type BarContentState } from './bar';
import { RoadmapNavigateButton } from './navigate-button';

type Props = {
	isHovered: boolean;
	isReadOnly: boolean;
	id: string;
	color: Color;
	level: number;
	startDate: number;
	dueDate: number;
	startDateType: DateType;
	dueDateType: DateType;
	warnings: MessageDescriptor[];
	placeholder?: Placeholder;
	DndLongTaskMetric: ComponentType<{
		isDragging: boolean;
		level: number;
	}>;
	renderBarContent: (props: BarContentProps, state: BarContentState) => ReactElement | null;
	onUpdate: OnChartItemUpdate;
	onLeftClick: OnChartItemClick;
	onRightClick?: (id: string, position: Position) => void;
};

const DateContent = ({
	id,
	color,
	level,
	startDate,
	dueDate,
	startDateType,
	dueDateType,
	warnings,
	placeholder,
	isHovered,
	isReadOnly,
	DndLongTaskMetric,
	renderBarContent,
	onUpdate,
	onLeftClick,
	onRightClick,
}: Props) => {
	const [{ timelineDuration, timelineWidth }] = useTimelineState();

	const barLeftPosition = getBarLeftPosition(startDate, timelineDuration, timelineWidth);
	const barRightPosition = getBarRightPosition(dueDate, timelineDuration, timelineWidth);
	const isBarVisible = getIsBarVisible(barLeftPosition, barRightPosition, timelineWidth);

	if (!isBarVisible) {
		return null;
	}

	return (
		<>
			<RoadmapBar
				id={id}
				left={barLeftPosition}
				right={barRightPosition}
				color={color}
				level={level}
				startDateType={startDateType}
				dueDateType={dueDateType}
				warnings={warnings}
				placeholder={placeholder}
				isReadOnly={isReadOnly}
				onUpdate={onUpdate}
				onLeftClick={onLeftClick}
				onRightClick={onRightClick}
				renderBarContent={renderBarContent}
				DndLongTaskMetric={DndLongTaskMetric}
			/>
			{isHovered && (
				<RoadmapNavigateButton
					itemMinLeftPosition={barLeftPosition}
					itemMaxRightPosition={barRightPosition}
					label={
						<FormattedDate
							startDate={startDate}
							startDateType={startDateType}
							dueDate={dueDate}
							dueDateType={dueDateType}
							placeholder={placeholder}
							isDurationVisible={!placeholder}
						/>
					}
				/>
			)}
		</>
	);
};

DateContent.defaultProps = {
	startDateType: EXPLICIT,
	dueDateType: EXPLICIT,
};

export { DateContent };
