import { fetchQuery, commitMutation } from 'react-relay';
import type {
	Observable as RelayObservable,
	GraphQLTaggedNode,
	CacheConfig,
	FetchQueryFetchPolicy,
	OperationType,
	MutationConfig,
	MutationParameters,
} from 'relay-runtime';
import type { Environment } from 'relay-runtime/lib/store/RelayStoreTypes';
import { Observable } from 'rxjs/Observable';
import type { Subscriber } from 'rxjs/Subscriber';
import FetchError, { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { stopCaptureGraphQlErrors, type AGGError } from '@atlassian/jira-relay-errors';
import {
	AggError,
	GRAPHQL_ERROR,
	INVALID_PAYLOAD_ERROR,
	NETWORK_ERROR,
	UNKNOWN_ERROR,
	type AggErrorInstance,
} from './agg-error';
import type { ExecutionOptions } from './types';

const statusCodeRegex = /^[12345][0-9]{2}$/;

export const getGraphQlErrorsForOperation = (
	errorCollector: string,
	operationName: string,
	executionOptions: ExecutionOptions = {},
): AGGError[] => {
	const errorsWithMeta = stopCaptureGraphQlErrors(errorCollector);
	if (Array.isArray(errorsWithMeta)) {
		const operationErrors = errorsWithMeta
			.filter((errorWithMeta) => errorWithMeta.meta.operationName === operationName)
			.flatMap((errorWithMeta) => errorWithMeta.errors);

		return executionOptions.graphqlErrorFilter
			? executionOptions.graphqlErrorFilter(operationErrors)
			: operationErrors;
	}
	return [];
};

export const sendOperationalEvent = (
	analyticKey: string,
	statusCode: string | number | null,
	aggTraceIds: string[],
	isRetried: boolean | undefined = undefined,
	cause: string | undefined = undefined,
): void => {
	getAnalyticsWebClientPromise().then((client) => {
		const analyticsClient = client.getInstance();

		// SSR doesn't have an analytics client
		if (!analyticsClient) {
			return;
		}

		let statusCodeFamily = 'unknown';
		if (statusCode !== null) {
			const statusCodeAsStr = String(statusCode);
			if (statusCodeAsStr === '0' || statusCodeAsStr === '429') {
				// special status codes
				statusCodeFamily = statusCodeAsStr;
			} else if (statusCodeAsStr.match(/^[12345][0-9]{2}$/)) {
				statusCodeFamily = `${statusCodeAsStr[0]}xx`;
			}
		}

		analyticsClient.sendOperationalEvent({
			action: 'fetch',
			actionSubject: 'relay operation',
			attributes: {
				key: analyticKey,
				statusCode,
				statusCodeFamily,
				traceId: aggTraceIds[0],

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				frontendVersion: window.BUILD_KEY,
				isRetried,
				cause,
			},
			source: 'relayOperation',
		});
	});
};

export const sendOperationalEventV2 = (
	analyticKey: string,
	statusCode: string | number | null,
	aggTraceIds: string[],
	isRetried: boolean | undefined,
	errorDetails?: {
		cause: string | undefined;
		affectsReliability: boolean;
		skipSentry: boolean;
	},
): void => {
	getAnalyticsWebClientPromise().then((client) => {
		const analyticsClient = client.getInstance();

		// SSR doesn't have an analytics client
		if (!analyticsClient) {
			return;
		}

		let statusCodeFamily = 'unknown';
		if (statusCode !== null) {
			const statusCodeAsStr = String(statusCode);
			if (statusCodeAsStr === '0' || statusCodeAsStr === '429') {
				// special status codes
				statusCodeFamily = statusCodeAsStr;
			} else if (statusCodeAsStr.match(statusCodeRegex)) {
				statusCodeFamily = `${statusCodeAsStr[0]}xx`;
			}
		}

		analyticsClient.sendOperationalEvent({
			action: 'fetch',
			actionSubject: 'relay operation',
			attributes: {
				key: analyticKey,
				statusCode,
				statusCodeFamily,
				traceId: aggTraceIds[0],

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				frontendVersion: window.BUILD_KEY,
				affectsReliability: errorDetails?.affectsReliability || false,
				skipSentry: errorDetails?.skipSentry || false,
				isRetried,
				cause: errorDetails?.cause,
			},
			source: 'relayOperation',
		});
	});
};

export const fromRelayObservable = <T,>(create: () => RelayObservable<T>): Observable<T> =>
	Observable.create((sink: Subscriber<T>) => {
		const subscription = create().subscribe(sink);

		return () => subscription.unsubscribe();
	});

export const fetchQueryObservable = <T extends OperationType>(
	environment: Environment,
	taggedNode: GraphQLTaggedNode,
	variables: T['variables'],
	cacheConfig?: {
		networkCacheConfig?: CacheConfig | null | undefined;
		fetchPolicy?: FetchQueryFetchPolicy | null | undefined;
	} | null,
): Observable<T['response']> =>
	fromRelayObservable(() => fetchQuery<T>(environment, taggedNode, variables, cacheConfig));

export const commitMutationObservable = <T extends MutationParameters = MutationParameters>(
	environment: Environment,
	config: Omit<MutationConfig<T>, 'onCompleted' | 'onError' | 'onUnsubscribe'>,
): Observable<T['response']> =>
	Observable.create((subscriber: Subscriber<T['response']>) => {
		const disposable = commitMutation<T>(environment, {
			...config,
			onCompleted: (response) => {
				subscriber.next(response);
				subscriber.complete();
			},
			onError: (err) => subscriber.error(err),
			onUnsubscribe: () => subscriber.unsubscribe(),
		});

		return () => disposable.dispose();
	});

export const transformGenericError = (
	error: Error,
	traceIds: string[] | [],
	operationName: string,
) => {
	// default error if we could not interpret the error
	let transformedError: AggErrorInstance = new AggError(
		error.message ?? `An unknown error has occured from ${operationName}`,
		[],
		traceIds,
		UNKNOWN_ERROR,
		error,
	);

	if (error instanceof ValidationError) {
		const newTraceId = error.traceId != null ? [error.traceId] : traceIds;
		transformedError = new AggError(
			`Query ${operationName} failed while fetching the data: HTTP ${error.statusCode}`,
			[
				{
					message: error.message,
					extensions: {
						statusCode: error.statusCode,
						errorType: NETWORK_ERROR,
					},
				},
			],
			newTraceId,
			NETWORK_ERROR,
			error,
		);
	}

	if (error instanceof DOMException && error.name === 'AbortError') {
		// When abort() is called, the fetch() promise rejects with an Error of type DOMException, with name AbortError.
		// See https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort for more details
		transformedError = new AggError(
			`Query ${operationName} was aborted.`,
			[],
			traceIds,
			NETWORK_ERROR,
			error,
		);
	}

	if (error instanceof SyntaxError) {
		// SyntaxError will usually be thrown when the json format is invalid.
		// Currently no details are provided (traceId status code or content types).
		// Follow up task to improve this https://jdog.jira-dev.com/browse/ROAD-5547

		transformedError = new AggError(
			`Query ${operationName} response payload was invalid and could not be parsed`,
			[],
			traceIds,
			INVALID_PAYLOAD_ERROR,
			error,
		);
	}

	if (error instanceof FetchError) {
		const newTraceId = error.traceId != null ? [error.traceId] : traceIds;
		transformedError = new AggError(
			`Query ${operationName} failed while fetching the data: HTTP ${error.statusCode}`,
			[
				{
					message: error.message,
					extensions: {
						statusCode: error.statusCode,
						errorType: NETWORK_ERROR,
					},
				},
			],
			newTraceId,
			NETWORK_ERROR,
			error,
		);
	}

	return transformedError;
};

export const transformGraphQLErrors = (
	relevantErrors: AGGError[],
	operationName: string,
	traceIds: string[] | [],
) =>
	new AggError(
		`Query ${operationName} had graphql errors`,
		relevantErrors,
		traceIds,
		GRAPHQL_ERROR,
	);

export const sendOperationalEventV2OnError = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error: any,
	analyticKey: string,
	isRetried: boolean,
	statusCodeOveride?: string,
) => {
	let traceIds: string[] = [];
	let statusCode = 'unknown';
	let cause = '';
	let skipSentry = false;
	let affectsReliability = true;

	if (error instanceof AggError) {
		traceIds = error.traceIds;
		affectsReliability = error.affectsReliability;
		skipSentry = error.skipSentry;
		statusCode = statusCodeOveride || String(error.statusCodes[0] || 'unknown');
		cause = String(error.getCause()?.message || '');
	}
	sendOperationalEventV2(analyticKey, statusCode, traceIds, isRetried, {
		cause,
		affectsReliability,
		skipSentry,
	});
	return { traceIds, statusCode };
};

export const sendOperationalEventV2OnSuccess = (
	analyticKey: string,
	traceId: string[] | [],
	isRetried = false,
) => {
	sendOperationalEventV2(analyticKey, '200', traceId, isRetried);
};
