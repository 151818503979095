import React, { useEffect, useCallback, useRef, type ReactNode } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import {
	createStore,
	createContainer,
	createActionsHook,
	createStateHook,
	createHook,
} from '@atlassian/react-sweet-state';
import { useHeaderItemIndexes } from '../../header';
import { useItemIndexes } from '../../items';
import { actions } from './actions';
import { CellMap } from './cell-map';
import {
	isCreateTriggerActiveForItem,
	isNavigationEnabled,
	isNavigationPrevented,
} from './selectors';
import type { State, ContainerProps, GetData } from './types';

const initialState: State = {
	gridCells: new CellMap(),
	focusedCell: undefined,
	activeCreateTrigger: undefined,
	rovingCell: undefined,
	isNavigationEnabled: true,
	isNavigationPrevented: false,
};

const FocusContainer = createContainer<ContainerProps>();

const focusStore = createStore<State, typeof actions, ContainerProps>({
	containedBy: FocusContainer,
	initialState,
	actions,
	name: 'timeline-table.focus',
	handlers: {
		onContainerUpdate:
			(nextProps, prevProps) =>
			({ getState }) => {
				if (fg('timeline_grid_navigation_m2')) {
					if (
						nextProps.headerIndexes !== prevProps.headerIndexes ||
						nextProps.itemIndexes !== prevProps.itemIndexes
					) {
						const cellMap = getState().gridCells;
						cellMap.setIsDirty(true);
					}
				}
			},
	},
});

const useFocusMarshal = createActionsHook(focusStore);

const useFocusState = createStateHook(focusStore);

const useIsNavigationEnabled = createStateHook(focusStore, {
	selector: isNavigationEnabled,
});

const useIsNavigationPrevented = createStateHook(focusStore, { selector: isNavigationPrevented });

const useIsCreateTriggerActive = createHook(focusStore, {
	selector: isCreateTriggerActiveForItem,
});

const FocusMarshalProvider = ({ children }: { children: ReactNode }) => {
	const [headerIndexesUnstable] = useHeaderItemIndexes();
	const [itemIndexesUnstable] = useItemIndexes();

	/* This follows the "useEventCallback" pattern. We want the callback reference to remain stable,
	 * but the internal values to also remain up-to-date. In doing so we can lazily evaluate coordinates
	 * using the action thunk and not have to hold a copy of the indexes elsewhere that is kept in sync.
	 */
	const headerIndexes = useRef(headerIndexesUnstable);
	const itemIndexes = useRef(itemIndexesUnstable);

	useEffect(() => {
		headerIndexes.current = headerIndexesUnstable;
		itemIndexes.current = itemIndexesUnstable;
	});

	const getCoordinates = useCallback((getRowData: GetData): [number, number] => {
		const { column, rowId } = getRowData();

		const itemIndexOffset = Object.keys(headerIndexes.current).length;
		const row = headerIndexes.current[rowId] ?? itemIndexes.current[rowId] + itemIndexOffset;

		return [column, row];
	}, []);

	return (
		<FocusContainer
			getCoordinates={getCoordinates}
			{...(fg('timeline_grid_navigation_m2')
				? { headerIndexes: headerIndexes.current, itemIndexes: itemIndexes.current }
				: {})}
		>
			{children}
		</FocusContainer>
	);
};

export {
	useFocusState,
	useFocusMarshal,
	useIsNavigationEnabled,
	useIsNavigationPrevented,
	useIsCreateTriggerActive,
	FocusMarshalProvider,
};
