import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/map';
import type { Observable } from 'rxjs/Observable';
import type { Ari } from '@atlassian/jira-platform-ari';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import getSubtasksConcreteQuery, {
	type getSubtasksQuery,
} from '@atlassian/jira-relay/src/__generated__/getSubtasksQuery.graphql';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { generateGetSubtasksQueryVariables } from '@atlassian/jira-software-roadmap-data-queries/src/queries/get-subtasks/index.tsx';
import { GET_SUBTASKS } from '@atlassian/jira-software-roadmap-utils/src/utils/metrics/common/constants.tsx';
import { runQueryWithMetadata } from '../common/agg/run-query.tsx';
import { queryMetrics } from '../performance-analytics';
import { transformSubtasksAndStatusCategoriesResponse } from './transformers';
import type { SubtasksAndStatusCategories, GraphQLSubtasksAndStatusCategories } from './types';

const analyticKey = 'jira.frontend.fe.software.roadmap.get-subtasks';
const concurrentMetricDefinition = queryMetrics[GET_SUBTASKS];

const DEFAULT_RESPONSE: GraphQLSubtasksAndStatusCategories = {
	statusCategories: [],
	subtasks: [],
};

export const getSubtasksForIssues = (
	sourceAri: Ari,
	issueIds: IssueId[],
): Observable<SubtasksAndStatusCategories> =>
	runQueryWithMetadata<getSubtasksQuery>(
		getSubtasksConcreteQuery,
		generateGetSubtasksQueryVariables(sourceAri, issueIds),
		analyticKey,
		concurrentMetricDefinition,
	).map(({ data }) => {
		getAnalyticsWebClientPromise().then((client) => {
			const analyticsClient = client.getInstance();

			if (analyticsClient) {
				analyticsClient.sendOperationalEvent({
					action: 'fetched',
					actionSubject: 'roadmapSubtasks',
					attributes: {
						total: data?.roadmaps?.roadmapSubtasksByIds?.subtasks.length ?? 0,
					},
					source: 'relayOperation',
				});
			}
		});

		const response: GraphQLSubtasksAndStatusCategories =
			data?.roadmaps?.roadmapSubtasksByIds ?? DEFAULT_RESPONSE;

		return transformSubtasksAndStatusCategoriesResponse(response);
	});
