import { fg } from '@atlassian/jira-feature-gating';
import {
	createStore,
	createContainer,
	createHook,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import { MINIMUM_LIST_WIDTH } from '../../constants';
import { actions, type Actions } from './actions';
import type { Props, State } from './common/types';
import { getListWidth, getListItemMenuOptions } from './selectors';

const initialState: State = {
	listWidth: 0, // <ListProvider /> has listWidth as required, change to DEFAULT_LIST_WIDTH when cleaning up project_timeline_multi-select_and_checkboxes
	listItemMenuOptions: {
		overrideLabels: {},
		customSections: [],
		customSectionsBulk: [],
	},
};

export const listStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'timeline-table.list',
});

export const useListActions = createActionsHook(listStore);

export const useListWidth = createHook(listStore, {
	selector: getListWidth,
});
export const useListItemMenuOptions = createHook(listStore, {
	selector: getListItemMenuOptions,
});

export const useListState = createHook(listStore);

export const ListProvider = createContainer<State, Actions, Props>(listStore, {
	onInit:
		() =>
		({ setState }, listState: State) => {
			setState({
				...listState,
				...(fg('project_timeline_multi-select_and_checkboxes') && {
					listWidth: Math.max(MINIMUM_LIST_WIDTH(), listState.listWidth),
				}),
			});
		},
	onUpdate:
		() =>
		({ setState }, listState: State) => {
			setState({
				...listState,
				...(fg('project_timeline_multi-select_and_checkboxes') && {
					listWidth: Math.max(MINIMUM_LIST_WIDTH(), listState.listWidth),
				}),
			});
		},
});

export default ListProvider;
