import { Component } from 'react';
import type { Location } from '@atlassian/react-resource-router';

export type Props = {
	location: Location;
	locationFromStore: Location;
	onLocationChanged: (location: Location) => void;
};

// eslint-disable-next-line jira/react/no-class-components
export default class RouterUpdater extends Component<Props> {
	componentDidUpdate(prevProps: Props) {
		const { location: spaRouterlocation, onLocationChanged, locationFromStore } = this.props;
		const { location: prevSpaRouterlocation } = prevProps;

		if (
			prevSpaRouterlocation.search !== spaRouterlocation.search &&
			locationFromStore.search !== spaRouterlocation.search
		) {
			// Update `router` if spa-router's location is not the same as router location from redux store
			onLocationChanged(spaRouterlocation);
		}
	}

	render() {
		return null;
	}
}
