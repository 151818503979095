import React, { type PropsWithChildren, useCallback, useState } from 'react';
import { IconButton } from '@atlaskit/button/new';
import InformationIcon from '@atlaskit/icon/core/information';
import { Popup } from '@atlaskit/popup';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { useIntl } from '@atlassian/jira-intl';
import {
	useAnalyticsEvents,
	fireTrackAnalytics,
	fireUIAnalytics,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import LegendLegacy from '../legend-legacy';
import messages from './messages';
import ShrinkToFit from './shrink-to-fit';

export type Props = {
	/**
	 * Whether the legend should be open by default
	 */
	defaultIsOpen?: boolean;
};

const Legend = ({ children, defaultIsOpen = false }: PropsWithChildren<Props>) => {
	const [isOpen, setIsOpen] = useState<boolean>(defaultIsOpen);
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClose = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});

		fireTrackAnalytics(analyticsEvent, 'legend closed');

		setIsOpen(false);
	}, [createAnalyticsEvent, setIsOpen]);

	const onToggle = useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'legendButton');
			fireTrackAnalytics(analyticsEvent, `legend ${isOpen ? 'closed' : 'opened'}`);

			setIsOpen((value) => !value);
		},
		[isOpen],
	);
	const renderContent = useCallback(
		() => (
			<ShrinkToFit>
				<Box xcss={legendContentStyles} paddingInline="space.200" paddingBlock="space.250">
					<Stack space="space.250">{children}</Stack>
				</Box>
			</ShrinkToFit>
		),
		[children],
	);

	return (
		<Popup
			isOpen={isOpen}
			placement="top-end"
			onClose={onClose}
			trigger={(triggerProps) => (
				<IconButton
					{...triggerProps}
					isSelected={isOpen}
					onClick={onToggle}
					icon={InformationIcon}
					isTooltipDisabled={false}
					label={formatMessage(messages.legend)}
					tooltip={{
						position: 'top',
					}}
					appearance="subtle"
				/>
			)}
			content={renderContent}
			label={formatMessage(messages.legend)}
			id="timeline-legend"
			role="dialog"
			shouldUseCaptureOnOutsideClick
		/>
	);
};

const legendContentStyles = xcss({
	boxSizing: 'content-box',
	minWidth: `calc(${token('space.100')} * 35)`,
});

export default componentWithFG('update_icon_on_timeline_legend', Legend, LegendLegacy);
