import { createSelector, createStructuredSelector } from 'reselect';
import mapValues from 'lodash/mapValues';
import memoize from 'lodash/memoize';
// eslint-disable-next-line jira/restricted/moment
import moment from 'moment';
import type { Color } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import type {
	IssueId,
	IssueKey,
	IssueTypeId,
	IssueStatusCategoryId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import {
	TEXT,
	ASSIGNEE,
	LABEL,
	ISSUE_PARENT,
	ISSUE_TYPE,
	VERSION,
	COMPONENT,
	STATUS_CATEGORY,
	QUICK_FILTER,
	CUSTOM_FILTER,
	GOAL,
} from '@atlassian/jira-software-filters/src/common/constants.tsx';
import { createFilterFunction } from '@atlassian/jira-software-filters/src/utils/index.tsx';
import type {
	FilterableIssue as SharedFilterableIssue,
	Matcher,
} from '@atlassian/jira-software-filters/src/utils/types.tsx';
import type {
	Hash,
	IdentifiableHash,
} from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import type {
	LevelOneSetting,
	Filter,
} from '@atlassian/jira-software-roadmap-model/src/filter/index.tsx';
import type {
	IssueType,
	IssueTypeHash,
} from '@atlassian/jira-software-roadmap-model/src/issue-type/index.tsx';
import type { Sprint } from '@atlassian/jira-software-roadmap-model/src/sprint/index.tsx';
import type { StatusCategoryHash } from '@atlassian/jira-software-roadmap-model/src/status/index.tsx';
import type { Progress } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/progress.tsx';
import { DONE } from '../../../model/status';
import {
	getToday,
	getFullIssueTypeHash,
	getActiveAndFutureSprints,
	getEpicIssueTypeIds,
	getIssueType,
	getFullVersionHash,
} from '../../configuration/selectors';
import {
	getIssueAssigneeHash,
	getIssueDependeesHash,
	getIssueDependenciesHash,
	getIssueIds,
	getIssueKeyToIdHash,
	getIssueParentIdHash,
	getIssueChildrenHash,
	getIssueStatusCategoryIdHash,
	getIssueTypeIdHash,
	getIssueCreationTransitionHash,
	getIssueSummaryHash,
	getIssueLabelsHash,
	getIssueKeyHash,
	getIssueColorHash,
	getIssueDueDateHash,
} from '../../entities/issues/selectors';
import {
	getDoneStatusCategoryId,
	getStatusCategoriesKeyHash,
	getFullStatusCategoriesHash,
} from '../../entities/status-categories/selectors';
import { getUserDisplayNameHash } from '../../entities/users/selectors';
import { getSelectedIssueKey, isGoalFilterActive } from '../../router/selectors';
import type { State } from '../../types';
import { getJQLFilteredIssueIds, getIsJQLFiltersActive } from '../../ui/filters/selectors';
import { getSanitisedIssueComponentIdsHash } from '../components';
import { getIssuesFilter, getLevelOneSetting, getFilteredGoalIssueIds } from '../filters';
import { getSanitisedIssueSprintsHash } from '../sprint';
import { getParentRolledUpDatesHash } from '../table/dates';
import { getSanitisedIssueVersionIdsHash } from '../versions';
import {
	getProgressHashPure,
	getIssueStatusCategoryHashPure,
	getIssueEdgeReleaseDatesPure,
	combineIssueIdsFilters,
} from './pure';

export const getSelectedIssueId = createSelector(
	getIssueKeyToIdHash,
	getSelectedIssueKey,
	(hash: IdentifiableHash<IssueKey, IssueId>, key?: IssueKey): IssueId | undefined => {
		if (key === undefined) {
			return undefined;
		}

		return hash[key];
	},
);

export const getIssueTypeForIssueId = (state: State, id: IssueId): IssueType =>
	getIssueType(state, getIssueTypeIdHash(state)[id]);

export const getIssueStatusCategoryHash = createSelector(
	getIssueStatusCategoryIdHash,
	getFullStatusCategoriesHash,
	getIssueStatusCategoryHashPure,
);

// ================= //
// === FILTERING === //
// ================= //

export type FilterableIssue = SharedFilterableIssue & {
	children: FilterableIssue[];
	dueDate: number | undefined;
};

type FilterFunction = (issue: FilterableIssue) => boolean;

type FilterPropertiesChecker<Property extends string> = (
	issue: FilterableIssue,
	filterableProperties: Set<Property>,
) => boolean;

/* Create a filter that can check against one or more values of an issue property at once.
 * E.g. You could filter for multiple assignees or statuses at once.
 */
const createFilterFromProperties = <FilterableProperty extends string>(
	filterableProperties: FilterableProperty[],
	checkProperties: FilterPropertiesChecker<FilterableProperty>,
): FilterFunction => {
	const filterablePropertiesSet = new Set(filterableProperties);
	return (issue) => checkProperties(issue, filterablePropertiesSet);
};

const statusCategoryChecker: FilterPropertiesChecker<IssueStatusCategoryId> = (
	issue,
	statusCategories,
) => issue.statusCategoryId !== undefined && statusCategories.has(issue.statusCategoryId);

export const getSettingFilterFunction = createSelector(
	getEpicIssueTypeIds,
	getFullStatusCategoriesHash,
	getLevelOneSetting,
	getToday,
	getDoneStatusCategoryId,
	(
		epicIssueTypeIds: IssueTypeId[],
		statusCategoriesHash: StatusCategoryHash,
		levelOneSetting: LevelOneSetting,
		today: number,
		doneStatusCategoryId: IssueStatusCategoryId | undefined,
	) => {
		const allStatusCategoryId = Object.keys(statusCategoriesHash);

		if (!levelOneSetting.showCompleted) {
			const incompleteStatusCategoryId = allStatusCategoryId.filter(
				(id) => statusCategoriesHash[id].key !== DONE,
			);

			const filterFunction = createFilterFromProperties<IssueStatusCategoryId>(
				incompleteStatusCategoryId,
				statusCategoryChecker,
			);

			return (issue: FilterableIssue): boolean =>
				epicIssueTypeIds.includes(issue.type.id) ? filterFunction(issue) : true;
		}

		const filterFunction = (issue: FilterableIssue): boolean => {
			if (doneStatusCategoryId === issue.statusCategoryId && issue.dueDate !== undefined) {
				const { dueDate } = issue;
				const { showCompleted, period } = levelOneSetting;

				const durationStart = moment
					.utc(today)
					.subtract(showCompleted ? period : 0, 'months')
					.startOf('day')
					.valueOf();
				return dueDate >= durationStart;
			}
			return true;
		};

		return (issue: FilterableIssue): boolean =>
			epicIssueTypeIds.includes(issue.type.id) ? filterFunction(issue) : true;
	},
);

const makeMatcherHierarchical = (matcher: Matcher<FilterableIssue>): Matcher<FilterableIssue> => {
	const recursiveMatcher: Matcher<FilterableIssue> = memoize(
		(issue: FilterableIssue) => matcher(issue) || issue.children.some(recursiveMatcher),
	);

	return (issue) => recursiveMatcher(issue);
};

const getFilterFunctionWithIssueIdsFilters = createSelector(
	getIssuesFilter,
	getJQLFilteredIssueIds,
	getFilteredGoalIssueIds,
	(
		{
			text,
			statuses,
			assignees,
			issueTypes,
			issueParents,
			labels,
			versions,
			components,
			quickFilters,
			customFilters,
			goals,
		}: Filter,
		jqlFilteredIssueIds,
		goalFilteredIssueIds,
	) =>
		createFilterFunction<FilterableIssue>(
			{
				[TEXT]: text,
				[ASSIGNEE]: assignees,
				[LABEL]: labels,
				[ISSUE_PARENT]: issueParents,
				[ISSUE_TYPE]: issueTypes,
				[VERSION]: versions,
				[STATUS_CATEGORY]: statuses,
				[COMPONENT]: components,
				[QUICK_FILTER]: quickFilters,
				[CUSTOM_FILTER]: customFilters,
				[GOAL]: goals,
			},
			{
				filteredIssueIds: new Set(
					combineIssueIdsFilters(jqlFilteredIssueIds, goalFilteredIssueIds),
				),
			},
		),
);

const getFilterFunctionWithoutJQLFilters = createSelector(
	getIssuesFilter,
	({ text, statuses, assignees, issueTypes, issueParents, labels, versions, components }: Filter) =>
		createFilterFunction<FilterableIssue>(
			{
				[TEXT]: text,
				[ASSIGNEE]: assignees,
				[LABEL]: labels,
				[ISSUE_PARENT]: issueParents,
				[ISSUE_TYPE]: issueTypes,
				[VERSION]: versions,
				[STATUS_CATEGORY]: statuses,
				[COMPONENT]: components,
			},
			{},
		),
);

export const getFilterFunction = (state: State, skipQuickFilters = false) => {
	if ((getIsJQLFiltersActive(state) && !skipQuickFilters) || isGoalFilterActive(state)) {
		return getFilterFunctionWithIssueIdsFilters(state);
	}
	return getFilterFunctionWithoutJQLFilters(state);
};

type IssuePropertyHashes = {
	issueStatusCategoryIdHash: ReturnType<typeof getIssueStatusCategoryIdHash>;
	issueAssigneeHash: ReturnType<typeof getIssueAssigneeHash>;
	issueTypeIdHash: ReturnType<typeof getIssueTypeIdHash>;
	issueChildrenHash: ReturnType<typeof getIssueChildrenHash>;
	issueCreationTransitionHash: ReturnType<typeof getIssueCreationTransitionHash>;
	issueSummaryHash: ReturnType<typeof getIssueSummaryHash>;
	issueParentIdHash: ReturnType<typeof getIssueParentIdHash>;
	issueLabelsHash: ReturnType<typeof getIssueLabelsHash>;
	issueKeyHash: ReturnType<typeof getIssueKeyHash>;
	issueVersionsHash: ReturnType<typeof getSanitisedIssueVersionIdsHash>;
	issueComponentsHash: ReturnType<typeof getSanitisedIssueComponentIdsHash>;
	issueDueDateHash: ReturnType<typeof getIssueDueDateHash>;
};

const getIssuePropertyHashes = createStructuredSelector<State, IssuePropertyHashes>({
	issueStatusCategoryIdHash: getIssueStatusCategoryIdHash,
	issueAssigneeHash: getIssueAssigneeHash,
	issueTypeIdHash: getIssueTypeIdHash,
	issueChildrenHash: getIssueChildrenHash,
	issueCreationTransitionHash: getIssueCreationTransitionHash,
	issueSummaryHash: getIssueSummaryHash,
	issueParentIdHash: getIssueParentIdHash,
	issueLabelsHash: getIssueLabelsHash,
	issueKeyHash: getIssueKeyHash,
	issueVersionsHash: getSanitisedIssueVersionIdsHash,
	issueComponentsHash: getSanitisedIssueComponentIdsHash,
	issueDueDateHash: getIssueDueDateHash,
});

export const getFilteredIssueIds = createSelector(
	getFilterFunction,
	getSettingFilterFunction,
	getIssueIds,
	getIssuePropertyHashes,
	getUserDisplayNameHash,
	getParentRolledUpDatesHash,
	(
		filterFunction,
		viewSettingFilterFunction,
		issueIds,
		{
			issueStatusCategoryIdHash,
			issueAssigneeHash,
			issueTypeIdHash,
			issueChildrenHash,
			issueCreationTransitionHash,
			issueSummaryHash,
			issueParentIdHash,
			issueLabelsHash,
			issueKeyHash,
			issueVersionsHash,
			issueComponentsHash,
			issueDueDateHash,
		},
		userDisplayNameHash,
		parentRolledUpDatesHash,
	) => {
		if (filterFunction === undefined && viewSettingFilterFunction === undefined) {
			return issueIds;
		}

		const createFilterableIssue = memoize((issueId: IssueId): FilterableIssue => {
			const children: IssueId[] = issueChildrenHash[issueId] || [];

			const parentRolledUpDate = parentRolledUpDatesHash[issueId];
			const rolledUpDueDate =
				parentRolledUpDate === undefined || parentRolledUpDate.isDueDatePlaceholder
					? undefined
					: parentRolledUpDate.dueDate;
			const dueDate =
				issueDueDateHash[issueId] !== undefined ? issueDueDateHash[issueId] : rolledUpDueDate;

			const assigneeId = issueAssigneeHash[issueId];

			const assignee =
				assigneeId !== undefined
					? {
							id: assigneeId,
							displayName: userDisplayNameHash[assigneeId],
						}
					: undefined;

			return {
				id: issueId,
				key: issueKeyHash[issueId],
				summary: issueSummaryHash[issueId],
				labels: issueLabelsHash[issueId],
				parentId: issueParentIdHash[issueId],
				type: { id: issueTypeIdHash[issueId] },
				versionIds: issueVersionsHash[issueId],
				componentIds: issueComponentsHash[issueId],
				statusCategoryId: issueStatusCategoryIdHash[issueId],
				children: children.map(createFilterableIssue),
				assignee,
				dueDate,
			};
		});

		const recursiveFilterFunction =
			filterFunction !== undefined ? makeMatcherHierarchical(filterFunction) : undefined;

		return issueIds.filter((issueId) => {
			const issue = createFilterableIssue(issueId);

			// An issues visibility will be resolved on its "real" properties AFTER creation.
			if (issueCreationTransitionHash[issueId] === true) {
				return true;
			}

			if (recursiveFilterFunction !== undefined && viewSettingFilterFunction !== undefined)
				return recursiveFilterFunction(issue) && viewSettingFilterFunction(issue);

			if (recursiveFilterFunction !== undefined) return recursiveFilterFunction(issue);

			if (viewSettingFilterFunction !== undefined) return viewSettingFilterFunction(issue);

			return true;
		});
	},
);

export const getFilteredIssueIdsHash = createSelector(getFilteredIssueIds, (filteredIssueIds) => {
	const issueIdsHash: Hash<boolean> = {};

	filteredIssueIds.forEach((issueId) => {
		issueIdsHash[issueId] = true;
	});

	return issueIdsHash;
});

export const getFilteredIssueCount = (state: State) =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	getFilteredIssueIds(state).length as unknown as number;

export const isIssueVisible = (state: State, issueId: IssueId): boolean =>
	getFilteredIssueIdsHash(state)[issueId] || false;

const filterNonBaseLevelIssueIds = (
	issueIds: IssueId[],
	issueTypeIdHash: Hash<IssueTypeId>,
	epicIssueTypeIds: IssueTypeId[],
) => issueIds.filter((issueId: IssueId) => epicIssueTypeIds.includes(issueTypeIdHash[issueId]));

export const getNonBaseLevelIssueIds = createSelector(
	getIssueIds,
	getIssueTypeIdHash,
	getEpicIssueTypeIds,
	filterNonBaseLevelIssueIds,
);

export const getFilteredNonBaseLevelIssueIds = createSelector(
	getFilteredIssueIds,
	getIssueTypeIdHash,
	getEpicIssueTypeIds,
	filterNonBaseLevelIssueIds,
);

// Get a hash of issue id to child issue ids, with the children in order.
export const getFilteredChildIssueIdsHash = createSelector(
	getFilteredIssueIds,
	getIssueParentIdHash,
	(issueIds: IssueId[], parentIdHash: Hash<IssueId | undefined>): Hash<IssueId[]> => {
		const issueChildIdHash: Hash<IssueId[]> = {};

		issueIds.forEach((issueId: IssueId) => {
			const parentId = parentIdHash[issueId];

			// Can only be a child if it has a parent...
			if (parentId) {
				if (!issueChildIdHash[parentId]) {
					issueChildIdHash[parentId] = [issueId];
				} else {
					issueChildIdHash[parentId].push(issueId);
				}
			}
		});

		return issueChildIdHash;
	},
);

export const getFilteredBaseLevelIssueIds = createSelector(
	getNonBaseLevelIssueIds,
	getFilteredChildIssueIdsHash,
	(issueParentIds: IssueId[], issueChildIdHash: Hash<IssueId[]>) => {
		const filteredBaseLevelIssueIds: Array<IssueId> = [];

		issueParentIds.forEach((id: IssueId) => {
			const ids = issueChildIdHash[id] || [];

			ids.length && filteredBaseLevelIssueIds.push(...ids);
		});

		return filteredBaseLevelIssueIds;
	},
);

// Filter for issues that exist on the Roadmap by checking against the issue types that belong to this project
export const getFilteredIssueDependenciesHash = createSelector(
	getIssueDependenciesHash,
	getIssueTypeIdHash,
	getEpicIssueTypeIds,
	getFullIssueTypeHash,
	(
		issueDependenciesHash: IdentifiableHash<IssueId, IssueId[]>,
		issueTypeIdHash: Hash<IssueTypeId>,
		epicIssueTypeIds: IssueTypeId[],
		issueTypeHash: IssueTypeHash,
	) =>
		mapValues(issueDependenciesHash, (dependencies: IssueId[]) =>
			dependencies.filter((issueId: IssueId) => issueTypeHash[issueTypeIdHash[issueId]]),
		),
);

// Filter for issues that exist on the Roadmap by checking against the issue types that belong to this project
export const getFilteredIssueDependeesHash = createSelector(
	getIssueDependeesHash,
	getIssueTypeIdHash,
	getEpicIssueTypeIds,
	getFullIssueTypeHash,
	(
		issueDependeesHash: IdentifiableHash<IssueId, IssueId[]>,
		issueTypeIdHash: Hash<IssueTypeId>,
		epicIssueTypeIds: IssueTypeId[],
		issueTypeHash: IssueTypeHash,
	) =>
		mapValues(issueDependeesHash, (dependencies: IssueId[]) =>
			dependencies.filter((issueId: IssueId) => issueTypeHash[issueTypeIdHash[issueId]]),
		),
);

export const getIssueDependencies = (state: State, issueId: IssueId): IssueId[] | undefined =>
	getFilteredIssueDependenciesHash(state)[issueId];

export const getIssueDependees = (state: State, issueId: IssueId): IssueId[] | undefined =>
	getFilteredIssueDependeesHash(state)[issueId];

export const getProgressHash = createSelector(
	getNonBaseLevelIssueIds,
	getIssueChildrenHash,
	getIssueStatusCategoryHash,
	getStatusCategoriesKeyHash,
	getProgressHashPure,
);

export const getProgress = (state: State, issueId: IssueId): Progress | undefined =>
	getProgressHash(state)[issueId];

// ======================= //
// === ISSUE & SPRINTS === //
// ======================= //

/**
 * Returns active/future sprints that have not been applied to the issue
 */
export const getIssueContextMenuSprintsHash = createSelector(
	getActiveAndFutureSprints,
	getSanitisedIssueSprintsHash,
	(activeOrFutureSprints: Sprint[], issueSprintsHash: IdentifiableHash<IssueId, Sprint[]>) => {
		const result: Record<IssueId, Sprint[]> = {};

		Object.keys(issueSprintsHash).forEach((issueId: IssueId) => {
			const sprintIds = issueSprintsHash[issueId].map((sprint: Sprint) => sprint.id);
			result[issueId] = activeOrFutureSprints.filter(
				(sprint: Sprint) => !sprintIds.includes(sprint.id),
			);
		});

		return result;
	},
);

export const getIssueContextMenuSprints = (state: State, issueId: IssueId): Sprint[] =>
	getIssueContextMenuSprintsHash(state)[issueId];

// Returns the parent's color for base level issues
const getIssueParentColorHash = createSelector(
	getIssueIds,
	getIssueParentIdHash,
	getIssueColorHash,
	(ids, parentIdHash, issueColorHash) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const issueParentColorHash: Record<string, any> = {};

		ids.forEach((id) => {
			const parentId = parentIdHash[id];
			const color = parentId !== undefined ? issueColorHash[parentId] : issueColorHash[id];
			issueParentColorHash[`${id}`] = color;
		});
		return issueParentColorHash;
	},
);

export const getIssueParentColor = (state: State, issueId: IssueId): Color =>
	getIssueParentColorHash(state)[issueId];

export const getIssueEdgeReleaseDatesHash = createSelector(
	getFullVersionHash,
	getSanitisedIssueVersionIdsHash,
	getIssueEdgeReleaseDatesPure,
);
