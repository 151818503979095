import React, { type ReactElement } from 'react';
import { DEFAULT_RENDERER } from '../../common/constants';
import { CONTEXT_MENU_TYPE, DEPENDENCIES_FLYOUT_TYPE } from '../../common/constants/overlay.tsx';
import type { Position } from '../../common/types/common';
import type { OverlayTypes } from '../../common/types/overlay.tsx';

export type ChartTimelineOverlayRendererProps = {
	id: string;
	position: Position;
};
type ChartTimelineOverlayRenderer = (
	rendererProps: ChartTimelineOverlayRendererProps,
) => ReactElement | null;
type SimpleRenderer = () => ReactElement | null;

type Props = {
	isDependenciesVisible: boolean;
	id: string | undefined;
	position: Position | undefined;
	type: OverlayTypes;
	renderDependencyLines: SimpleRenderer;
	renderHighlightLines: SimpleRenderer;
	renderDependencyFlyout: ChartTimelineOverlayRenderer;
	renderContextMenu: ChartTimelineOverlayRenderer;
};

const RoadmapChartTimelineOverlay = ({
	id,
	position,
	type,
	isDependenciesVisible,
	renderDependencyFlyout,
	renderDependencyLines,
	renderHighlightLines,
	renderContextMenu,
}: Props) => {
	const renderFlyout = () => {
		if (id !== undefined && type !== undefined && position !== undefined) {
			switch (type) {
				case DEPENDENCIES_FLYOUT_TYPE:
					return renderDependencyFlyout({ id, position });

				case CONTEXT_MENU_TYPE:
					return renderContextMenu({ id, position });

				default:
					return null;
			}
		}

		return null;
	};

	return (
		<>
			{renderFlyout()}
			{isDependenciesVisible && renderDependencyLines()}
			{renderHighlightLines()}
		</>
	);
};

RoadmapChartTimelineOverlay.defaultProps = {
	renderDependencyFlyout: DEFAULT_RENDERER,
	renderDependencyLines: DEFAULT_RENDERER,
	renderHighlightLines: DEFAULT_RENDERER,
	renderContextMenu: DEFAULT_RENDERER,
};

export { RoadmapChartTimelineOverlay };
