import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/concat';
import { batchActions, type BatchAction } from 'redux-batched-actions';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { isBaseLevel } from '@atlassian/jira-software-roadmap-model/src/hierarchy/index.tsx';
import { ISSUE_DETAILS } from '../../model/panel';
import {
	getIssueKeyById,
	getIssueParentIdHash,
	getIssueLevel,
} from '../../state/entities/issues/selectors';
import {
	interactedItemsLimitExceeded,
	type InteractedItemsLimitExceededAction,
} from '../../state/flags/actions';
import {
	getFilteredBaseLevelIssueIds,
	getFilteredNonBaseLevelIssueIds,
} from '../../state/selectors/issues';
import { getIsInteractedItemsOverLimit } from '../../state/selectors/select';
import type { State } from '../../state/types';
import {
	setSelectedIssue,
	hidePanel,
	type SetSelectedIssueAction,
} from '../../state/ui/panel/actions';
import { getPanelType } from '../../state/ui/panel/selectors';
import {
	ACTIVATE_ITEM,
	SELECT_MULTIPLE_ITEMS,
	SELECT_ITEMS,
	setSelectedItemIds,
	setSelectionAnchors,
	type ActivateItem,
	type SelectMultipleItems,
	type SelectItemsAction,
	type SetSelectedItemIdsAction,
	type SetSelectionAnchorsAction,
} from '../../state/ui/table/actions';
import { getSelectedItemIds, getSelectionAnchors } from '../../state/ui/table/selectors';
import type { StateEpic } from '../common/types';
import { fireInteractedItemsLimitExceededAnalytics } from './common/analytics';

const getIsInteractedItemsLimitExceeded = (
	state: State,
	isSelectedItemIdsBaseLevel: boolean,
	toBeSelectedItemIds: IssueId[],
	analyticsEvent: UIAnalyticsEvent,
) => {
	const isInteractedItemsOverLimit = getIsInteractedItemsOverLimit(
		state,
		isSelectedItemIdsBaseLevel,
		toBeSelectedItemIds,
	);

	isInteractedItemsOverLimit && fireInteractedItemsLimitExceededAnalytics(analyticsEvent);

	return isInteractedItemsOverLimit;
};

const getMultiSelectItems = (
	state: State,
	id: IssueId,
	anchorId: IssueId,
	isSelectedItemIdsBaseLevel: boolean,
): IssueId[] => {
	if (!anchorId) {
		return [];
	}

	const orderedFilteredItems = isSelectedItemIdsBaseLevel
		? getFilteredBaseLevelIssueIds(state)
		: getFilteredNonBaseLevelIssueIds(state);
	const clickedItemIndex = orderedFilteredItems.indexOf(id);
	const anchorIndex = orderedFilteredItems.indexOf(anchorId);
	const itemList = orderedFilteredItems.slice(
		Math.min(clickedItemIndex, anchorIndex),
		Math.max(clickedItemIndex, anchorIndex) + 1,
	);

	return clickedItemIndex < anchorIndex ? itemList.reverse() : itemList;
};

type Actions =
	| SetSelectedIssueAction
	| SetSelectionAnchorsAction
	| SetSelectedItemIdsAction
	| InteractedItemsLimitExceededAction
	| BatchAction
	| never;

export const multiselect = (
	action$: ActionsObservable<SelectMultipleItems>,
	store: MiddlewareAPI<State>,
) =>
	action$
		.ofType(SELECT_MULTIPLE_ITEMS)
		.mergeMap(
			({
				payload: { analyticsEvent, itemIds },
			}: SelectMultipleItems): Observable<
				SetSelectedItemIdsAction | InteractedItemsLimitExceededAction
			> => {
				const state = store.getState();

				const selectedItemLevel = itemIds.length
					? getIssueLevel(state, itemIds[0])
					: undefined; /** When deselecting all. */

				if (selectedItemLevel === undefined) {
					return Observable.of(setSelectedItemIds([]));
				}

				const isSelectedItemIdsBaseLevel = isBaseLevel(selectedItemLevel);

				const isInteractedItemsLimitExceeded = getIsInteractedItemsLimitExceeded(
					state,
					isSelectedItemIdsBaseLevel,
					itemIds,
					analyticsEvent,
				);

				if (isInteractedItemsLimitExceeded) {
					return Observable.of(interactedItemsLimitExceeded());
				}

				return Observable.of(setSelectedItemIds(itemIds));
			},
		);

export const select = (action$: ActionsObservable<ActivateItem>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(ACTIVATE_ITEM)
		.mergeMap(({ payload: { itemId } }: ActivateItem): Observable<SetSelectedIssueAction> => {
			const state = store.getState();
			const key = getIssueKeyById(state, itemId);
			return Observable.of(setSelectedIssue(key));
		});

// Remove with project_timeline_multi-select_and_checkboxes
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<SelectItemsAction>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(SELECT_ITEMS)
		.mergeMap(
			({
				payload: { id, level, withCmd, withShift },
				meta: { analyticsEvent },
			}: SelectItemsAction): Observable<Actions> => {
				const state = store.getState();
				const key = getIssueKeyById(state, id);

				if (!key) {
					return Observable.empty<never>();
				}

				const isIssueDetailsShown = getPanelType(state) === ISSUE_DETAILS;

				if (!withCmd && !withShift) {
					// Single-select resets selected items and anchors
					return Observable.concat(
						Observable.of(batchActions([setSelectionAnchors([id]), setSelectedItemIds([id])])),
						Observable.of(setSelectedIssue(key)),
					);
				}

				const parentIds = getIssueParentIdHash(state);
				const selectedItemIds = getSelectedItemIds(state);
				const selectedItemLevel = selectedItemIds.length
					? getIssueLevel(state, selectedItemIds[0])
					: level;
				const isSelectedItemIdsBaseLevel = isBaseLevel(selectedItemLevel);

				// Prevent selecting items from a different level or parent when multi-selecting
				if (selectedItemIds.length && parentIds[selectedItemIds[0]] !== parentIds[id]) {
					return Observable.empty<never>();
				}

				const selectionAnchors = getSelectionAnchors(state);

				if (withShift) {
					const actions = [];
					const [anchorId] = selectionAnchors.slice(-1);

					// Do nothing if nothing is selected via shift multi-select
					if (anchorId === id) {
						return Observable.empty<never>();
					}

					const multiSelectItems = getMultiSelectItems(
						state,
						id,
						anchorId,
						isSelectedItemIdsBaseLevel,
					);
					// Append and dedupe the multi-select items when selecting via cmd + shift multi-select
					const updatedMultiSelectItems = withCmd
						? selectedItemIds
								.filter((item: IssueId) => !multiSelectItems.includes(item))
								.concat(multiSelectItems)
						: multiSelectItems;

					const isInteractedItemsLimitExceeded = getIsInteractedItemsLimitExceeded(
						state,
						isSelectedItemIdsBaseLevel,
						updatedMultiSelectItems,
						analyticsEvent,
					);

					if (isInteractedItemsLimitExceeded) {
						return Observable.of(interactedItemsLimitExceeded());
					}

					// Only keep the most recent anchor to avoid conflicting with selected items when selecting via shift multi-select
					selectionAnchors.length > 1 && actions.push(setSelectionAnchors([anchorId]));
					// Treat multi-select as single-select when no items were selected previously
					selectedItemIds.length
						? actions.push(setSelectedItemIds(updatedMultiSelectItems))
						: actions.push(setSelectionAnchors([id]), setSelectedItemIds([id]));

					const batchedActionsObservable = Observable.of(
						actions.length > 1 ? batchActions(actions) : actions[0],
					);

					// Update the issue details (if it's already shown) when selecting via shift multi-select
					return isIssueDetailsShown
						? Observable.concat(batchedActionsObservable, Observable.of(setSelectedIssue(key)))
						: batchedActionsObservable;
				}

				const selectedItemIndex = selectedItemIds.indexOf(id);
				const updatedSelectedItemIds = selectedItemIds.slice();
				const selectionAnchorIndex = selectionAnchors.indexOf(id);
				const updatedSelectionAnchors = selectionAnchors.slice();

				if (withCmd) {
					const actions = [];
					let issueKeyShown: string | undefined;

					if (selectedItemIds.length === 1 && selectedItemIndex === 0) {
						// Hide the issue details when the only item is deselected via cmd multi-select
						updatedSelectionAnchors.pop();
						updatedSelectedItemIds.pop();
						isIssueDetailsShown && actions.push(hidePanel());
					} else {
						issueKeyShown = key;

						// Toggle the item and update anchors when (de)selecting via cmd multi-select
						if (selectedItemIndex === -1) {
							updatedSelectionAnchors.push(id);
							updatedSelectedItemIds.push(id);

							const isInteractedItemsLimitExceeded = getIsInteractedItemsLimitExceeded(
								state,
								isSelectedItemIdsBaseLevel,
								updatedSelectedItemIds,
								analyticsEvent,
							);

							if (isInteractedItemsLimitExceeded) {
								return Observable.of(interactedItemsLimitExceeded());
							}
						} else {
							const isAnchor = selectionAnchorIndex > -1;

							isAnchor && updatedSelectionAnchors.splice(selectionAnchorIndex, 1);
							updatedSelectedItemIds.splice(selectedItemIndex, 1);

							const [prevSelectedItem] = updatedSelectedItemIds.slice(-1);

							// Use a previously selected item since the last anchor was deselected
							!updatedSelectionAnchors.length && updatedSelectionAnchors.push(prevSelectedItem);

							// Update the issue details with a previously selected item when deselecting
							if (prevSelectedItem !== id) {
								issueKeyShown = getIssueKeyById(state, prevSelectedItem);
							}
						}

						if (isIssueDetailsShown && !issueKeyShown) {
							actions.push(hidePanel());
						}
					}

					// Ensure the selection anchors and selected items are updated
					actions.unshift(
						setSelectionAnchors(updatedSelectionAnchors),
						setSelectedItemIds(updatedSelectedItemIds),
					);

					const batchedActionsObservable = Observable.of(batchActions(actions));

					// Update the issue details (if it's already shown) when selecting via cmd multi-select
					return isIssueDetailsShown && issueKeyShown
						? Observable.concat(
								batchedActionsObservable,
								Observable.of(setSelectedIssue(issueKeyShown)),
							)
						: batchedActionsObservable;
				}

				return Observable.empty<never>();
			},
		)) as StateEpic;
