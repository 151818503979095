import React, { type ReactNode } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { ITEM_TYPE, CREATE_ITEM_TYPE } from '../../constants';
import { useCreateItemAnchorIndex } from '../../context/items/index.tsx';
import { useVirtualisedItems } from '../../context/virtualise/main.tsx';
import type { EnrichedItem, FlattenedEnrichedItem } from '../../types/hierarchy-enriched-item.tsx';
import type { FlatItem } from '../../types/item.tsx';
import type { RenderCreateItem, RenderItem, RenderItemContainer } from './common/types';

type Props = {
	renderItem: RenderItem;
	renderItemContainer: RenderItemContainer;
	renderCreateItem: RenderCreateItem;
};

type RenderProps = {
	items: FlattenedEnrichedItem[];
	getIsVirtual: (flatIndex: number) => boolean;
	parentItem: EnrichedItem<FlatItem> | undefined;
};

const ItemsRenderer = ({ renderItem, renderItemContainer, renderCreateItem }: Props) => {
	const [virtualisedData] = useVirtualisedItems();
	const createItemAnchorIndex = useCreateItemAnchorIndex();

	const { requiredItems = [], overscanRange } = virtualisedData;

	/* If the item is beyond the visible and overscan range, then it's not visible.
	 * Unless it's the anchor for a create item, we need the "full" version to refocus for a11y
	 */
	const getIsVirtual = (flatIndex: number) =>
		(flatIndex < overscanRange.start || flatIndex > overscanRange.end) &&
		flatIndex !== createItemAnchorIndex;

	// if the item is beyond the visible and overscan range, then it's not visible
	const getIsVirtualOld = (flatIndex: number) =>
		flatIndex < overscanRange.start || flatIndex > overscanRange.end;

	const renderedItems = renderList({
		items: requiredItems,
		getIsVirtual: fg('timeline_grid_navigation_m2') ? getIsVirtual : getIsVirtualOld,
		parentItem: undefined,
		renderItem,
		renderItemContainer,
		renderCreateItem,
	});
	return <>{renderedItems}</>;
};

const renderList = ({
	items,
	getIsVirtual,
	parentItem,
	renderItem,
	renderItemContainer,
	renderCreateItem,
}: Props & RenderProps) => {
	// Start iterate flat items
	const flatItemElements: ReactNode[] = [];

	const getItemsToRender = (
		parentFlatItem: EnrichedItem<FlatItem> | undefined,
		flatItems: FlattenedEnrichedItem[],
	) => {
		if (parentFlatItem === undefined) {
			return flatItems.filter(({ meta }) => !meta.parentId);
		}

		return flatItems.filter(({ meta }) => meta.parentId === parentFlatItem.item.id);
	};

	const itemsToRender = getItemsToRender(parentItem, items);

	// Generate items
	itemsToRender?.forEach((enrichedItem) => {
		if (enrichedItem.type === ITEM_TYPE) {
			const itemContainerChildren: ReactNode[] = [];

			const { item, meta, flatIndex } = enrichedItem;

			// If it is an invisible sibling or ancestor we render it as virtual,
			itemContainerChildren.push(renderItem(item, meta, flatIndex, getIsVirtual(flatIndex)));

			const {
				isExpanded,
				isParent,
				numChildren = 0,
				ariaRowIndex,
				topOffset,
				childItemsHeight,
			} = meta;

			const parentItemContainerMeta = {
				parentId: parentItem?.item.id,
				isExpanded,
				isParent,
				id: item.id,
				level: item.level,
				depth: item.depth,
				ariaRowIndex,
				topOffset,
				childItemsHeight,
				isInTransition: item.isInTransition,
			};

			flatItemElements.push(renderItemContainer(parentItemContainerMeta, 0, itemContainerChildren));

			if (isExpanded && numChildren > 0) {
				const renderedChildItems = renderList({
					items,
					getIsVirtual,
					parentItem: enrichedItem,
					renderItem,
					renderItemContainer,
					renderCreateItem,
				});

				flatItemElements.push(...renderedChildItems);
			}
		} else if (enrichedItem.type === CREATE_ITEM_TYPE) {
			// Render visible create item
			flatItemElements.push(
				renderCreateItem(enrichedItem.id, enrichedItem.anchorItem, enrichedItem.meta),
			);
		}
	});

	return flatItemElements;
};

export default ItemsRenderer;
