import { graphql } from 'react-relay';
import type { Observable } from 'rxjs/Observable';
import type { Ari } from '@atlassian/jira-platform-ari';
import type {
	resolveHealthcheckMutation,
	resolveHealthcheckMutation$data,
	resolveHealthcheckMutation$variables,
	RoadmapAddLevelOneIssueTypeHealthcheckResolution,
} from '@atlassian/jira-relay/src/__generated__/resolveHealthcheckMutation.graphql';
import { RESOLVE_HEALTHCHECK } from '@atlassian/jira-software-roadmap-utils/src/utils/metrics/common/constants.tsx';
import { runMutation } from '../common/agg/run-mutation.tsx';
import { mutationMetrics } from '../performance-analytics';

const mutationName = 'resolveHealthcheck';
const concurrentMetricDefinition = mutationMetrics[RESOLVE_HEALTHCHECK];
const analyticKey = 'jira.frontend.fe.software.roadmap.resolve-roadmap-healthcheck';

export const resolveHealthcheckFromServer = (
	sourceARI: Ari,
	actionId: string,
	addLevelOneIssueType?: RoadmapAddLevelOneIssueTypeHealthcheckResolution,
): Observable<resolveHealthcheckMutation$data> => {
	const variables: resolveHealthcheckMutation$variables = {
		sourceARI,
		input: { actionId, addLevelOneIssueType },
	};

	return runMutation<resolveHealthcheckMutation>(
		mutationName,
		graphql`
			mutation resolveHealthcheckMutation(
				$sourceARI: ID!
				$input: RoadmapResolveHealthcheckInput!
			) {
				roadmaps {
					resolveRoadmapHealthcheck(sourceARI: $sourceARI, input: $input) {
						success
						errors {
							message
							extensions {
								... on RoadmapMutationErrorExtension {
									statusCode
									errorType
								}
							}
						}
					}
				}
			}
		`,
		variables,
		analyticKey,
		concurrentMetricDefinition,
	);
};
