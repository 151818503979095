import React, { type ReactElement } from 'react';

type Props = {
	renderChartDragLayer?: () => ReactElement | null;
	renderOverlayButton?: () => ReactElement | null;
};

const RoadmapChartOverlay = ({
	renderChartDragLayer = () => null,
	renderOverlayButton = () => null,
}: Props) => (
	<>
		{renderOverlayButton()}
		{renderChartDragLayer()}
	</>
);

export { RoadmapChartOverlay };
