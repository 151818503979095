import { graphql } from 'react-relay';
import type { Observable } from 'rxjs/Observable';
import type { Ari } from '@atlassian/jira-platform-ari';
import type {
	removeRoadmapDependencyMutation$data,
	RoadmapToggleDependencyInput,
	removeRoadmapDependencyMutation,
} from '@atlassian/jira-relay/src/__generated__/removeRoadmapDependencyMutation.graphql';
import { DEPENDENCY_REMOVED } from '@atlassian/jira-software-roadmap-utils/src/utils/metrics/common/constants.tsx';
import { runMutation } from '../common/agg/run-mutation.tsx';
import { mutationMetrics } from '../performance-analytics';

const mutationName = 'removeRoadmapDependency';
const concurrentMetricDefinition = mutationMetrics[DEPENDENCY_REMOVED];
const analyticKey = 'jira.frontend.fe.software.roadmap.dependency-removed';

type InputParams = {
	sourceARI: Ari;
	input: RoadmapToggleDependencyInput;
};

export const removeDependencyOnServer = ({
	sourceARI,
	input,
}: InputParams): Observable<removeRoadmapDependencyMutation$data> =>
	runMutation<removeRoadmapDependencyMutation>(
		mutationName,
		graphql`
			mutation removeRoadmapDependencyMutation(
				$sourceARI: ID!
				$input: RoadmapToggleDependencyInput!
			) {
				roadmaps {
					removeRoadmapDependency(sourceARI: $sourceARI, input: $input) {
						success
						errors {
							message
							extensions {
								... on RoadmapMutationErrorExtension {
									statusCode
									errorType
								}
							}
						}
						output {
							dependee
							dependency
						}
					}
				}
			}
		`,
		{ sourceARI, input },
		analyticKey,
		concurrentMetricDefinition,
	);
