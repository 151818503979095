import type { ColorSchema, Color } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { transformColorToTheme } from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import type { DependencyTheme } from '../../types/dependency.tsx';

export const dependencyThemes: Record<Color, DependencyTheme> = transformColorToTheme(
	(cs: ColorSchema) => ({
		icon: cs.primary,
		line: cs.line,
		border: cs.border,
		handle: {
			shadow: cs.primary,
			background: cs.primary,
		},
	}),
);
