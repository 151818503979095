import type { MiddlewareAPI, Action as ActionType } from 'redux';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/zip';
import { batchActions } from 'redux-batched-actions';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { fg } from '@atlassian/jira-feature-gating';
import type { IssueId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IdentifiableHash } from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import type { Issue } from '@atlassian/jira-software-roadmap-model/src/issue/index.tsx';
import { filterItemsByJQLFilters } from '@atlassian/jira-software-roadmap-services/src/issues/filter.tsx';
import { getItems } from '@atlassian/jira-software-roadmap-services/src/issues/get.tsx';
import type { GlobalIssueCreateProps } from '../../model/issue';
import { getSourceARI } from '../../state/app/selectors';
import {
	CREATE_ISSUE,
	extendIssues,
	GLOBAL_ISSUE_CREATE,
	type GlobalIssueCreateAction as Action,
} from '../../state/entities/issues/actions';
import { addUsers } from '../../state/entities/users/actions';
import { irrelevantIssuesCreated, issueHidden } from '../../state/flags/actions';
import { getQuickFilters, getCustomFilters } from '../../state/router/selectors';
import type { State } from '../../state/types';
import { jqlFiltersSuccess } from '../../state/ui/filters/actions';
import type { StateEpic } from '../common/types';

// Irrelevant issues are those that were created but do match the roadmaps issue source
const getIrrelevantIssueKeys = (
	globalIssues: Array<GlobalIssueCreateProps>,
	roadmapIssues: IdentifiableHash<IssueId, Issue>,
) => {
	const irrelevantIssueKeys: Array<IssueKey> = [];
	globalIssues.forEach((globalIssue) => {
		if (roadmapIssues[globalIssue.issueId] === undefined) {
			irrelevantIssueKeys.push(globalIssue.issueKey);
		}
	});
	return irrelevantIssueKeys;
};

/* From the issues created in global create when on the roadmap:
 * 1. Check whether they are relevant to the roadmap (ie board filter, project)
 * 2. For all issues that are relevant, add them to the roadmap
 * 3. For all issues that are not relevant, do not add them to the roadmap, and notify the user
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(GLOBAL_ISSUE_CREATE).mergeMap((action: Action) => {
		const globalIssues = action.payload;
		const globalIssueIds = globalIssues.map((issue) => issue.issueId);
		const quickFilterIds = getQuickFilters(store.getState());
		const customFilterIds = getCustomFilters(store.getState());

		if (fg('jsw-roadmap-state-change-based-issue-hidden-flags')) {
			return getItems(getSourceARI(store.getState()), globalIssueIds).map(
				(roadmapIssuesAndUsers) => {
					const { issues: roadmapIssues, users: roadmapUsers } = roadmapIssuesAndUsers;
					const irrelevantKeys = getIrrelevantIssueKeys(globalIssues, roadmapIssues.hash);
					const createActions: ActionType[] = [];

					if (Object.keys(roadmapUsers).length > 0) createActions.push(addUsers(roadmapUsers));

					if (roadmapIssues.sequence.length > 0)
						createActions.push(extendIssues(roadmapIssues, GLOBAL_ISSUE_CREATE));

					if (irrelevantKeys.length > 0)
						createActions.push(irrelevantIssuesCreated({ issueKeys: irrelevantKeys }));

					return batchActions(createActions);
				},
			);
		}
		return Observable.zip(
			getItems(getSourceARI(store.getState()), globalIssueIds),
			quickFilterIds.length > 0 || customFilterIds.length > 0
				? filterItemsByJQLFilters(getSourceARI(store.getState()), quickFilterIds, customFilterIds)
				: Observable.of(null),
			(roadmapIssuesAndUsers, filterIssueIds: string[] | null) => {
				const { issues: roadmapIssues, users: roadmapUsers } = roadmapIssuesAndUsers;
				const irrelevantKeys = getIrrelevantIssueKeys(globalIssues, roadmapIssues.hash);
				const createActions: ActionType[] = [];

				if (filterIssueIds) {
					const issuesHiddenByQuickFilter = roadmapIssues.sequence.filter(
						(roadmapIssueId: string) => !filterIssueIds.includes(roadmapIssueId),
					);

					createActions.push(jqlFiltersSuccess(filterIssueIds));
					if (issuesHiddenByQuickFilter.length > 0) {
						createActions.push(issueHidden({ ids: issuesHiddenByQuickFilter }));
					}
				}

				if (Object.keys(roadmapUsers).length > 0) {
					createActions.push(addUsers(roadmapUsers));
				}

				if (roadmapIssues.sequence.length > 0) {
					createActions.push(extendIssues(roadmapIssues, CREATE_ISSUE));
				}

				if (irrelevantKeys.length > 0) {
					createActions.push(irrelevantIssuesCreated({ issueKeys: irrelevantKeys }));
				}

				return batchActions(createActions);
			},
		);
	})) as StateEpic;
