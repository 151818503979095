import type { StoreActionApi, Action } from '@atlassian/react-sweet-state';
import type { Position } from '../../../../../common/types/common';
import type { State, ContainerProps } from '../../common/types';
import { onDateDrag } from './date';
import { onNoDateDrag } from './no-date';

export type OnDragAction = (
	from: Position,
	to: Position,
	start: Position,
) => Action<State, ContainerProps>;

/* Our drag interaction supports:
 * - Items with existing dates (and thus positions). Dragging thus involves updating those positions.
 * - Items without dates (and thus no positions). Before dragging the dates are only "previews" which become concrete when dragged.
 */
export const onDrag =
	(from: Position, to: Position, start: Position) =>
	({ setState, getState }: StoreActionApi<State>, containerProps: ContainerProps) => {
		const { activeItem, dragType, itemPositions, previewBaselinePosition } = getState();

		if (!activeItem || !dragType) {
			return;
		}

		let dragResult;
		const { startDate, dueDate } = activeItem;

		if (startDate === undefined && dueDate === undefined && previewBaselinePosition) {
			// Refine types to prove to flow that both dates are always absent
			const noDateActiveItem = { ...activeItem, startDate, dueDate };

			dragResult = onNoDateDrag(
				{ from, to, start },
				{ activeItem: noDateActiveItem, dragType, previewBaselinePosition },
				containerProps,
			);
		} else if (startDate !== undefined && dueDate !== undefined) {
			// Refine types to prove to flow that both dates are always present
			const dateActiveItem = { ...activeItem, startDate, dueDate };

			dragResult = onDateDrag(
				{ from, to, start },
				{ activeItem: dateActiveItem, dragType, itemPositions },
				containerProps,
			);
		}

		// In case of an invalid drag (e.g. out of bounds) there will be no drag result
		dragResult && setState(dragResult);
	};
