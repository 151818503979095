import memoizeOne from 'memoize-one';
import { N0, N20 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import {
	BEFORE,
	INSIDE,
	LAST,
	DEFAULT_CREATE_ID,
	ITEM_TYPE,
	CREATE_ITEM_TYPE,
	DEFAULT_LEVEL,
	BASE_LEVEL_ITEM_HEIGHT,
	DEFAULT_DEPTH,
	BASE_LEVEL,
} from '../constants';
import type { CreateItemAnchor } from '../types/create.tsx';
import type {
	EnrichedCreateItem,
	EnrichedItem,
	FlattenedEnrichedItem,
} from '../types/hierarchy-enriched-item.tsx';
import type { ExpandedItems, Item, FlatItem, ItemId } from '../types/item.tsx';

export const isRoot = (depth: number): boolean => depth === DEFAULT_DEPTH;

export const isBaseLevel = (level: number): boolean => level === BASE_LEVEL;

const shouldCreateBeforeItem = (createItemAnchor: CreateItemAnchor, id: ItemId): boolean =>
	createItemAnchor !== undefined &&
	createItemAnchor.position === BEFORE &&
	createItemAnchor.beforeId === id;

const shouldCreateInsideItem = (createItemAnchor: CreateItemAnchor, id: ItemId): boolean => {
	if (createItemAnchor === undefined) {
		return false;
	}
	const createChildLast = createItemAnchor.position === INSIDE && createItemAnchor.parentId === id;

	const createChildSibling =
		createItemAnchor.position === BEFORE && createItemAnchor.parentId === id;

	return createChildLast || createChildSibling;
};

const shouldCreateLast = (createItemAnchor: CreateItemAnchor): boolean =>
	createItemAnchor === undefined || createItemAnchor.position === LAST;

/* The rootIndex is the position of the item at depth 0.
 * For parent items this will be its position, excluding any children.
 * For child items, this will be its parents position, as defined by the above.
 */
const getItemBackgroundColor = (rootIndex: number) =>
	rootIndex % 2 === 0 ? token('elevation.surface', N0) : token('elevation.surface.sunken', N20);

// To make sure the create item can be properly reconciled, we need a stable key.
const getStableCreateId = (createItemAnchor: CreateItemAnchor) => {
	if (createItemAnchor === undefined) {
		return DEFAULT_CREATE_ID;
	}

	/**
	 * We want to keep default key for the item at the end of the list
	 * otherwise new key generation will unmount and then mount component again
	 * which breaks empty list lifecycle
	 */
	if (!('beforeId' in createItemAnchor) && createItemAnchor.position === LAST) {
		return DEFAULT_CREATE_ID;
	}

	if (createItemAnchor.position === INSIDE && createItemAnchor.parentId) {
		return `create-${createItemAnchor.position}${createItemAnchor.parentId}`;
	}

	return `create-${createItemAnchor.position}${
		'beforeId' in createItemAnchor && createItemAnchor.beforeId ? createItemAnchor.beforeId : ''
	}`;
};

/* When creating last, it was always be on the same level as the root items.
 * When creating before, it will be on the same level as its anchor item.
 * When creating inside, it will be one level lower than its anchor item.
 */
const getCreateLevel = (createItemAnchor: CreateItemAnchor, anchorItem: FlatItem | undefined) => {
	if (anchorItem === undefined) {
		return DEFAULT_LEVEL;
	}

	// If not a create before, it will be a create inside
	const { id, level } = anchorItem;
	return shouldCreateBeforeItem(createItemAnchor, id) ? level : level - 1;
};

const getCreateDepth = (createItemAnchor: CreateItemAnchor, anchorItem: FlatItem | undefined) => {
	if (anchorItem === undefined) {
		return DEFAULT_DEPTH;
	}

	// If not a create before, it will be a create inside
	const { id, depth = 0 } = anchorItem;
	return shouldCreateBeforeItem(createItemAnchor, id) ? depth : depth + 1;
};

type EnrichCreateItemProps = {
	anchorItem: FlatItem | undefined;
	parentId: ItemId | undefined;
	createItemAnchor: CreateItemAnchor;
	rootIndex: number;
	ariaRowIndex: number | undefined;
	startOffset: number;
	topOffset: number;
	flatIndex: number;
};

const enrichCreateItem = ({
	anchorItem,
	parentId,
	createItemAnchor,
	rootIndex,
	ariaRowIndex,
	startOffset,
	topOffset,
	flatIndex,
}: EnrichCreateItemProps): EnrichedCreateItem<FlatItem> => ({
	id: getStableCreateId(createItemAnchor),
	type: CREATE_ITEM_TYPE,
	anchorItem,
	meta: {
		level: getCreateLevel(createItemAnchor, anchorItem),
		isActive: createItemAnchor !== undefined,
		backgroundColor: getItemBackgroundColor(rootIndex),
		parentId,
		ariaRowIndex,
		topOffset: topOffset + startOffset,
		depth: getCreateDepth(createItemAnchor, anchorItem),
	},
	flatIndex,
});

const countDescendants = (item: Item): number => {
	const { childItems = [] } = item;
	return childItems.length + childItems.reduce((acc, child) => acc + countDescendants(child), 0);
};

type EnrichedItemProps = {
	item: FlatItem;
	expandedItems: ExpandedItems;
	rootIndex: number;
	flatIndex: number;
	ariaRowIndex: number | undefined;
	isParent: boolean;
	numChildren: number;
	startOffset: number;
	childItemsHeight: number;
	parentItem?: EnrichedItem<FlatItem>;
	ariaControls?: string;
};
const enrichItem = ({
	item,
	expandedItems,
	rootIndex,
	flatIndex,
	ariaRowIndex,
	isParent,
	numChildren,
	startOffset,
	childItemsHeight,
	parentItem,
	ariaControls,
}: EnrichedItemProps): EnrichedItem<FlatItem> => ({
	type: ITEM_TYPE,
	item,
	meta: {
		parentId: parentItem?.item.id,
		backgroundColor: getItemBackgroundColor(rootIndex),
		isParent,
		isExpanded: Boolean(expandedItems[item.id]),
		ariaRowIndex,
		numChildren,
		childItemsHeight,
		rootIndex,
		topOffset: (parentItem?.meta.topOffset ?? 0) + startOffset,
		ariaControls,
	},
	flatIndex,
});

export const getItemLevel = (enrichedItem: FlattenedEnrichedItem) => {
	switch (enrichedItem.type) {
		case CREATE_ITEM_TYPE:
			return enrichedItem.meta.level;

		case ITEM_TYPE:
			return enrichedItem.item.level;

		default: {
			const _exhaustiveCheck: never = enrichedItem;
			return -1;
		}
	}
};

/*
 * Enrich the item tree to prepare it for rendering:
 * - Recursively construct meta items to preserve hierarchy information like depth, parent id, etc. Also,
 *       calculate some of the meta properties like children height, aria index, flat index, etc.
 * - Transform the creation anchor into a first-class citizen item
 * - Determine the visibility of child items
 *
 * @returns an object containing the enriched items, the aria row count,
 * the offset which is used to position the item in the UI list relative to its parent (top of the parent is considered 0px),
 * the flat index which is the index of each item if the whole list was flat,
 * and the aria row index is the index of the item if the whole list was expanded.
 */
export const getHierarchyEnrichedItems = memoizeOne(
	(
		items: Item[],
		createItemAnchor: CreateItemAnchor,
		expandedItems: ExpandedItems,
		isCreateLastEnabled: boolean,
		isCreateSiblingEnabled: boolean,
		isCreateChildEnabled: boolean,
		rootItemHeight: number,
		ariaRowOffset: number | undefined,
		lastFlatIndex = 0,
		parentItem?: EnrichedItem<FlatItem>,
	): {
		flatItems: FlattenedEnrichedItem[];
		itemIndexes: Record<ItemId, number>;
		ariaRowCount: number;
		offset: number;
		flatIndex: number;
		ariaRowIndex: number;
	} => {
		// the height of any item is set depending on its depth
		// - root items have a height of rootItemHeight
		// - nested items have a height of nestedItemHeight
		const depth = parentItem ? (parentItem.item.depth ?? 0) + 1 : 0;

		// if the parent item is root (has no parent), then the offset should be 0 otherwise it should be the parent's height
		// as the item is positioned relative to its parent's container
		let offset = 0;
		if (depth > 0) {
			// add the parent's height to the offset
			offset += depth > 1 ? BASE_LEVEL_ITEM_HEIGHT : rootItemHeight;
		}
		let flatIndex = lastFlatIndex;
		const isRowCountKnown = ariaRowOffset !== undefined;
		let ariaRowIndex = (ariaRowOffset ?? 0) + 1; // NOTE: aria-rowindex starts from 1 instead of 0

		const flattenedItems: FlattenedEnrichedItem[] = [];
		const itemIndexes: Record<ItemId, number> = {};

		items.forEach((item: Item, index: number) => {
			const rootIndex = parentItem?.meta.rootIndex ?? index;
			const itemHeight = depth > 0 ? BASE_LEVEL_ITEM_HEIGHT : rootItemHeight;
			const {
				id,
				level = DEFAULT_LEVEL,
				isInTransition,
				canCreateChildOverride = true,
				childItems = [],
			} = item;
			const itemProps = {
				id,
				level,
				depth,
				isInTransition,
				canCreateChildOverride,
			};

			if (isCreateSiblingEnabled && shouldCreateBeforeItem(createItemAnchor, id)) {
				const createItem = enrichCreateItem({
					anchorItem: itemProps,
					parentId: parentItem?.item.id,
					createItemAnchor,
					rootIndex,
					ariaRowIndex: isRowCountKnown ? ariaRowIndex : undefined,
					startOffset: offset,
					topOffset: parentItem?.meta?.topOffset ?? 0,
					flatIndex,
				});

				flattenedItems.push(createItem);
				itemIndexes[createItem.id] = flatIndex;

				ariaRowIndex += 1;
				// create item has always height of the root level
				offset += rootItemHeight;
				// we need to increase the flat index to account for the create item
				flatIndex += 1;
			}
			// this is to find out if there is a create item inside the item to count it in the children count
			const isChildCreate =
				isCreateChildEnabled &&
				canCreateChildOverride &&
				shouldCreateInsideItem(createItemAnchor, id);

			// this is to create child is being created using the plus button next to the item
			const isChildCreateInside = Boolean(
				isChildCreate && createItemAnchor && createItemAnchor.position === INSIDE,
			);
			// ItemsRenderer uses this count to find the child items in the flattened items
			const numChildren = childItems.length + (isChildCreate ? 1 : 0);
			const flattenedItem = enrichItem({
				item: itemProps,
				expandedItems,
				rootIndex,
				flatIndex,
				ariaRowIndex: isRowCountKnown ? ariaRowIndex : undefined,
				isParent: childItems.length > 0,
				numChildren, // will be calculated after recursively created child items
				startOffset: offset,
				childItemsHeight: 0, // will be calculated after recursively created child items
				parentItem,
				...(childItems.length > 0 && {
					ariaControls: childItems.map((child) => child.id).join(' '),
				}),
			});

			// Increase the index to account for the parent item, which will be inserted first
			flatIndex += 1;
			ariaRowIndex += 1;
			// consider the item itself is added to calculate the offset
			offset += itemHeight;

			let innerHierarchyEnrichedItems;
			let itemsHeight = 0;
			let childEnrichedItems: FlattenedEnrichedItem[] = [];

			if (expandedItems[id]) {
				// for the children the offset is relative the parent
				// so we should reset the offset to parentsHeight
				innerHierarchyEnrichedItems = getHierarchyEnrichedItems(
					childItems,
					createItemAnchor,
					expandedItems,
					isCreateLastEnabled,
					isCreateSiblingEnabled,
					isCreateChildEnabled,
					rootItemHeight,
					isRowCountKnown ? ariaRowIndex - 1 : -1,
					flatIndex,
					flattenedItem,
				);
				ariaRowIndex = innerHierarchyEnrichedItems?.ariaRowIndex ?? 0;

				// the height of the parent shouldn't be included in the children height
				itemsHeight = (innerHierarchyEnrichedItems?.offset ?? itemHeight) - itemHeight;
				childEnrichedItems = innerHierarchyEnrichedItems?.flatItems ?? [];
				flatIndex = innerHierarchyEnrichedItems?.flatIndex ?? flatIndex;

				// Creating an item inside a parent will always be in last position
				if (isChildCreateInside) {
					const createChildItem = enrichCreateItem({
						anchorItem: itemProps,
						parentId: id,
						createItemAnchor,
						rootIndex,
						ariaRowIndex: isRowCountKnown ? ariaRowIndex : undefined,
						// offset of the create item would be the height of prior children plus the parent height,
						startOffset: itemsHeight + itemHeight,
						topOffset: flattenedItem?.meta?.topOffset ?? 0,
						flatIndex,
					});
					childEnrichedItems.push(createChildItem);

					// offset will be calculated based on the itemsHeight so we don't need to increase it here
					itemsHeight += rootItemHeight;
					ariaRowIndex += 1;
					flatIndex += 1;
				}
			} else {
				ariaRowIndex += countDescendants(item);
			}

			flattenedItems.push({
				...flattenedItem,
				meta: { ...flattenedItem.meta, childItemsHeight: itemsHeight },
			});
			itemIndexes[flattenedItem.item.id] = flattenedItem.flatIndex;

			childEnrichedItems.forEach((enrichedChildItem) => {
				flattenedItems.push(enrichedChildItem);
				const key = 'id' in enrichedChildItem ? enrichedChildItem.id : enrichedChildItem.item.id;
				itemIndexes[key] = enrichedChildItem.flatIndex;
			});

			offset += itemsHeight;
		});

		if (depth === DEFAULT_DEPTH && isCreateLastEnabled && shouldCreateLast(createItemAnchor)) {
			const createItem = enrichCreateItem({
				anchorItem: undefined,
				parentId: undefined,
				createItemAnchor,
				rootIndex: items.length,
				ariaRowIndex: isRowCountKnown ? ariaRowIndex : undefined,
				startOffset: offset,
				topOffset: parentItem?.meta?.topOffset ?? 0,
				flatIndex,
			});

			flattenedItems.push(createItem);
			itemIndexes[createItem.id] = flatIndex;

			ariaRowIndex += 1;
			offset += rootItemHeight;
			flatIndex += 1;
		}

		return {
			flatItems: flattenedItems,
			itemIndexes,
			ariaRowCount: isRowCountKnown ? ariaRowIndex - 1 : -1,
			offset,
			flatIndex,
			ariaRowIndex,
		};
	},
);
