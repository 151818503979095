import type { Query } from '@atlassian/jira-software-roadmap-model/src/router/index.tsx';
import type { RouterActionPush } from '@atlassian/react-resource-router';
import { hasMacroConfig } from '../../../state/app/selectors';
import { routerLocationChange } from '../../../state/router/actions';
import { getLocation, getQuery } from '../../../state/router/selectors';
import type { State } from '../../../state/types';
import { convertQueryToString } from '../../../utils/router.tsx';

const updateQuery = (push: RouterActionPush, state: State, callback: (query: Query) => Query) => {
	const location = getLocation(state);
	const searchStr = convertQueryToString(callback(getQuery(state)));
	// Roadmap macro doesn't need the url to be updated because it is in an iframe
	if (!hasMacroConfig(state)) {
		push({ ...location, search: searchStr });
	}
	return routerLocationChange({ ...location, search: `?${searchStr}` });
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (push: RouterActionPush, state: State, callback: (query: Query) => Query) =>
	updateQuery(push, state, callback);
