/* ===========================================================================
 * === LEGACY @atlassian/jira-drag-observer implementation ===
 *
 *
 * This entire `legacy-resizer` module can be deleted once the new
 * `resizer` implementation with @atlaskit/pragmatic-drag-and-drop has been
 * soaked in production and the feature gate is deprecated.
 *
 * TODO(FG-REMOVE): jsw_roadmaps_timeline_table_custom_scroll_pdnd
 *
 * https://switcheroo.atlassian.com/ui/gates/49cac7b3-a5f8-45d9-9533-08d52c4f3345
 *
 * =========================================================================== */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import clamp from 'lodash/clamp';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { Position } from '@atlassian/jira-drag-observer/src/common/types.tsx';
import { MINIMUM_LIST_WIDTH, LIST_RESIZE_DRAG_AND_DROP } from '../../../../../common/constants';
import type { OnSetListWidth as OnResize } from '../../../../../common/types/callbacks.tsx';
import type { DndLongTaskMetricComponent } from '../../../../../common/types/drag-and-drop.tsx';
import { fireUIAnalyticsEvent } from '../../../../../common/utils/analytics';
import ResizeHandler from '../resize-handler';
import { useMaxWidth } from '../use-max-width';

type Props = {
	width: number;
	DndLongTaskMetric: DndLongTaskMetricComponent | undefined;
	onResize: OnResize;
	onResizeEnd: OnResize;
};

const Resizer = ({ width, DndLongTaskMetric, onResize, onResizeEnd }: Props) => {
	const initialWidth = useRef<number>(width);
	const [isDragging, setIsDragging] = useState<boolean>(false);
	const maxWidth = useMaxWidth();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		width > maxWidth && onResize(maxWidth);
	}, [width, maxWidth, onResize]);

	const onDragStart = useCallback(() => {
		initialWidth.current = width;
		setIsDragging(true);
	}, [width]);

	const onDrag = useCallback(
		(_from: Position, to: Position, start: Position) => {
			const newWidth = Math.round(initialWidth.current + to.x - start.x);
			const saneWidth = clamp(newWidth, MINIMUM_LIST_WIDTH(), maxWidth);

			onResize(saneWidth);
		},
		[maxWidth, onResize],
	);

	const onDragEnd = useCallback(
		(from: Position, to: Position) => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'resized',
				actionSubject: 'list',
				actionSubjectId: 'resizedList',
				attributes: { didExpand: to.x > from.x },
			});

			fireUIAnalyticsEvent(analyticsEvent);
			setIsDragging(false);
			onResizeEnd(initialWidth.current);
		},
		[createAnalyticsEvent, onResizeEnd],
	);

	return (
		<>
			<ResizeHandler onDragStart={onDragStart} onDrag={onDrag} onDragEnd={onDragEnd} />
			{DndLongTaskMetric !== undefined && (
				<DndLongTaskMetric isDragging={isDragging} metricType={LIST_RESIZE_DRAG_AND_DROP} />
			)}
		</>
	);
};

export default Resizer;
