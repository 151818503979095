import React, { memo } from 'react';
import { useTimelineState } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/timeline/index.tsx';
import { CLOSED } from '../../../common/constants/interval.tsx';
import type { Interval, IntervalGroupPositions } from '../../../common/types/interval.tsx';
import { getIntervalGroupPositions } from '../../../common/utils/interval/positions.tsx';
import { IntervalMarker } from './marker';
import { IntervalBaseMarker } from './marker/main.tsx';

export type Props = {
	intervals: ReadonlyArray<Interval>;
	/**
	 * render props method to show the Popup content when any sprint been pressed
	 * @param selectedIntervals collection of intervals related to a sprint
	 * @returns
	 */
	renderPopupContent: (
		selectedIntervals: ReadonlyArray<Interval>,
		setInitialFocusRef?: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
	) => React.ReactNode;
};

const RoadmapIntervals = ({ intervals, renderPopupContent }: Props) => {
	const [{ timelineDuration, timelineWidth }] = useTimelineState();
	const intervalGroups = getIntervalGroupPositions(intervals, timelineDuration, timelineWidth);

	return (
		<>
			{intervalGroups.map((intervalGroup: IntervalGroupPositions, index: number) => {
				const hasOpenInterval = intervalGroup.intervals.some(
					({ state }: Interval) => state !== CLOSED,
				);

				const Marker = hasOpenInterval ? IntervalMarker : IntervalBaseMarker;

				return (
					<Marker
						key={index}
						intervalGroup={intervalGroup}
						renderPopupContent={renderPopupContent}
					/>
				);
			})}
		</>
	);
};

RoadmapIntervals.whyDidYouRender = true;
const RoadmapIntervalsMemoed = memo<Props>(RoadmapIntervals);
RoadmapIntervalsMemoed.displayName = 'RoadmapIntervals';

export { RoadmapIntervalsMemoed as RoadmapIntervals };
