import React, { type SyntheticEvent, useEffect } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { SuccessAutoDismissFlag } from '@atlassian/jira-flags';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';
import { getIssueUrl } from './utils';

export type Props = {
	id: string | number;
	keys: string[];
	isFilterApplied: boolean;
	onClearFilter: () => void;
	onViewIssue: (key: string) => void;
	onDismissed?: (arg1: string | number) => void;
};

const IssueHiddenFlag = ({
	id,
	keys,
	isFilterApplied,
	onClearFilter,
	onViewIssue,
	onDismissed,
}: Props) => {
	const { formatMessage } = useIntl();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		fireTrackAnalytics(
			createAnalyticsEvent({ action: 'viewed', actionSubject: 'issueHiddenFlag' }),
			'viewed issueHiddenFlag',
			{
				isStateChangeBasedIssueHiddenFlagsEnabled: fg(
					'jsw-roadmap-state-change-based-issue-hidden-flags',
				),
			},
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const numIssues = keys.length;

	const onClick =
		numIssues === 1 && onViewIssue
			? (e: SyntheticEvent<HTMLElement, MouseEvent>) => {
					const { ctrlKey, metaKey } = e.nativeEvent;
					if (ctrlKey || metaKey) return;

					e.preventDefault();
					onViewIssue(keys[0]);
				}
			: undefined;

	const description =
		numIssues === 1 ? (
			<FormattedI18nMessage
				message={formatMessage(messages.flagDescriptionWithLink, {
					linkStart: '{linkStart}',
					linkEnd: '{linkEnd}',
				})}
				componentsMapping={{
					link: () => (
						<a href={getIssueUrl([keys[0]])} onClick={onClick} target="_blank">
							{keys[0]}
						</a>
					),
				}}
			/>
		) : (
			formatMessage(messages.flagDescription, { numIssues })
		);

	const viewIssueAction = {
		content: formatMessage(messages.issueLink, { numIssues }),
		href: getIssueUrl(keys),
		onClick,
		target: '_blank',
	};

	const clearFilterAction = {
		content: formatMessage(messages.clearFilter),
		onClick: onClearFilter,
	};

	const actions = [];

	if (numIssues > 0) actions.push(viewIssueAction);
	if (isFilterApplied) actions.push(clearFilterAction);

	return (
		<SuccessAutoDismissFlag
			id={id}
			onDismissed={onDismissed}
			title={formatMessage(messages.title, { numIssues })}
			description={description}
			actions={actions}
		/>
	);
};

export default IssueHiddenFlag;
