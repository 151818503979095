import React, { type ComponentType } from 'react';
import '@atlaskit/css-reset';
import ProjectBreadcrumb from '@atlassian/jira-breadcrumbs/src/common-breadcrumbs/project/index.tsx';
import { ViewExperienceTrackingProvider } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracking-provider/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { type Ari, createAri } from '@atlassian/jira-platform-ari';
import { withSuspense } from '@atlassian/jira-platform-resources-utils/src/utils/with-suspense/index.tsx';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';
import { MetaAPIConsumer } from '@atlassian/jira-providers-meta/src';
import { nextGenRoadmapResource } from '@atlassian/jira-router-resources-roadmap/src/services/next-gen-roadmap/index.tsx';
import { JIRA_SOFTWARE } from '@atlassian/jira-shared-types/src/application.tsx';
import ResourceRefreshOnMount from '@atlassian/jira-software-resource-invalidator/src/ui/resource-refresh-on-mount/index.tsx';
import { NEXTGEN } from '@atlassian/jira-software-roadmap-model/src/app/index.tsx';
import { MarkProductStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { type RouteContext, useResource, withRouter } from '@atlassian/react-resource-router';
import { toRoadmapSpaAppId } from '../../common/utils';
import renderRoadmap from '../common/roadmap';
import { withLicenseCheck } from '../common/roadmap/with-license-check';

// Replace with lodash/noop
// eslint-disable-next-line @typescript-eslint/no-empty-function
const clearCache = () => {};
// Replace with lodash/noop
// eslint-disable-next-line @typescript-eslint/no-empty-function
const updateCache = () => {};

const getKeys = (routeContext: RouteContext, cloudId: string): [string, string, Ari] => {
	const projectKey = routeContext.match.params.projectKey || '';
	const boardId = routeContext.match.params.boardId || '';

	return [
		projectKey,
		boardId,
		createAri({
			resourceOwner: 'jira-software',
			cloudId,
			resourceType: 'board',
			resourceId: boardId,
		}),
	];
};

const useRoadmapResource = withSuspense(() => useResource(nextGenRoadmapResource));

export const Roadmap = (projectKey: string, boardId: number, sourceAri: Ari) => {
	const tenantContext = useTenantContext();
	const roadmapDataResource = useRoadmapResource();
	const [{ data: containerData }] = useContainerContext();
	const projectName =
		(containerData && 'project' in containerData && containerData.project.name) || '';

	const prefetchDataId = `software/roadmap:${boardId}`;

	const prefetchData = {
		data: roadmapDataResource.data,
		loading: roadmapDataResource.loading,
		error: roadmapDataResource.error,
		clearCache,
		updateCache,
		id: prefetchDataId,
	};

	if (!(containerData && 'project' in containerData && containerData.project)) {
		return (
			<MetaAPIConsumer>
				{(getMeta) =>
					renderRoadmap(
						NEXTGEN,
						boardId,
						projectName,
						sourceAri,
						getMeta,
						tenantContext,
						prefetchData,
					)
				}
			</MetaAPIConsumer>
		);
	}

	return (
		<MetaAPIConsumer>
			{(getMeta) => (
				<ProjectBreadcrumb
					projectKey={containerData.project.key}
					projectName={containerData.project.name}
					projectId={containerData.project.id}
					isSimplifiedProject
				>
					{renderRoadmap(
						NEXTGEN,
						boardId,
						projectName,
						sourceAri,
						getMeta,
						tenantContext,
						prefetchData,
					)}
					<ResourceRefreshOnMount resource={roadmapDataResource} />
				</ProjectBreadcrumb>
			)}
		</MetaAPIConsumer>
	);
};

export const RoadmapWithExperienceTracker = (routeContext: RouteContext) => {
	const tenantContext = useTenantContext();
	const [projectKey, boardId, sourceAri] = getKeys(routeContext, tenantContext.cloudId);

	return (
		<>
			<MarkProductStart />
			<ViewExperienceTrackingProvider
				experienceId={projectKey}
				experience="viewRoadmap"
				analyticsSource="roadmap"
				edition={tenantContext.appEditions.software}
				application={JIRA_SOFTWARE}
			>
				<ErrorBoundary
					id={toRoadmapSpaAppId(NEXTGEN)}
					packageName="jiraSpaAppsRoadmap"
					withExperienceTracker
				>
					{Roadmap(projectKey, Number(boardId), sourceAri)}
				</ErrorBoundary>
			</ViewExperienceTrackingProvider>
		</>
	);
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const RouterWrapper = withRouter(
	withLicenseCheck<RouteContext, ComponentType<RouteContext>>(RoadmapWithExperienceTracker),
) as ComponentType<Record<string, any>>; // eslint-disable-line @typescript-eslint/no-explicit-any

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (props: any) => <RouterWrapper {...props} />;
