import { connect } from '@atlassian/jira-react-redux';
import {
	getInteractionPerformanceExtraData,
	type InteractionPerformanceExtraData,
} from '../state/selectors/metrics';
import type { State } from '../state/types';
import interactionPerformanceFactory from './utils';

export const interactionPerformance =
	interactionPerformanceFactory<InteractionPerformanceExtraData>();

export type RequiredStateProps = {
	interactionPerformanceExtraData: InteractionPerformanceExtraData;
};

const CaptureInteractionMetricsExtraData = ({
	interactionPerformanceExtraData,
}: RequiredStateProps) => {
	interactionPerformance.setExtraData(interactionPerformanceExtraData);
	return null;
};

export const InteractionMetricsExtraData = connect(
	(state: State) => ({
		interactionPerformanceExtraData: getInteractionPerformanceExtraData(state),
	}),
	{},
)(CaptureInteractionMetricsExtraData);
