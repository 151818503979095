import type { TimelineDuration } from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/timeline.tsx';
import { CLOSED } from '../../../common/constants/interval.tsx';
import type { Interval, IntervalGroupPositions } from '../../../common/types/interval.tsx';
import { getIntervalGroupPositions } from '../../../common/utils/interval/positions.tsx';

type Parameters = {
	intervals: ReadonlyArray<Interval>;
	currentIntervalId: string | undefined;
	timelineDuration: TimelineDuration;
	timelineWidth: number;
};

/* A drop zone is an interval that an item can be assigned to, so we need to avoid:
 * - Showing already closed intervals
 * - Showing the currently assigned interval
 */
export const getActiveOrFutureIntervalGroups = ({
	intervals,
	currentIntervalId,
	timelineDuration,
	timelineWidth,
}: Parameters): ReadonlyArray<IntervalGroupPositions> => {
	let unassignedIntervals = intervals;

	if (currentIntervalId !== undefined) {
		unassignedIntervals = intervals.filter((interval) => interval.id !== currentIntervalId);
	}

	const intervalGroups = getIntervalGroupPositions(
		unassignedIntervals,
		timelineDuration,
		timelineWidth,
	);

	return intervalGroups.filter((intervalGroup) =>
		intervalGroup.intervals.some((interval) => interval.state !== CLOSED),
	);
};
