import React from 'react';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	RELEASES_HEADER_ID,
	SPRINTS_HEADER_ID,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/index.tsx';
import { RoadmapListHeaderRow } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/list-header-item/index.tsx';
import { DEFAULT_HEADER_ROW_ID } from '@atlassian/jira-software-roadmap-timeline-table/src/common/constants';
import type { HeaderItem } from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/header.tsx';
import messages from './messages';

const ListHeaderItem = ({ id }: HeaderItem) => {
	const { formatMessage } = useIntl();

	switch (id) {
		case RELEASES_HEADER_ID:
			return <RoadmapListHeaderRow title={formatMessage(messages.releases)} />;
		case SPRINTS_HEADER_ID:
			return <RoadmapListHeaderRow title={formatMessage(messages.sprints)} />;
		case DEFAULT_HEADER_ROW_ID:
			return fg('jsw_roadmaps_timeline-empty-cell-label') ? (
				<VisuallyHidden>{formatMessage(messages.empty)}</VisuallyHidden>
			) : null;
		default:
			return null;
	}
};

export const renderListHeaderItem = ({ id }: HeaderItem) => <ListHeaderItem id={id} />;
