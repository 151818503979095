import type { IntlFormatV2 } from '@atlassian/jira-intl/src/v2/types.tsx';
import { getDateFromTimestamp } from '@atlassian/jira-software-roadmap-utils/src/utils/dates';
import type { Interval } from '../../../../../common/types/interval.tsx';
import messages from './messages';

export const getLabel = ({
	intervals,
	targetIntervalId,
	isLabelVisible,
}: {
	intervals: ReadonlyArray<Interval>;
	targetIntervalId: string | undefined;
	isLabelVisible: boolean;
}) => {
	if (!isLabelVisible) {
		return null;
	}
	if (targetIntervalId !== undefined) {
		const interval = intervals.find(({ id }: Interval) => id === targetIntervalId);
		return interval ? interval.name : null;
	}
	return intervals.map(({ name }: Interval) => name).join(', ');
};

export const getSprintAriaLabel = (
	intervals: ReadonlyArray<Interval>,
	formatMessage: IntlFormatV2['formatMessage'],
) =>
	intervals
		.map(({ name, startDate, endDate }) =>
			formatMessage(messages.sprintLabel, {
				name,
				startDate: getDateFromTimestamp(startDate),
				endDate: getDateFromTimestamp(endDate),
			}),
		)
		.join(', ');
