import { combineEpics } from 'redux-observable';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import type { StateEpic } from '../common/types';
import attemptRank from './attempt-rank';
import attemptRemove from './attempt-remove-issue';
import count from './count';
import create from './create';
import dependencies from './dependencies';
import expand from './expand';
import exportCsv from './export-csv';
import globalIssueCreate from './global-issue-create';
import hiddenFlag from './hidden-flag';
import optimisticUpdate from './optimistic-update';
import rank from './rank';
import realtime from './realtime';
import reload from './reload';
import scheduleIssues from './schedule-issues';
import prevSelect, { multiselect, select } from './select';
import update from './update';
import updateAndRank from './update-and-rank';

const issuesEpicsOld = (): StateEpic =>
	combineEpics(
		count,
		create,
		expand,
		globalIssueCreate,
		select,
		multiselect,
		// Remove with project_timeline_multi-select_and_checkboxes
		prevSelect,
		update,
		updateAndRank,
		reload,
		rank,
		attemptRank,
		dependencies,
		realtime,
		optimisticUpdate,
		scheduleIssues,
		attemptRemove,
		exportCsv,
	);

const issuesEpicsNew = (): StateEpic =>
	combineEpics(
		count,
		create,
		expand,
		globalIssueCreate,
		hiddenFlag,
		select,
		multiselect,
		// Remove with project_timeline_multi-select_and_checkboxes
		prevSelect,
		update,
		updateAndRank,
		reload,
		rank,
		attemptRank,
		dependencies,
		realtime,
		optimisticUpdate,
		scheduleIssues,
		attemptRemove,
		exportCsv,
	);

export default functionWithCondition(
	() => fg('jsw-roadmap-state-change-based-issue-hidden-flags'),
	issuesEpicsNew(),
	issuesEpicsOld(),
);
