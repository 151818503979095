import type { Action } from '@atlassian/react-sweet-state';
import { INTERVAL } from '../../../../../common/constants/date.tsx';
import type { State, ContainerProps } from '../../common/types';
import { getTargetIntervalId } from '../../common/utils';

export type OnLingerAction = () => Action<State, ContainerProps>;

/* To "linger" on an item is to spend a small amount of time with the cursor over it (or one of its key elements).
 * We take this as a sign of intent for the user to interact with the item, rather than coincidence from scrolling
 * or simply navigating.
 */
export const onLinger: OnLingerAction =
	() =>
	({ getState, setState }, { timelineWidth, simpleIntervals }) => {
		const { activeItem, itemPositions } = getState();

		if (
			(activeItem?.startDateType === INTERVAL && activeItem?.dueDateType === INTERVAL) ||
			activeItem?.intervalId !== undefined
		) {
			let targetIntervalId;
			const { id, startDate, dueDate, intervalId } = activeItem;
			const leftPosition = itemPositions[id]?.leftPosition;

			/* For existing items, we can directly compare their pre-existing interval.
			 * Otherwise, for previews that don't have an interval yet, we can compare interval positions.
			 */
			if (startDate !== undefined && dueDate !== undefined) {
				const isIntervalExists = simpleIntervals.find(
					({ intervalIds }) => intervalId && intervalIds.includes(intervalId),
				);
				if (isIntervalExists) {
					targetIntervalId = intervalId;
				}
			} else if (
				/* Don't highlight target interval id if a date-based item has no dates
				 */
				leftPosition !== undefined &&
				activeItem.startDateType === INTERVAL &&
				activeItem.dueDateType === INTERVAL
			) {
				targetIntervalId = getTargetIntervalId(simpleIntervals, timelineWidth, leftPosition);
			}

			setState({ hasLingered: true, targetIntervalId });
		}
	};
