import type { MiddlewareAPI, Action as ActionType } from 'redux';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import { batchActions } from 'redux-batched-actions';
import { Observable } from 'rxjs/Observable';
import uuid from 'uuid';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import type { addRoadmapItemMutation$data } from '@atlassian/jira-relay/src/__generated__/addRoadmapItemMutation.graphql';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	BASE_LEVEL,
	PARENT_LEVEL,
} from '@atlassian/jira-software-roadmap-model/src/hierarchy/index.tsx';
import type { Issue } from '@atlassian/jira-software-roadmap-model/src/issue/index.tsx';
import { create } from '@atlassian/jira-software-roadmap-services/src/issues/add-roadmap-item.tsx';
import { transformIssue } from '@atlassian/jira-software-roadmap-services/src/issues/transformers.tsx';
import type { CreatedIssue } from '@atlassian/jira-software-roadmap-services/src/issues/types.tsx';
import type { CreateItemAnchor } from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/create.tsx';
import { getSourceARI, isClassic } from '../../../state/app/selectors';
import { getBoardJql, getProjectId } from '../../../state/configuration/selectors';
import { addPayloadGlobalIssueCreate } from '../../../state/entities/global-issue-create/actions';
import {
	replaceIssue,
	removeIssue,
	type CreateIssueAction,
	insertIssue,
	CREATE_ISSUE,
} from '../../../state/entities/issues/actions';
import {
	irrelevantIssuesCreated,
	aggError,
	type AggErrorAction,
	issueHidden,
} from '../../../state/flags/actions';
import { getQueryContainsJQLFilters } from '../../../state/router/selectors';
import { getFilteredCustomFilters } from '../../../state/selectors/filters';
import { updateCreationPreferences } from '../../../state/settings/actions';
import type { State } from '../../../state/types';
import { jqlFiltersSuccess } from '../../../state/ui/filters/actions';
import {
	getFilteredQuickFilterQueries,
	getJQLFilteredIssueIds,
} from '../../../state/ui/filters/selectors';
import { resetCreateItemAnchor } from '../../../state/ui/table/actions';
import { onHandleAggErrors } from '../../common/handle-agg-errors';
import { getIsIssueHiddenByFiltersOrSettings } from '../common';
import { getSafeRankBeforeRequest } from '../common/rank';
import { performPostCreateSideEffects } from './utils';
import { getGICReasonOnCreateError, getFieldsForCreateDialogGIC } from './utils/global-create';

const getPostCreateActions = (
	state: State,
	temporaryId: IssueId,
	{ id, key, issue, matchesSource, matchesJqlFilters }: CreatedIssue,
	skipQuickFilters: boolean,
) => {
	const actions = [];

	if (matchesSource && issue !== undefined) {
		actions.push(replaceIssue({ temporaryId, id, issue }, CREATE_ISSUE));

		if (!fg('jsw-roadmap-state-change-based-issue-hidden-flags')) {
			const { isHiddenByFilter } = getIsIssueHiddenByFiltersOrSettings(
				state,
				issue,
				id,
				skipQuickFilters,
			);
			const isHidden = isHiddenByFilter || !matchesJqlFilters;
			if (isHidden) {
				actions.push(issueHidden({ ids: [id] }));
			}
		}

		if (matchesJqlFilters) {
			const currentlyVisibleIssueIds = getJQLFilteredIssueIds(state);
			if (getQueryContainsJQLFilters(state)) {
				actions.push(jqlFiltersSuccess([...currentlyVisibleIssueIds, id]));
			}
		}
	} else {
		if (!key) throw new Error('Cannot remove issue without a key (key was undefined)');
		actions.push(removeIssue(temporaryId), irrelevantIssuesCreated({ issueKeys: [key] }));
	}

	return batchActions(actions);
};

const getCreateErrorActions = (temporaryId: IssueId, aggErrorAction?: AggErrorAction) =>
	batchActions([aggErrorAction ?? aggError(), removeIssue(temporaryId), resetCreateItemAnchor()]);

const createOnBackend = (
	store: MiddlewareAPI<State>,
	temporaryId: IssueId,
	optimisticIssue: Issue,
	createItemAnchor: NonNullable<CreateItemAnchor>,
	analyticsEvent: UIAnalyticsEvent,
) => {
	const state = store.getState();
	const { issueTypeId } = optimisticIssue;
	const projectId = getProjectId(state);
	const sourceARI = getSourceARI(state);
	const jql = getBoardJql(state);
	let jqlContexts: ReadonlyArray<string> = [];
	if (isClassic(state)) {
		jqlContexts = getFilteredQuickFilterQueries(state);
	} else if (!isClassic(state)) {
		jqlContexts = getFilteredCustomFilters(state);
	}

	return getSafeRankBeforeRequest(
		state,
		createItemAnchor !== undefined &&
			'beforeId' in createItemAnchor &&
			createItemAnchor.beforeId !== undefined
			? createItemAnchor.beforeId
			: undefined,
	)
		.mergeMap((rankRequest) =>
			create({
				sourceARI,
				input: {
					projectId,
					itemTypeId: issueTypeId,
					parentId: optimisticIssue.parentId.value,
					summary: optimisticIssue.summary.value,
					dueDate: null,
					startDate: null,
					color: optimisticIssue.color.value,
					rank: rankRequest ? { beforeId: String(rankRequest.beforeId) } : undefined,
					jql,
					jqlContexts,
					assignee: optimisticIssue.assignee,
					labels: optimisticIssue.labels.length > 0 ? optimisticIssue.labels : undefined,
					componentIds:
						optimisticIssue.componentIds.length > 0 ? optimisticIssue.componentIds : undefined,
					versionIds:
						optimisticIssue.versionIds.length > 0 ? optimisticIssue.versionIds : undefined,
				},
			}),
		)
		.mergeMap((data: addRoadmapItemMutation$data) => {
			const addRoadmapItemPayload = data?.roadmaps?.addRoadmapItem;
			const addRoadmapItemOutput = data?.roadmaps?.addRoadmapItem?.output;

			if (addRoadmapItemPayload?.success === true && addRoadmapItemOutput) {
				const { id, key, matchesSource, matchesJqlFilters, item } = addRoadmapItemOutput;

				// WARNING!!! We need to retrieve the state again here as a workaround to a race-condition #ROAD-3073
				const safeState = store.getState();
				const createIssue: CreatedIssue = {
					id,
					parentId: optimisticIssue.parentId.value,
					issueTypeId,
					key,
					issue: item ? transformIssue(item) : undefined,
					matchesSource,
					matchesJqlFilters: matchesJqlFilters ?? true,
				};
				performPostCreateSideEffects(safeState, temporaryId, createIssue, analyticsEvent);
				const skipQuickFilters = matchesJqlFilters ?? true;
				return Observable.of(
					getPostCreateActions(safeState, temporaryId, createIssue, skipQuickFilters),
				);
			}

			const errorMessage = getGICReasonOnCreateError(addRoadmapItemPayload?.errors);
			if (errorMessage) {
				return Observable.of(
					batchActions([
						removeIssue(temporaryId),
						addPayloadGlobalIssueCreate(
							getFieldsForCreateDialogGIC(state, optimisticIssue, errorMessage),
						),
						updateCreationPreferences({
							itemTypeId: issueTypeId,
							level: optimisticIssue.parentId.value ? BASE_LEVEL : PARENT_LEVEL,
							projectId,
						}),
						resetCreateItemAnchor(),
					]),
				);
			}

			return onHandleAggErrors(!!addRoadmapItemPayload, addRoadmapItemPayload?.errors).map(
				(aggErrorAction) => getCreateErrorActions(temporaryId, aggErrorAction),
			);
		})
		.catch(() => Observable.of(getCreateErrorActions(temporaryId)));
};

/* When an issue has no required fields it can be created inline.
 * 1. Insert an optimistic issue.
 * 2. Create, or create and rank issue on the backend. Depends on the create anchor (sibling, parent, etc).
 * 3. Replace the optimistic issue with the real issue, or remove it. Depends on post-create visibility.
 */
const inlineCreate = (
	store: MiddlewareAPI<State>,
	{ payload: { level }, meta }: CreateIssueAction,
	optimisticIssue: Issue,
	createItemAnchor: NonNullable<CreateItemAnchor>,
) => {
	const projectId = getProjectId(store.getState());
	const { issueTypeId } = optimisticIssue;
	const temporaryId = uuid.v4();
	const optimisticActions: ActionType[] = [
		updateCreationPreferences({ itemTypeId: issueTypeId, level, projectId }),
		insertIssue({ id: temporaryId, issue: optimisticIssue, anchor: createItemAnchor }),
	];

	if (meta.resetCreateItemAnchor) {
		optimisticActions.push(resetCreateItemAnchor());
	}

	return Observable.concat(
		Observable.of(batchActions(optimisticActions)),
		createOnBackend(store, temporaryId, optimisticIssue, createItemAnchor, meta.analyticsEvent),
	);
};

export default inlineCreate;
