import React, { type PropsWithChildren, useLayoutEffect, useRef, useState } from 'react';
import { styled } from '@compiled/react';

const DEFAULT_OFFSET = 24;

const ShrinkToFit = ({ children }: PropsWithChildren<{}>) => {
	const [bottomOffset, setBottomOffset] = useState<number>(DEFAULT_OFFSET);
	const rootRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		/**
		 * Wait until animation frame before taking measurement, because popup does not position correctly straight
		 * away for *some reason*.
		 */
		const animationFrame = requestAnimationFrame(() => {
			if (!rootRef.current) return;

			const rect = rootRef.current.getBoundingClientRect();

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const distanceFromBottom = window.innerHeight - rect.bottom;

			setBottomOffset(distanceFromBottom);
		});

		return () => cancelAnimationFrame(animationFrame);
	}, []);

	return (
		<Container ref={rootRef} bottomOffset={bottomOffset} topOffset={DEFAULT_OFFSET}>
			{children}
		</Container>
	);
};

export default ShrinkToFit;

type Props = {
	bottomOffset: number;
	topOffset: number;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxHeight: ({ bottomOffset, topOffset }: Props) =>
		`calc(100vh - ${bottomOffset}px - ${topOffset}px)`,
});
