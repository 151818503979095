import 'rxjs/add/operator/map';
import 'rxjs/add/observable/fromPromise';
import type { Observable } from 'rxjs/Observable';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { AGGError } from '@atlassian/jira-relay-errors';
import roadmapGoalsConcreteQuery, {
	type roadmapGoalsQuery,
} from '@atlassian/jira-relay/src/__generated__/roadmapGoalsQuery.graphql';
import { generateRoadmapGoalsQueryVariables } from '@atlassian/jira-software-roadmap-data-queries/src/queries/roadmap-goals/index.tsx';
import type { RoadmapGoalHash } from '@atlassian/jira-software-roadmap-model/src/goals/index.tsx';
import { GET_GOALS } from '@atlassian/jira-software-roadmap-utils/src/utils/metrics/common/constants.tsx';
import { runQueryWithMetadataAndSelector } from '../common/agg/run-query.tsx';
import { queryMetrics } from '../performance-analytics';
import type { RoadmapGoals } from './types';

const analyticKey = 'jira.frontend.fe.software.roadmap.goals';
const concurrentMetricDefinition = queryMetrics[GET_GOALS];

export const executionOptions = {
	graphqlErrorFilter: (errors: AGGError[]) =>
		errors.filter(
			(error) =>
				!(error.path && error.path[0] === 'townsquare' && error.extensions?.statusCode === 403),
		),
};

export const getGoals = (sourceAri: Ari): Observable<RoadmapGoalHash> =>
	runQueryWithMetadataAndSelector<roadmapGoalsQuery, RoadmapGoals>(
		roadmapGoalsConcreteQuery,
		generateRoadmapGoalsQueryVariables(sourceAri),
		analyticKey,
		concurrentMetricDefinition,
		(data) => data.roadmaps?.roadmapAriGoals?.goals,
		executionOptions,
	).map(({ data }) =>
		data.reduce<RoadmapGoalHash>((acc, { goal, issueIds }) => {
			if (goal) {
				acc[goal.id] = { goal, issueIds: issueIds.map((id) => String(id)) };
			}
			return acc;
		}, {}),
	);
