import { defineMessages } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default defineMessages({
	legend: {
		// eslint-disable-next-line jira/i18n/id-named-by-package
		id: 'aais-timeline-legend.legend.legend-button.legend',
		defaultMessage: 'Legend',
		description: 'Button label. Toggles Roadmap Legend popup.',
	},
}) as {
	legend: MessageDescriptor;
};
