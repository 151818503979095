import type { Observable as ObservableType } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import versionDetailsConcreteQuery, {
	type versionDetailsQuery,
} from '@atlassian/jira-relay/src/__generated__/versionDetailsQuery.graphql';
import { VERSION_DETAILS } from '@atlassian/jira-software-roadmap-utils/src/utils/metrics/common/constants.tsx';
import { runQueryWithMetadataAndSelector } from '../common/agg/run-query.tsx';
import { queryMetrics } from '../performance-analytics';
import type { GraphQLVersionDetailsResponse } from './types';

const concurrentMetricDefinition = queryMetrics[VERSION_DETAILS];
const analyticKey = 'jira.frontend.fe.software.roadmap.version-details';

export const fetchVersionDetails = (
	versionAri: string,
): ObservableType<GraphQLVersionDetailsResponse> =>
	runQueryWithMetadataAndSelector<versionDetailsQuery, GraphQLVersionDetailsResponse>(
		versionDetailsConcreteQuery,
		{ versionAri },
		analyticKey,
		concurrentMetricDefinition,
		(data) => data.jira.version,
	)
		.map(({ data }) => data)
		.catch((error) => {
			log.safeErrorWithoutCustomerData('getVersionDetails', 'getVersionDetails failed', error);
			throw error;
		});
