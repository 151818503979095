import React, { type PropsWithChildren, useLayoutEffect, useRef, useState } from 'react';
import { Box } from '@atlaskit/primitives';

const DEFAULT_OFFSET = 24;

const ShrinkToFit = ({ children }: PropsWithChildren<{}>) => {
	const [bottomOffset, setBottomOffset] = useState<number>(DEFAULT_OFFSET);
	const rootRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		/**
		 * Wait until animation frame before taking measurement, because popup does not position correctly straight
		 * away for *some reason*.
		 */
		const animationFrame = requestAnimationFrame(() => {
			if (!rootRef.current) return;

			const rect = rootRef.current.getBoundingClientRect();

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const distanceFromBottom = window.innerHeight - rect.bottom;

			setBottomOffset(distanceFromBottom);
		});

		return () => cancelAnimationFrame(animationFrame);
	}, []);

	return (
		<Box
			ref={rootRef}
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{ maxHeight: `calc(100vh - ${bottomOffset}px - ${DEFAULT_OFFSET}px)` }}
		>
			{children}
		</Box>
	);
};

export default ShrinkToFit;
