import 'rxjs/add/operator/map';
import 'rxjs/add/observable/fromPromise';
import type { Observable } from 'rxjs/Observable';
import type { Ari } from '@atlassian/jira-platform-ari';
import getItemsByIdsConcreteQuery, {
	type getItemsByIdsQuery,
} from '@atlassian/jira-relay/src/__generated__/getItemsByIdsQuery.graphql';
import { generateGetItemsByIdsQueryVariables } from '@atlassian/jira-software-roadmap-data-queries/src/queries/get-items-by-ids/index.tsx';
import { GET_ITEMS_BY_IDS } from '@atlassian/jira-software-roadmap-utils/src/utils/metrics/common/constants.tsx';
import { runQueryWithMetadata } from '../common/agg/run-query.tsx';
import { queryMetrics } from '../performance-analytics';
import { transformIssuesFromGetItemsByIdsQueryResponse } from './transformers';
import type { IssuesAndUsers } from './types';

const analyticKey = 'jira.frontend.fe.software.roadmap.get-items-by-ids';
const concurrentMetricDefinition = queryMetrics[GET_ITEMS_BY_IDS];

/**
 * Bulk fetch issues by issueIds from server.
 */
export const getItems = (sourceAri: Ari, issueIds: string[]): Observable<IssuesAndUsers> =>
	runQueryWithMetadata<getItemsByIdsQuery>(
		getItemsByIdsConcreteQuery,
		generateGetItemsByIdsQueryVariables(sourceAri, issueIds),
		analyticKey,
		concurrentMetricDefinition,
	).map(({ data }) => transformIssuesFromGetItemsByIdsQueryResponse(data));
