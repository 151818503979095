import {
	CHART_BAR_RESIZE,
	PERSIST_ITEM,
	EXPORT_IMAGE_GENERATED,
	ISSUE_UPDATED,
	DEPENDENCY_ADDED,
	BULK_ISSUES_SCHEDULED,
	CRITICAL_DATA,
	VERSION_DETAILS,
	GET_ISSUE_IDS,
	GET_ITEMS_BY_IDS,
	LOAD_HEALTHCHECK_ISSUE_TYPES,
	FILTER_CONFIGURATION,
	DERIVE_FIELDS,
	GET_GOALS,
	type InteractionMetricType,
	type MutationMetricType,
	type QueryMetricType,
} from '@atlassian/jira-software-roadmap-utils/src/utils/metrics/common/constants.tsx';

const railTargets: {
	animation: number;
	inline: number;
	load: number;
} = {
	animation: 16,
	inline: 100,
	load: 1000,
};

const serverResponseTargets: {
	fetch: number;
	bulk: number;
	mutate: number;
	fastMutate: number;
	slowFetch: number;
} = {
	fetch: 750,
	bulk: 2000,
	mutate: 2500,
	fastMutate: 1500,
	slowFetch: 1000,
};

const interactionSLOs: Partial<
	Record<InteractionMetricType | MutationMetricType | QueryMetricType, number>
> = {
	[CHART_BAR_RESIZE]: railTargets.inline,
	[EXPORT_IMAGE_GENERATED]: railTargets.load,
	[PERSIST_ITEM]: serverResponseTargets.mutate,
	[BULK_ISSUES_SCHEDULED]: serverResponseTargets.bulk,
	[ISSUE_UPDATED]: serverResponseTargets.fastMutate,
	[DEPENDENCY_ADDED]: serverResponseTargets.slowFetch,
	[CRITICAL_DATA]: serverResponseTargets.fetch,
	[VERSION_DETAILS]: serverResponseTargets.fetch,
	[GET_ISSUE_IDS]: serverResponseTargets.slowFetch,
	[GET_ITEMS_BY_IDS]: serverResponseTargets.slowFetch,
	[LOAD_HEALTHCHECK_ISSUE_TYPES]: serverResponseTargets.fetch,
	[FILTER_CONFIGURATION]: serverResponseTargets.fetch,
	[DERIVE_FIELDS]: serverResponseTargets.fetch,
	[GET_GOALS]: serverResponseTargets.fetch,
};

export default interactionSLOs;
