import React, { type ReactElement } from 'react';
import { connect } from '@atlassian/jira-react-redux';
import type { Position } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/common';
import type { OverlayTypes } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/overlay.tsx';
import {
	RoadmapChartTimelineOverlay,
	type ChartTimelineOverlayRendererProps,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-timeline-overlay/main.tsx';
import { isDependenciesVisible } from '../../../../state/selectors/filters';
import type { State } from '../../../../state/types';
import { getTooltipParams } from '../../../../state/ui/table/selectors';
import ContextMenu from './context-menu/async';
import DependencyFlyout from './dependency-flyout/async';
import DependencyLines from './dependency-lines';
import HighlightLines from './highlight-lines';

type Renderers = {
	renderDependencyLines: () => ReactElement;
	renderHighlightLines: () => ReactElement;
	renderDependencyFlyout: (props: ChartTimelineOverlayRendererProps) => ReactElement;
	renderContextMenu: (props: ChartTimelineOverlayRendererProps) => ReactElement;
};

const renderers: Renderers = {
	renderDependencyLines: () => <DependencyLines />,
	renderDependencyFlyout: (props: ChartTimelineOverlayRendererProps) => (
		// @ts-expect-error - TS2559 - Type '{ id: string; position: Position; }' has no properties in common with type 'IntrinsicAttributes & { context?: Context<ReactReduxContextValue<any, AnyAction>> | undefined; store?: Store<...> | undefined; }'.
		<DependencyFlyout {...props} />
	),
	renderContextMenu: (props: ChartTimelineOverlayRendererProps) => <ContextMenu {...props} />,
	renderHighlightLines: () => <HighlightLines />,
};

type StateProps = {
	isDependenciesVisible: boolean;
	id: string | undefined;
	position: Position | undefined;
	type: OverlayTypes;
} & Renderers;

export default connect((state: State): StateProps => {
	const tooltip = getTooltipParams(state);
	return {
		...renderers,
		type: tooltip?.type,
		id: tooltip && 'id' in tooltip ? tooltip.id.toString() : undefined,
		position: tooltip?.position,
		isDependenciesVisible: isDependenciesVisible(state),
	};
}, {})(RoadmapChartTimelineOverlay);
