import React, { type ReactNode } from 'react';
import noop from 'lodash/noop';
import {
	createStore,
	createHook,
	createStateHook,
	createContainer,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import { DEFAULT_ITEM_HEIGHT } from '../../constants';
import { getHierarchyEnrichedItems } from '../../utils/hierarchy-enriched-items.tsx';
import { getItemsContainerStyle } from '../../utils/items-background.tsx';
import { getSelectedItemIdsHash } from '../../utils/selected-items.tsx';
import { useAriaRowOffset } from '../header';
import { actions, type Actions } from './actions';
import {
	getIsTableEmpty,
	getItems,
	getItemIndexes,
	getIsItemSelected,
	getIsItemMultiSelected,
	getItemsSelectionCount,
	getItemsContainerStyles,
	getItemsCount,
	getAriaRowCount,
	getCreateItemAnchor,
	getHasActiveCreate,
	getItemHeight,
	getCreateItemAnchorIndex,
	getIsItemSelectable,
	getSelectedItemIds,
	getActiveItemId,
	getIsItemActive,
} from './selectors';
import type { ContainerProps, State } from './types';

const DEFAULT_STATE: State = {
	items: [],
	itemIndexes: {},
	selectedItemIds: [],
	rangeAnchorItemId: undefined,
	onItemMultiSelect: noop,
	activeItemId: undefined,
	onItemActivate: noop,
	// Remove this with project_timeline_multi-select_and_checkboxes
	itemsSelectionState: {},
	// Remove this with project_timeline_multi-select_and_checkboxes
	itemsSelectionCount: 0,
	ariaRowCount: 0,
	isTableEmpty: false,
	itemsBackground: 'none',
	totalHeight: 0,
	itemHeight: DEFAULT_ITEM_HEIGHT,
	createItemAnchor: undefined,
};

const store = createStore<State, Actions>({
	initialState: DEFAULT_STATE,
	actions,
	name: 'timeline-table.items',
});

export const useItemsState = createHook(store);

export const useItems = createHook(store, {
	selector: getItems,
});

export const useItemIndexes = createHook(store, {
	selector: getItemIndexes,
});

export const useIsItemSelected = createHook(store, {
	selector: getIsItemSelected,
});

export const useIsItemMultiSelected = createHook(store, {
	selector: getIsItemMultiSelected,
});

export const useItemsSelectionCount = createHook(store, {
	selector: getItemsSelectionCount,
});

export const useIsTableEmpty = createHook(store, {
	selector: getIsTableEmpty,
});

export const useItemsCount = createHook(store, {
	selector: getItemsCount,
});

export const useAriaRowCount = createHook(store, {
	selector: getAriaRowCount,
});

export const useItemHeight = createHook(store, {
	selector: getItemHeight,
});

export const useItemsContainerStyles = createHook(store, {
	selector: getItemsContainerStyles,
});

export const useCreateItemAnchor = createHook(store, {
	selector: getCreateItemAnchor,
});

export const useCreateItemAnchorIndex = createStateHook(store, {
	selector: getCreateItemAnchorIndex,
});

export const useHasActiveCreate = createHook(store, {
	selector: getHasActiveCreate,
});

export const useSelectedItemIds = createHook(store, {
	selector: getSelectedItemIds,
});
export const useActiveItemId = createHook(store, {
	selector: getActiveItemId,
});

export const useIsItemActive = createHook(store, {
	selector: getIsItemActive,
});

export const useIsItemSelectable = createHook(store, {
	selector: getIsItemSelectable,
});

const updateState =
	() =>
	(
		{ setState }: StoreActionApi<State>,
		{
			items,
			createItemAnchor,
			expandedItems,
			activeItemId,
			onItemActivate,
			selectedItemIdsWithPermission,
			onItemMultiSelect,
			isCreateLastEnabled,
			isCreateSiblingEnabled,
			isCreateChildEnabled,
			itemHeight,
			ariaRowOffset,
		}: ContainerProps,
	) => {
		const { flatItems, itemIndexes, ariaRowCount } = getHierarchyEnrichedItems(
			items,
			createItemAnchor,
			expandedItems,
			isCreateLastEnabled,
			isCreateSiblingEnabled,
			isCreateChildEnabled,
			itemHeight,
			ariaRowOffset,
		);

		const itemsSelectionState = getSelectedItemIdsHash(selectedItemIdsWithPermission);
		const { itemsBackground, totalHeight } = getItemsContainerStyle(flatItems, itemHeight);

		setState({
			items: flatItems,
			itemIndexes,
			itemsSelectionState,
			itemsSelectionCount: selectedItemIdsWithPermission?.length,
			activeItemId,
			onItemActivate,
			selectedItemIds: selectedItemIdsWithPermission ?? [],
			onItemMultiSelect,
			ariaRowCount,
			isTableEmpty: items.length === 0,
			itemsBackground,
			totalHeight,
			itemHeight,
			createItemAnchor,
		});
	};

const ItemsProviderContainer = createContainer<State, Actions, ContainerProps>(store, {
	onInit: updateState,
	onUpdate: updateState,
});

type Props = Omit<ContainerProps, 'ariaRowOffset'> & { children: ReactNode };

export const ItemsProvider = (props: Props) => {
	const [ariaRowOffset] = useAriaRowOffset();
	return <ItemsProviderContainer {...props} ariaRowOffset={ariaRowOffset} />;
};
