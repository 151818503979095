import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/empty';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators/catchError';
import { filter } from 'rxjs/operators/filter';
import { map } from 'rxjs/operators/map';
import { switchMap } from 'rxjs/operators/switchMap';
import { CONFLUENCE_WHITEBOARD } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import {
	toIssueKey,
	type BaseUrl,
	type IssueKey,
	type CloudId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { createIssue } from '../../common/issue-builder';
import type { IssueRemoteData } from '../../model/issue';
import { fetchIssueRemoteData, fetchIssueConfluenceWhiteboardData } from './graphql';
import * as IssueExpressTypes from './types';

const LOG_LOCATION = 'issue.data-provider.source.issue-express';

const getIssueKeyFromResponse = (
	response: IssueExpressTypes.ViewIssue | IssueExpressTypes.ViewIssueRemoteData,
): IssueKey => {
	// @ts-expect-error - TS2339 - Property 'fields' does not exist on type 'ViewIssue | ViewIssueRemoteData'.
	const fields = response.fields || [];

	const issueKeyField: IssueExpressTypes.IssueKeyField | undefined = fields.find(
		// @ts-expect-error - TS7006 - Parameter 'field' implicitly has an 'any' type.
		(field) => field.__typename === IssueExpressTypes.IssueKey,
	);

	if (!issueKeyField) {
		throw new Error('Issue key field missing in graphql response');
	}

	return toIssueKey(issueKeyField.stringValue);
};

const transformGraphQlIssueRemoteData = (
	response: IssueExpressTypes.ViewIssueRemoteData,
	issueKey?: IssueKey,
): IssueRemoteData | null => {
	if (!response) {
		return null;
	}
	const key = issueKey || getIssueKeyFromResponse(response);
	const now = new Date();
	const { issueId, remoteLinks } = response;

	return createIssue({ key })
		.withMeta({
			newIssueViewLockedIn: response.meta ? response.meta.newIssueViewLockedIn : false,
			dataSource: 'issueExpress',
			fetchTime: now,
		})
		.withId(issueId)
		.withRemoteLinks(remoteLinks)
		.build();
};

const transformConfluenceWhiteboardData = (
	response: IssueExpressTypes.ConfluenceWhiteboardsGraphqlResponse,
): Pick<IssueRemoteData['remoteLinks'], 'confluenceWhiteboards'> => {
	const issueToWhiteboard = response?.graphStore?.issueToWhiteboard ?? {};
	const whiteboards = issueToWhiteboard.edges?.map(({ node }) => ({
		whiteboardId: node.whiteboardId,
		author: { ...node.author },
		href: `${node.links?.base}${node.links?.webUi}`,
		type: CONFLUENCE_WHITEBOARD,
		id: node.id,
		title: node.title,
	}));

	return {
		confluenceWhiteboards: {
			linkedWhiteboards: {
				whiteboardCount: issueToWhiteboard.totalCount,
				whiteboards,
			},
		},
	};
};

export const fetchIssueRemoteData$ = (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
): Observable<IssueRemoteData> =>
	// @ts-expect-error - TS2322 - Type 'Observable<IssueRemoteData | null>' is not assignable to type 'Observable<IssueRemoteData>'.
	fetchIssueRemoteData(baseUrl, issueKey)
		.map((graphQlIssue) => transformGraphQlIssueRemoteData(graphQlIssue, issueKey))
		.filter(Boolean)
		.catch((err) => {
			trackOrLogClientError(
				LOG_LOCATION,
				'fetchIssueRemoteData: Failed to fetch issue remote data from gira',
				err,
			);
			return Observable.empty<never>();
		});

// This function is to fetch remote links from gira and linked confluence whiteboards from AGG which requires issueId
// At this time we don't have issueId yet so we need to wait until gira endpoint returns issueId and then fetch confluence whiteboards
// We then combined the data and update the store.
export const fetchIssueRemoteDataWithWhiteboards$ = (
	issueKey: IssueKey,
	cloudId: CloudId,
): Observable<IssueRemoteData> =>
	// @ts-expect-error - TS2322 - Type 'Observable<IssueRemoteData | null>' is not assignable to type 'Observable<IssueRemoteData>'.
	fetchIssueRemoteData('', issueKey).pipe(
		switchMap((remoteDataResponse) =>
			fetchIssueConfluenceWhiteboardData(remoteDataResponse.issueId, cloudId).pipe(
				map((confluenceWhiteboardDataResponse) => {
					const confluenceWhiteboards = transformConfluenceWhiteboardData(
						confluenceWhiteboardDataResponse,
					);
					const combinedRemoteData = {
						...remoteDataResponse,
						remoteLinks: {
							...remoteDataResponse.remoteLinks,
							...confluenceWhiteboards,
						},
					};
					return combinedRemoteData;
				}),
				map((graphQlIssue) => transformGraphQlIssueRemoteData(graphQlIssue, issueKey)),
				filter(Boolean),
				catchError((err) => {
					trackOrLogClientError(
						LOG_LOCATION,
						'fetchIssueConfluenceWhiteboardData: Failed to fetch confluence whiteboards from AGG',
						err,
					);
					return of(transformGraphQlIssueRemoteData(remoteDataResponse, issueKey));
				}),
			),
		),
		catchError((err) => {
			trackOrLogClientError(
				LOG_LOCATION,
				'fetchIssueRemoteData: Failed to fetch issue remote data from gira',
				err,
			);
			return Observable.empty<never>();
		}),
	);
