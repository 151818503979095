import React from 'react';
import Toolbar from '@atlassian/jira-aais-timeline-toolbar/src/ui/container/index.tsx';
import type { ToolbarItem } from '@atlassian/jira-aais-timeline-toolbar/src/ui/container/types.tsx';
import FullscreenButton from '@atlassian/jira-fullscreen-button/src/index.tsx';
import { JiraBottomRightCornerOutlet } from '@atlassian/jira-layout-controller/src/ui/bottom-right-corner/outlet/index.tsx';
import { RoadmapTodayButton } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-overlay/today-button/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import Legend from './legend';
import TimelineMode from './timeline-mode';

type Props = {
	isMacroView: boolean;
};

// NOTE: We are confident that the queried elements with the given id will always be HTMLElement | null
const getFullscreenRefs = (): (HTMLElement | null)[] =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, jira/jira-ssr/no-unchecked-globals-usage
	Array.from(document.querySelectorAll('[data-fullscreen-id]')) as (HTMLElement | null)[];

const OverlayButton = ({ isMacroView }: Props) => {
	const toolbarItems: ToolbarItem[] = [
		{
			id: 'today-button',
			element: <RoadmapTodayButton />,
			showDivider: false,
		},
		{ id: 'timeline-mode-switcher', element: <TimelineMode /> },
		{ id: 'legend', element: <Legend /> },
		{
			id: 'fullscreen-button',
			element: (
				<FullscreenButton
					testId="roadmap.standard-roadmap.common.table.chart-overlay.overlay-button.fullscreen-button"
					getCustomRefs={getFullscreenRefs}
					withContainerStyles={false}
					isSubtle={isVisualRefreshEnabled()}
				/>
			),
			isVisible: !isMacroView,
			showDivider: false,
		},
	];

	return (
		<JiraBottomRightCornerOutlet orderFromRight={2} id="software-roadmap-timeline-overlay">
			<Toolbar items={toolbarItems} />
		</JiraBottomRightCornerOutlet>
	);
};

export { OverlayButton };
