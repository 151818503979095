/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { useIntl } from 'react-intl-next';
import { N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { inQuarterOrder, inQuarterOrderOld } from '../messages';

const headerStyles = css({
	font: token('font.body.UNSAFE_small'),
	color: token('color.text.subtlest', N300),
	marginTop: 0,
});

const headerTextTransformStyles = css({
	textTransform: 'uppercase',
});

const contrastStyles = css({
	color: token('color.text.accent.gray.bolder'),
});

type Props = {
	quarterNumber: number;
	// Set to required on FG cleanup jsw_roadmaps_timeline-gridcell-header-contrast
	isHighlighted?: boolean;
	yearCaption?: string | undefined | null;
};

const QuarterCell = ({ quarterNumber, yearCaption, isHighlighted }: Props) => {
	const { formatMessage } = useIntl();

	const { startMonth, endMonth } = isVisualRefreshEnabled()
		? inQuarterOrder[quarterNumber]
		: inQuarterOrderOld[quarterNumber];

	return (
		<small
			css={[
				headerStyles,
				!isVisualRefreshEnabled() && headerTextTransformStyles,
				isHighlighted && fg('jsw_roadmaps_timeline-gridcell-header-contrast') && contrastStyles,
			]}
		>
			{`${formatMessage(startMonth)} – ${formatMessage(endMonth)}`} {yearCaption}
		</small>
	);
};

QuarterCell.defaultProps = {
	yearCaption: null,
};

export default QuarterCell;
export type { Props as APIProps };
