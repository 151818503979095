/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { B100, N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { CHART_COLUMN_COORDINATE, DEFAULT_HEADER_ROW_ID, zIndex } from '../../../common/constants';
import { useEnablements } from '../../../common/context/enablements/index.tsx';
import { useGridCell } from '../../../common/context/side-effect-marshal/focus-marshal/use-grid-cell';
import { useTimelineState } from '../../../common/context/timeline/index.tsx';
import type { RenderHeaderItem } from '../../../renderers';
import ChartCalendarCells from './calendar-cells';

const defaultHeaderCellsContainer = css({
	position: 'relative',
	boxSizing: 'border-box',
	borderTop: `1px solid ${token('color.border', N40)}`,
	borderBottom: `1px solid ${token('color.border', N40)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	zIndex: zIndex.HEADER,
});

const subHeaderCellsContainer = css({
	position: 'relative',
	boxSizing: 'border-box',
	display: 'flex',
	alignItems: 'center',
	padding: 0,
	borderBottom: `1px solid ${token('color.border', N40)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	zIndex: zIndex.HEADER,
	'&:focus': {
		outline: 'none',
	},
	'&:focus-visible': {
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused', B100)}`,
	},
});

type Props = {
	id: string;
	itemHeight: number;
	renderChartHeaderItem: RenderHeaderItem;
};

const ChartHeaderCell = ({ id, itemHeight, renderChartHeaderItem }: Props) => {
	const [{ timelineWidth, timelineMode, timelineDuration, columnDurations, today }] =
		useTimelineState();
	const [{ isHighlightToday }] = useEnablements();
	const cellRef = useGridCell(id, CHART_COLUMN_COORDINATE);

	if (id === DEFAULT_HEADER_ROW_ID) {
		return (
			<div css={defaultHeaderCellsContainer} style={{ width: timelineWidth, height: itemHeight }}>
				<ChartCalendarCells
					today={today}
					timelineMode={timelineMode}
					timelineDuration={timelineDuration}
					columnDurations={columnDurations}
					isHighlightToday={isHighlightToday}
				/>
			</div>
		);
	}

	return (
		<div
			ref={cellRef}
			role="gridcell"
			tabIndex={-1}
			css={subHeaderCellsContainer}
			style={{ width: timelineWidth, height: itemHeight }}
		>
			{renderChartHeaderItem({ id })}
		</div>
	);
};

export { ChartHeaderCell };
