import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { type BatchAction, batchActions } from 'redux-batched-actions';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import uuid from 'uuid';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import ecClient from '@atlassian/jira-jsis-ec-client/src/services/index.tsx';
import IssueMutation from '@atlassian/jira-jsis-ec-client/src/services/issue-mutation/index.tsx';
import { MutationSource } from '@atlassian/jira-jsis-ec-client/src/services/storage/constants.tsx';
import type { Ari } from '@atlassian/jira-platform-ari';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type {
	updateRoadmapItemMutation$data,
	RoadmapItemRankInput,
} from '@atlassian/jira-relay/src/__generated__/updateRoadmapItemMutation.graphql';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type {
	RankRequest,
	Issue,
} from '@atlassian/jira-software-roadmap-model/src/issue/index.tsx';
import { updateItemGraphqlOnServer } from '@atlassian/jira-software-roadmap-services/src/issues/update-roadmap-item.tsx';
import type { IssueHash } from '../../model/issue';
import { getSourceARI, getBoardId } from '../../state/app/selectors';
import {
	rankIssuesSupported,
	getEpicIssueTypeIds,
	getProjectId,
} from '../../state/configuration/selectors';
import {
	type ExtendIssuesAction,
	type UpdateIssueAction,
	extendIssues,
	UPDATE_RANK_ISSUE,
	updateAndPersistIssue,
	type UpdateAndRankIssueAction,
	clearTransitionState,
} from '../../state/entities/issues/actions';
import {
	getIssueKeyById,
	getSafeId,
	getIssue,
	getIssueTypeIdForIssueId,
	getIssueIds,
} from '../../state/entities/issues/selectors';
import {
	type UnableToRankIssueAction,
	type AggErrorAction,
	unableToRankIssue,
	aggError,
} from '../../state/flags/actions';
import type { State } from '../../state/types';
import { type JQLFiltersReapplyAction, reapplyJQLFilters } from '../../state/ui/filters/actions';
import { onHandleAggErrors } from '../common/handle-agg-errors';
import type { GenericAction, StateEpic } from '../common/types';
import { type UpdateAnalyticsMetadata, getFieldModForIssueUpdate } from './common';
import { formatDate } from './common/dates';
import { getSafeRankRequest } from './common/rank';
import { createTransitionIssueWithUpdatedItems } from './common/update';
import refreshIssuePage from './refresh-issue-page';

export const persistUpdateAndRank = (
	issueIdObservable: Observable<IssueId>,
	issue: Issue,
	transitionId: string,
	unsafeRankRequest: Observable<RankRequest>,
	analyticsMetadata: UpdateAnalyticsMetadata,
	sourceARI: Ari,
): Observable<GenericAction[]> =>
	issueIdObservable.flatMap((issueId) =>
		unsafeRankRequest.flatMap((request) => {
			let aggRank: RoadmapItemRankInput | undefined;
			if ('rankBeforeId' in request) {
				aggRank = {
					id: request.rankBeforeId,
					position: 'BEFORE',
				};
			} else if ('rankAfterId' in request) {
				aggRank = {
					id: request.rankAfterId,
					position: 'AFTER',
				};
			}

			const fields = {
				...getFieldModForIssueUpdate(issue),
				rank: aggRank,
			};

			return updateItemGraphqlOnServer({
				sourceARI,
				input: {
					itemId: issueId,
					projectId: analyticsMetadata.projectId,
					summary: fields.summary,
					dueDate: formatDate(fields.dueDate),
					startDate: formatDate(fields.startDate),
					color: fields.color,
					parentId: fields.parentId,
					sprintId: fields.sprintId,
					clearFields: fields.clearFields,
					rank: fields.rank,
				},
			}).flatMap((data?: updateRoadmapItemMutation$data) => {
				const updateRoadmapItemData = data?.roadmaps?.updateRoadmapItem;
				const errors = updateRoadmapItemData?.errors;
				const success = updateRoadmapItemData?.success;
				if (success === false && errors?.length) {
					return onHandleAggErrors(!!updateRoadmapItemData, errors).map((aggErrorAction) => [
						clearTransitionState({ issueId, transitionId }),
						aggErrorAction,
					]);
				}
				const clonedEvent = analyticsMetadata.event.clone();
				if (clonedEvent) {
					const attributes = {
						...analyticsMetadata.attributes,
						projectType: 'software',
					};
					fireTrackAnalytics(clonedEvent, 'issue updated', attributes);
				}

				if (ff('odin.ec.client.integration')) {
					ecClient.saveIssueMutationToCache(
						new IssueMutation(issueId.toString(), MutationSource.UPDATE),
					);
				}

				return Observable.of([clearTransitionState({ issueId, transitionId })]);
			});
		}),
	);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((
	action$: ActionsObservable<UpdateAndRankIssueAction>,
	store: MiddlewareAPI<State>,
) =>
	action$
		.ofType(UPDATE_RANK_ISSUE)
		.flatMap(
			(
				action: UpdateAndRankIssueAction,
			): Observable<
				| ExtendIssuesAction
				| BatchAction
				| UnableToRankIssueAction
				| UpdateIssueAction
				| AggErrorAction
				| JQLFiltersReapplyAction
			> => {
				const unsafeIssueId: IssueId = action.payload.issueId;
				const { rankRequest } = action.payload;
				const state = store.getState();
				const sourceARI = getSourceARI(state);

				const issue = getIssue(state, unsafeIssueId);
				const issueKey = getIssueKeyById(state, unsafeIssueId);
				const projectId = getProjectId(state);
				const issueTypeId = getIssueTypeIdForIssueId(state, unsafeIssueId);
				const level = getEpicIssueTypeIds(state).includes(issueTypeId) ? 1 : 0;

				if (issue === undefined) {
					return Observable.empty<never>();
				}

				if (!rankIssuesSupported(state)) {
					const { parentId } = action.payload.properties;
					const clonedEvent = action.meta.analyticsEvent.clone();
					if (clonedEvent) {
						const attributes = {
							projectType: 'software',
							parentIdUpdated: true,
						};

						fireTrackAnalytics(clonedEvent, 'issue disabledRankDrop', attributes);
					}
					return Observable.concat(
						Observable.of(unableToRankIssue({ boardId: getBoardId(state) })),
						Observable.of(
							updateAndPersistIssue(unsafeIssueId, { parentId }, action.meta.analyticsEvent),
						),
					);
				}

				// Find out new sequence
				const sequence = getIssueIds(state);
				let anchorId;
				let isBefore = true;

				if ('rankBeforeId' in rankRequest) {
					anchorId = rankRequest.rankBeforeId;
				} else {
					isBefore = false;
					anchorId = rankRequest.rankAfterId;
				}
				const issueIndex = sequence.indexOf(unsafeIssueId);
				sequence.splice(issueIndex, 1);
				let anchorIndex = sequence.indexOf(anchorId);

				if (isBefore === false) {
					anchorIndex += 1;
				}
				sequence.splice(anchorIndex, 0, unsafeIssueId);

				const transitionId = uuid.v4();

				// create new issue object
				const [transitionIssue, updatedItems] = createTransitionIssueWithUpdatedItems(
					issue,
					action.payload.properties,
					transitionId,
				);

				const transitionExtend = getSafeId(store.getState(), unsafeIssueId).flatMap(
					(issueId: IssueId) => {
						if (issueId === undefined) {
							return Observable.empty<never>();
						}
						const extendHash: IssueHash = {};
						extendHash[issueId] = transitionIssue;

						return Observable.of(
							extendIssues({
								sequence: [...sequence], // update new sequence
								hash: extendHash,
							}),
						);
					},
				);

				const extendHash: IssueHash = {};
				extendHash[unsafeIssueId] = transitionIssue;
				const analyticsMetadata = {
					event: action.meta.analyticsEvent,
					projectId,
					attributes: {
						issueTypeId,
						updatedItems: [...updatedItems, { name: 'rank' }],
						level,
					},
				};

				const safeRankRequest = getSafeRankRequest(state, rankRequest);

				return Observable.concat(
					// update `unsafe` issue first
					Observable.of(
						extendIssues({
							sequence: [],
							hash: extendHash,
						}),
					),
					// schedule update of persisted issue
					transitionExtend,
					persistUpdateAndRank(
						getSafeId(store.getState(), unsafeIssueId),
						transitionIssue,
						transitionId,
						safeRankRequest,
						analyticsMetadata,
						sourceARI,
					)
						.map((actions) => {
							refreshIssuePage(state, issueKey);

							if ('old' in transitionIssue.parentId) {
								const {
									parentId: { old: prevParentId, value: nextParentId },
								} = transitionIssue;

								prevParentId && refreshIssuePage(state, getIssueKeyById(state, prevParentId));

								nextParentId && refreshIssuePage(state, getIssueKeyById(state, nextParentId));
							}

							return batchActions(actions);
						})
						.catch(() => Observable.from([aggError()])),
					fg('jsw-roadmap-state-change-based-issue-hidden-flags')
						? Observable.empty<never>()
						: Observable.of(reapplyJQLFilters()),
				);
			},
		)) as StateEpic;
