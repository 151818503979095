/** @jsx jsx */
import React, { type ComponentType, useRef } from 'react';
import { css, jsx } from '@compiled/react';
import { Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import BreadcrumbRenderer from '@atlassian/jira-breadcrumbs/src/breadcrumb-renderer/index.tsx';
import PageHeader from '@atlassian/jira-common-components-page-header';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { getWillShowNav3 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav3.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { useBreakpointCSSVariables } from '../../common/utils/responsive/use-breakpoint-css-variables.tsx';
import { headerBreakpointOptions } from './constants';
import messages from './messages';

type Props = {
	isMacroView: boolean;
	Share: ComponentType<{}>;
	Filters: ComponentType<{ isMacroView: boolean }>;
	ViewSettings: ComponentType<{}>;
	MoreSettings: ComponentType<{}> | undefined;
	Feedback: ComponentType<{}>;
};

const containerStylesOldNavigation = css({
	flex: '0 0 auto',
	paddingRight: token('space.300', '24px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.fullscreen': {
		display: 'none',
	},
});

const containerStylesNewNavigation = css({
	flex: '0 0 auto',
	paddingRight: token('space.300', '24px'),
});

const headerRowContainerStyles = css({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});

const breadcrumbRowContainerStyles = css({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});

const spacedHeaderRowContainerStylesOld = css({
	marginTop: token('space.200', '16px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:first-of-type': {
			minWidth: 0,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:nth-of-type(2)': {
			flexShrink: 0,
		},
	},
});

const spacedHeaderRowContainerStyles = css({
	marginTop: token('space.250'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:first-of-type': {
			minWidth: 0,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:nth-of-type(2)': {
			flexShrink: 0,
		},
	},
});

const RoadmapHeader = ({
	Feedback,
	Share,
	Filters,
	isMacroView,
	ViewSettings,
	MoreSettings,
}: Props) => {
	const { formatMessage } = useIntl();

	const ref = useRef<HTMLDivElement>(null);

	useBreakpointCSSVariables(ref, headerBreakpointOptions);

	const marginBottom = () => {
		if (getWillShowNav4()) {
			return `${gridSize * 2}px`;
		}
		return isMacroView ? `${gridSize * 2}px` : `${gridSize * 4}px`;
	};

	return (
		<div
			ref={ref}
			css={[getWillShowNav4() ? containerStylesNewNavigation : containerStylesOldNavigation]}
			data-fullscreen-id={getWillShowNav4() ? undefined : 'roadmap-header-container'}
		>
			{getWillShowNav3() && !isMacroView && (
				<PageHeader
					truncateTitle
					actions={
						<Inline space="space.100">
							<Feedback />
							<Share />
							{MoreSettings ? <MoreSettings /> : null}
						</Inline>
					}
					breadcrumbs={
						<div css={breadcrumbRowContainerStyles}>
							<BreadcrumbRenderer />
						</div>
					}
				>
					{formatMessage(messages.timeline)}
				</PageHeader>
			)}
			<div
				css={[
					headerRowContainerStyles,
					isVisualRefreshEnabled()
						? spacedHeaderRowContainerStyles
						: spacedHeaderRowContainerStylesOld,
				]}
				style={{
					marginBottom: marginBottom(),
				}}
			>
				<UFOSegment name="roadmap-filters">
					<Filters isMacroView={isMacroView} />
				</UFOSegment>

				{getWillShowNav4() && (
					<Inline space="space.100">
						{!isMacroView && <Share />}
						<ViewSettings />
						{MoreSettings && !isMacroView ? <MoreSettings /> : null}
					</Inline>
				)}

				{getWillShowNav3() && <ViewSettings />}
			</div>
		</div>
	);
};

RoadmapHeader.defaultProps = {
	isMacroView: false,
};

export default RoadmapHeader;
