import { connect } from '@atlassian/jira-react-redux';
import { withRouter } from '@atlassian/react-resource-router';
import { routerLocationChange } from '../../state/router/actions';
import { getLocation as getLocationFromStore } from '../../state/router/selectors';
import type { State } from '../../state/types';
import RouterUpdater from './view';

export default connect(
	(state: State) => ({
		locationFromStore: getLocationFromStore(state),
	}),
	{
		onLocationChanged: routerLocationChange,
	},
)(withRouter(RouterUpdater));
